import { ReactElement } from 'react'
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import colors from './colors'
import { formatFollowUpsSummaryText } from './formatText'

type PropsT = {
  customersWithFollowUps: number
  onClick: () => void
}

const FollowUpsSummary = (props: PropsT): ReactElement => {
  const isFollowUpsEmpty = props.customersWithFollowUps < 1
  return (
    <List>
      <ListItem
        onClick={props.onClick}
        divider
        sx={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'flex-end',
          paddingY: '8px',
          minHeight: '56px',
          backgroundColor: isFollowUpsEmpty
            ? colors.NO_FOLLOW_UPS_SUMMARY_BACKGROUND
            : colors.FOLLOW_UPS_SUMMARY_BACKGROUND
        }}
      >
        {isFollowUpsEmpty ? (
          <ListItemIcon sx={{ paddingLeft: isFollowUpsEmpty ? '5px' : 0 }}>
            <TaskAltIcon
              sx={{
                color: colors.NO_FOLLOW_UPS_SUMMARY_ICON_BACKGROUND
              }}
            />
          </ListItemIcon>
        ) : (
          <ListItemIcon>
            <TaskAltIcon
              sx={{
                color: colors.FOLLOW_UPS_SUMMARY_ICON_BACKGROUND
              }}
            />
          </ListItemIcon>
        )}

        <ListItemText
          primary={
            isFollowUpsEmpty
              ? 'No outstanding follow ups'
              : formatFollowUpsSummaryText(props.customersWithFollowUps)
          }
          primaryTypographyProps={{
            style: {
              fontWeight: 'bold',
              fontSize: '16px'
            },
            noWrap: true
          }}
        />
        <ListItemIcon sx={{ minWidth: '24px' }}>
          <KeyboardArrowRightIcon />
        </ListItemIcon>
      </ListItem>
    </List>
  )
}

export default FollowUpsSummary
