import { ReactElement, useState } from 'react'
import ListDialog from 'components/ListDialog'
import { ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { isStyleAdvisor } from 'utils/userPermissions'

interface PropsT {
  onClose: () => void
  onQuickLinkClick: (linkDetail: LinkDetailsT) => void
  isLoading: boolean
  open: boolean
  shouldShowAnniversaryLinks: boolean
}

interface LinkDetailsT {
  path: string
  linkText: string
  title: string
}

const STYLE_ADVISOR_LINK_DETAILS: LinkDetailsT[] = [
  {
    linkText: "Weekend - Women's",
    path: 'curation/1192403',
    title: "weekend - women's"
  },
  {
    linkText: "Weekend - Men's",
    path: 'curation/1180607',
    title: "weekend - men's"
  },
  {
    linkText: "Wardrobe Refresh - Women's",
    path: 'curation/1196180',
    title: "wardrobe refresh - women's"
  },
  {
    linkText: "Wardrobe Refresh - Men's",
    path: 'curation/1192368',
    title: "wardrobe refresh - men's"
  },
  {
    linkText: "Fall Festivities - Women's",
    path: 'curation/1196066',
    title: "fall festivities - women's"
  },
  {
    linkText: "Fall Festivities - Men's",
    path: 'curation/1220335',
    title: "fall festivities - men's"
  },
  {
    linkText: "Workwear - Women's",
    path: 'curation/1196034',
    title: "workwear - women's"
  },
  {
    linkText: "Workwear - Women's - Plus",
    path: 'curation/1220276',
    title: "workwear - women's - plus"
  },
  {
    linkText: "Workwear - Women's - Petite",
    path: 'curation/1194083',
    title: "workwear - women's - petite"
  },
  {
    linkText: "Workwear - Men's",
    path: 'curation/1196271',
    title: "workwear - men's"
  },
  {
    linkText: "Wedding Guest - Women's",
    path: 'curation/1220318',
    title: "wedding guest - women's"
  },
  {
    linkText: "Family Moments - Men's",
    path: 'curation/1116227',
    title: "family moments - men's"
  },
  {
    linkText: "Men's Big & Tall",
    path: 'curation/1220203',
    title: "men's big & tall"
  }
]

const DEFUALT_LINK_DETAILS: LinkDetailsT[] = [
  {
    linkText: "New Arrivals - Women's",
    path: 'browse/women/new',
    title: "women's new arrivals"
  },
  {
    linkText: "New Arrivals - Men's",
    path: 'browse/men/new',
    title: "men's new arrivals"
  },
  {
    linkText: 'New Arrivals - Beauty',
    path: 'browse/beauty/new',
    title: 'beauty new arrivals'
  },
  {
    linkText: 'New Markdowns',
    path: 'browse/sale/new-markdowns',
    title: 'new markdowns'
  },
  {
    linkText: 'New Arrivals - Designer',
    path: 'browse/designer/new',
    title: 'designer new arrivals'
  },
  {
    linkText: 'Designer Sale',
    path: 'browse/sale/designer',
    title: 'designer sale'
  },
  {
    linkText: 'Designer Private Sale',
    path: 'browse/designer-private-sale',
    title: 'designer private sale'
  }
]

const ANNIVERSARY_LINK_DETAILS: LinkDetailsT[] = [
  {
    linkText: 'All',
    path: 'browse/anniversary-sale/all',
    title: "women's anniversary sale"
  },
  {
    linkText: "Women's",
    path: 'browse/anniversary-sale/women',
    title: "women's anniversary sale"
  },
  {
    linkText: "Men's",
    path: 'browse/anniversary-sale/men',
    title: "men's anniversary sale"
  },
  {
    linkText: 'Beauty Exclusives',
    path: 'browse/anniversary-sale/beauty-exclusives',
    title: 'beauty anniversary sale'
  },
  {
    linkText: 'Shoes',
    path: 'browse/anniversary-sale/shoes',
    title: 'shoes anniversary sale'
  },
  {
    linkText: 'Designer',
    path: 'browse/anniversary-sale/designer',
    title: 'designer anniversary sale'
  },
  {
    linkText: 'Grooming',
    path: 'browse/anniversary-sale/men/grooming',
    title: 'grooming anniversary sale'
  }
]

enum TOGGLE_OPTIONS {
  ANNIVERSARY = 'anniversary',
  DEFAULT = 'default'
}

const LinksToggleButtons = ({
  selectedToggle,
  onToggleChange
}: {
  selectedToggle: TOGGLE_OPTIONS
  onToggleChange: (option: TOGGLE_OPTIONS) => void
}): JSX.Element => {
  const theme = useTheme()

  return (
    <ToggleButtonGroup
      size="small"
      color="primary"
      value={selectedToggle}
      onChange={(
        _event: React.MouseEvent<HTMLElement>,
        filter: TOGGLE_OPTIONS
      ) => onToggleChange(filter)}
      exclusive
    >
      <ToggleButton
        value={TOGGLE_OPTIONS.ANNIVERSARY}
        sx={{
          bgcolor: 'white',
          outline: `1px solid ${theme.palette.grey[400]}`,
          outlineOffset: '-1px'
        }}
      >
        Anniversary
      </ToggleButton>
      <ToggleButton
        value={TOGGLE_OPTIONS.DEFAULT}
        sx={{
          bgcolor: 'white',
          outline: `1px solid ${theme.palette.grey[400]}`,
          outlineOffset: '-1px'
        }}
      >
        Default
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

const QuickLinksDialog = (props: PropsT): ReactElement => {
  const {
    isLoading,
    onClose,
    onQuickLinkClick,
    open,
    shouldShowAnniversaryLinks
  } = props

  const { isSAPublicBoardQuickLinksEnabled } = useFeatureFlags()

  const [selectedToggle, setSelectedToggle] = useState(
    shouldShowAnniversaryLinks ? TOGGLE_OPTIONS.ANNIVERSARY : null
  )
  const isAnniversaryList =
    shouldShowAnniversaryLinks && selectedToggle === TOGGLE_OPTIONS.ANNIVERSARY

  const defaultLinks =
    isStyleAdvisor() && isSAPublicBoardQuickLinksEnabled?.active
      ? [].concat(STYLE_ADVISOR_LINK_DETAILS).concat(DEFUALT_LINK_DETAILS)
      : DEFUALT_LINK_DETAILS

  const linksToDisplay = isAnniversaryList
    ? ANNIVERSARY_LINK_DETAILS
    : defaultLinks

  return (
    <ListDialog
      isAnniversaryList={isAnniversaryList}
      isLoading={isLoading}
      onClose={onClose}
      options={linksToDisplay.map((linkDetail) => {
        return {
          text: linkDetail.linkText,
          action: () => onQuickLinkClick(linkDetail)
        }
      })}
      open={open}
      shouldDecreaseListPadding={true}
      title={'Quick links'}
      toggleButtons={
        shouldShowAnniversaryLinks &&
        selectedToggle && (
          <LinksToggleButtons
            selectedToggle={selectedToggle}
            onToggleChange={setSelectedToggle}
          />
        )
      }
    />
  )
}

export default QuickLinksDialog
