import { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ListDialog from 'components/ListDialog/ListDialog'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { isStyleAdvisor } from 'utils/userPermissions'
import { Typography } from '@mui/material'
import CustomersDialog from 'components/CustomersDialog'

interface PropsT {
  open: boolean
  onClose: () => void
  sourcePage: string
}

const CreateStyleBoardDialog = (props: PropsT): ReactElement => {
  const { open, onClose, sourcePage } = props
  const title = 'Create Style Board'
  const history = useHistory()
  const [isCustomersDialogOpen, setIsCustomersDialogOpen] = useState(false)

  const handleOneCustomerClick = async () => {
    generateNewRelicLogs(`startBoardForCustomerFrom${sourcePage}Click`)
    setIsCustomersDialogOpen(true)
  }

  const handlePublicClick = async () => {
    generateNewRelicLogs(
      sourcePage !== 'CurationHome'
        ? `startPublicBoardFrom${sourcePage}Click`
        : 'startPublicBoardClick'
    )
    history.push({
      pathname: '/review',
      search: `?publicBoard=true`
    })
  }

  const handleGeneralRequestClick = async () => {
    generateNewRelicLogs(`startGeneralRequestFrom${sourcePage}Click`)
    history.push({
      pathname: '/review',
      search: `?generalRequest=true`
    })
  }

  return (
    <>
      <ListDialog
        title={title}
        options={[
          isStyleAdvisor()
            ? {
                text: `For one customer`,
                subtext: (
                  <Typography variant="caption">{`(non-queue)`}</Typography>
                ),
                action: () => handleGeneralRequestClick()
              }
            : {
                text: 'For one customer',
                action: () => handleOneCustomerClick()
              },
          {
            text: 'For the public',
            action: () => handlePublicClick()
          }
        ]}
        open={open}
        onClose={onClose}
      />
      <CustomersDialog
        isDialogOpen={isCustomersDialogOpen}
        onClose={() => setIsCustomersDialogOpen(false)}
        isCreateStyleBoard={true}
      />
    </>
  )
}

export default CreateStyleBoardDialog
