type ProductWithNotes = {
  rmsSku: string
  webStyleId?: string
  note?: string
  productOrder?: number
}

export type ProductSourceType =
  | 'fashionMapViews'
  | 'iframe'
  | 'scannerAdds'
  | 'scannerViews'
  | 'seedItemRecViews'
  | 'wishlistAdds'
  | 'snapshotPurchaseHistoryAdds'
  | 'snapshotPurchaseHistoryViews'
  | 'startItemRecViews'
  | 'customerListWishlistAdds'
  | 'customerListPurchaseHistoryAdds'
  | 'miniPDPRecentItemAdds'
  | 'miniPDPSeedItemRecAdds'
  | 'miniPDPBeautyRestockAdds'
  | 'miniPDPStartBoardRecAdds'
  | 'miniPDPHolidayGiftsSBAdds'
  | 'miniPDPScannerDrawerAdds'

export type ProductDiscovery = {
  rmsSkus: { [key: string]: ProductSourceType }
  webStyleIds: { [key: string]: ProductSourceType }
}

export const determineProductSource = (
  product: ProductWithNotes,
  productDiscovery: ProductDiscovery
): ProductSourceType => {
  if (Object.keys(productDiscovery.rmsSkus).includes(product.rmsSku)) {
    return productDiscovery.rmsSkus[product.rmsSku]
  }
  if (
    product.webStyleId &&
    Object.keys(productDiscovery.webStyleIds).includes(product.webStyleId)
  ) {
    return productDiscovery.webStyleIds[product.webStyleId]
  }
  return 'iframe'
}
