import { FollowUpKind } from 'types/FollowUps'

export const FOLLOW_UP_TEXT = {
  [FollowUpKind.ADDED_TO_BOOK]: 'Joined your book. Say hi! 👋',
  [FollowUpKind.TWO_WEEKS]: 'Joined your book 2 weeks ago. Check in! 🫶',
  [FollowUpKind.TWO_MONTHS]:
    'Joined your book 2 months ago. Invite them back! ✉️',
  [FollowUpKind.NEW_PURCHASE_WITH_EMPLOYEE]:
    'Purchased from you. Say thanks! 🙏',
  [FollowUpKind.TWO_WEEKS_PURCHASE_WITH_EMPLOYEE]:
    'Purchased from you 2 weeks ago. Check in! 🫶',
  [FollowUpKind.TWO_MONTHS_PURCHASE_WITH_EMPLOYEE]:
    'Purchased from you 2 months ago. Invite them back! ✉️'
} as const

export type FollowUpTextKeys = keyof typeof FOLLOW_UP_TEXT
