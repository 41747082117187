import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { FetchArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery'
import oktaTokenStorage from 'utils/okta-token-utils'
import setStandardHeaders from 'utils/set-standard-headers'
import { SalesMetricT } from 'types/SalesApi'

const getPreviousDay = (): string => {
  const date = new Date()
  date.setDate(date.getDate() - 1)
  return date.toISOString().slice(0, 10)
}

const defaultSalesMetric: SalesMetricT = {
  business_date: getPreviousDay(),
  currency_code: '',
  employee_id: '',
  digital_gross_amt_ty: 0,
  digital_gross_amt_ly: 0,
  digital_return_amt_ty: 0,
  digital_return_amt_ly: 0,
  digital_net_amt_ty: 0,
  digital_net_amt_ly: 0,
  digital_gross_amt_w2d: 0,
  digital_gross_amt_w2d_ly: 0,
  digital_return_amt_w2d: 0,
  digital_return_amt_w2d_ly: 0,
  digital_net_amt_w2d: 0,
  digital_net_amt_w2d_ly: 0,
  digital_gross_amt_m2d: 0,
  digital_gross_amt_m2d_ly: 0,
  digital_return_amt_m2d: 0,
  digital_return_amt_m2d_ly: 0,
  digital_net_amt_m2d: 0,
  digital_net_amt_m2d_ly: 0,
  digital_gross_amt_pp2d: 0,
  digital_gross_amt_pp2d_ly: 0,
  digital_return_amt_pp2d: 0,
  digital_return_amt_pp2d_ly: 0,
  digital_net_amt_pp2d: 0,
  digital_net_amt_pp2d_ly: 0,
  digital_gross_amt_y2d: 0,
  digital_gross_amt_y2d_ly: 0,
  digital_return_amt_y2d: 0,
  digital_return_amt_y2d_ly: 0,
  digital_net_amt_y2d: 0,
  digital_net_amt_y2d_ly: 0,
  store_gross_amt_ty: 0,
  store_gross_amt_ly: 0,
  store_net_amt_ty: 0,
  store_net_amt_ly: 0,
  store_gross_amt_w2d: 0,
  store_gross_amt_w2d_ly: 0,
  store_return_amt_w2d: 0,
  store_return_amt_w2d_ly: 0,
  store_net_amt_w2d: 0,
  store_net_amt_w2d_ly: 0,
  store_gross_amt_m2d: 0,
  store_gross_amt_m2d_ly: 0,
  store_return_amt_m2d: 0,
  store_return_amt_m2d_ly: 0,
  store_net_amt_m2d: 0,
  store_net_amt_m2d_ly: 0,
  store_gross_amt_pp2d: 0,
  store_gross_amt_pp2d_ly: 0,
  store_return_amt_pp2d: 0,
  store_return_amt_pp2d_ly: 0,
  store_net_amt_pp2d: 0,
  store_net_amt_pp2d_ly: 0,
  store_gross_amt_y2d: 0,
  store_gross_amt_y2d_ly: 0,
  store_return_amt_y2d: 0,
  store_return_amt_y2d_ly: 0,
  store_net_amt_y2d: 0,
  store_net_amt_y2d_ly: 0,
  gross_amt_ty: 0,
  gross_amt_ly: 0,
  gross_amt_w2d: 0,
  gross_amt_w2d_ly: 0,
  gross_amt_m2d: 0,
  gross_amt_m2d_ly: 0,
  gross_amt_pp2d: 0,
  gross_amt_pp2d_ly: 0,
  gross_amt_y2d: 0,
  gross_amt_y2d_ly: 0,
  return_amt_ty: 0,
  return_amt_ly: 0,
  return_amt_w2d: 0,
  return_amt_w2d_ly: 0,
  return_amt_m2d: 0,
  return_amt_m2d_ly: 0,
  return_amt_pp2d: 0,
  return_amt_pp2d_ly: 0,
  return_amt_y2d: 0,
  return_amt_y2d_ly: 0,
  net_amt_ty: 0,
  net_amt_ly: 0,
  net_amt_w2d: 0,
  net_amt_w2d_ly: 0,
  net_amt_m2d: 0,
  net_amt_m2d_ly: 0,
  net_amt_pp2d: 0,
  net_amt_pp2d_ly: 0,
  net_amt_y2d: 0,
  net_amt_y2d_ly: 0
}

const baseQueryWithRetries = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const result = await fetchBaseQuery({
      baseUrl: process.env.REACT_APP_SALES_API_URL,
      prepareHeaders: (headers) => {
        setStandardHeaders({ headers: headers })
        const accessToken = oktaTokenStorage.getOktaAccessToken()
        const idToken = oktaTokenStorage.getOktaIdToken()

        if (accessToken) {
          headers.set('Nord-Okta-Id-Token', idToken)
          headers.set('Authorization', `Bearer ${accessToken}`)
        }

        return headers
      }
    })(args, api, extraOptions)

    if (result) {
      if (result.error) {
        if (result.error.status === 404) {
          // Return default response if 404 error
          return { data: defaultSalesMetric }
        }
        const nordRequestId =
          result.meta?.request.headers.get('nord-request-id')
        const errorWithMetaData = {
          error: { ...result.error, meta: { nordRequestId } }
        }

        if (process.env.NODE_ENV === 'test') {
          retry.fail(result.error)
        }

        return errorWithMetaData
      }
    }

    return result
  },
  {
    maxRetries: 2
  }
)

export const salesApi = createApi({
  reducerPath: 'salesApi',
  baseQuery: baseQueryWithRetries,
  tagTypes: ['Sales'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getSales: builder.query<SalesMetricT, void>({
      query: () => {
        return {
          url: '/get_data/',
          method: 'GET'
        }
      },
      providesTags: ['Sales']
    })
  })
})

export const { useGetSalesQuery } = salesApi
