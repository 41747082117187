import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import { twilioActions, twilioUtils } from 'services/twilioSlice'
import { useLazyGetTwilioTokenQuery } from 'services/twilioApi'
import {
  Conversation,
  Message,
  Client as TwilioConversationClient
} from '@twilio/conversations'

import { useAppDispatch, useAppSelector } from 'app/hooks'
import { isMessagingUser } from 'utils/userPermissions'
import { useFeatureFlags } from './FeatureFlagsContext'
import { getTwilioSdkClient } from 'app/twilioSdkClient'
import { getOktaTokenSelector } from 'app/employeeSlice'
import {
  ReduxConversation,
  ReduxMessage,
  CONVERSATION_STATE
} from 'types/Twilio'

export type TwilioUpdateContextType = {
  client: TwilioConversationClient
  isError: boolean
  error?: unknown
}

const context = createContext<TwilioUpdateContextType>(
  {} as TwilioUpdateContextType
)

export const useTwilioClient = (): TwilioUpdateContextType =>
  useContext(context)

export const TwilioClientProvider = ({
  children
}: {
  children: ReactNode
}): JSX.Element => {
  const appDispatch = useAppDispatch()

  const [client, setClient] = useState<TwilioConversationClient>()
  const wasClientInstanceSetRef = useRef(false)
  const [isTwilioContextError, setIsTwilioContextError] = useState(false)

  const { isConversationWithCustomerIdEnabled } = useFeatureFlags()
  const didFeatureFlagsLoad = !!Object.keys(useFeatureFlags())?.length
  const oktaAccessToken = useAppSelector(getOktaTokenSelector)

  const [getTwilioToken, result] = useLazyGetTwilioTokenQuery()
  const {
    data: twilioTokenData,
    isUninitialized: isTwilioTokenFetchUninit,
    isError: isTwilioTokenError,
    error
  } = result

  const twilioToken = twilioTokenData?.token

  const canUserViewMessaging = isMessagingUser()

  const amountOfConversationsRef = useRef(0)

  const getConversations = useCallback(async (client) => {
    try {
      const paginator = await client.getSubscribedConversations()

      const allConversations = await twilioUtils.getAllConversationsRecursively(
        paginator
      )
      amountOfConversationsRef.current = allConversations?.length
      return allConversations
    } catch (error) {
      setIsTwilioContextError(true)
      return []
    }
  }, [])

  const getUnreadMessagesCount = useCallback(async (conversation) => {
    try {
      return await conversation.getUnreadMessagesCount()
    } catch {
      return null
    }
  }, [])

  const setUnreadMessagesCountInRedux = useCallback(
    (conversationSid, unreadMessagesCount) => {
      appDispatch(
        twilioActions.updateUnreadMessagesCount({
          conversationSid,
          overwriteAmount: unreadMessagesCount || 0
        })
      )
    },
    [appDispatch]
  )

  const getMostRecentMessage = useCallback(async (conversation) => {
    try {
      const messagePaginator = await conversation.getMessages(1)
      const mostRecentMessage = messagePaginator.items[0]
      return mostRecentMessage
    } catch {
      return {} as Message
    }
  }, [])

  const handleNewMessageOrConversation = useCallback(
    async ({
      recentTwilioMessage,
      recentTwilioConversation
    }: {
      recentTwilioMessage: Message
      recentTwilioConversation: Conversation
    }) => {
      const isRecentMessageEmpty = !recentTwilioMessage
      const formattedMessage: ReduxMessage = await twilioUtils.formatMessage(
        recentTwilioMessage
      )
      const formattedConversation =
        await twilioUtils.formatConversationForRedux(
          recentTwilioConversation,
          undefined,
          isConversationWithCustomerIdEnabled?.active
        )
      const conversationSid = formattedConversation.sid

      if (
        recentTwilioMessage?.body &&
        recentTwilioMessage?.body.trim().toLowerCase() === 'stop'
      ) {
        formattedConversation.state = CONVERSATION_STATE.INACTIVE
      }

      // New Message, New Customer-Initiated Conversation (with message), or New Empty Seller-Initiated Conversation in RealTime
      appDispatch(
        twilioActions.addOrUpdateConversationInRealTime({
          conversation: {
            ...formattedConversation,
            mostRecentMessageDate: isRecentMessageEmpty
              ? ''
              : formattedMessage.dateCreated
          }
        })
      )
      // New Message or New Customer-Initiated Conversation (with message) in RealTime
      if (!isRecentMessageEmpty) {
        const unreadMessagesCount = await getUnreadMessagesCount(
          recentTwilioConversation
        )

        appDispatch(
          twilioActions.addRealTimeMessage({
            message: formattedMessage,
            conversationSid
          })
        )
        setUnreadMessagesCountInRedux(conversationSid, unreadMessagesCount)
      }
    },
    [
      appDispatch,
      getUnreadMessagesCount,
      setUnreadMessagesCountInRedux,
      isConversationWithCustomerIdEnabled
    ]
  )

  useEffect(() => {
    if (
      !canUserViewMessaging ||
      wasClientInstanceSetRef.current ||
      !didFeatureFlagsLoad
    ) {
      return
    }
    // fetch twilio token
    if (oktaAccessToken && isTwilioTokenFetchUninit) {
      appDispatch(twilioActions.setIsLoading({ value: true }))
      getTwilioToken({
        oktaAccessToken
      })
    }
    // set Twilio SDK Client
    if (twilioToken) {
      const sdkClient = getTwilioSdkClient(twilioToken)
      setClient(sdkClient)
      wasClientInstanceSetRef.current = true
      appDispatch(twilioActions.setAuthToken({ token: twilioToken }))
      setIsTwilioContextError(false)
      sdkClient.on('initialized', async () => {
        const allConversations = await getConversations(sdkClient)
        const reduxReadyConversations: Record<string, ReduxConversation> = {}
        await Promise.all(
          allConversations.map(async (conversation) => {
            const unreadMessagesCount = await getUnreadMessagesCount(
              conversation
            )
            const formattedConversation =
              await twilioUtils.formatConversationForRedux(
                conversation,
                unreadMessagesCount,
                isConversationWithCustomerIdEnabled?.active
              )
            reduxReadyConversations[conversation.sid] = formattedConversation
          })
        )

        appDispatch(
          twilioActions.setConversations({
            conversations: reduxReadyConversations
          })
        )
        appDispatch(twilioActions.setIsLoading({ value: false }))
      })

      sdkClient.on('initFailed', () => {
        setIsTwilioContextError(true)
        appDispatch(twilioActions.setIsLoading({ value: false }))
      })

      sdkClient.on('connectionStateChanged', (state) => {
        if (state === 'denied' || state === 'error') {
          setIsTwilioContextError(true)
          appDispatch(twilioActions.setIsLoading({ value: false }))
        } else if (isTwilioContextError) {
          setIsTwilioContextError(false)
        }
      })

      sdkClient.on('messageAdded', async (message) => {
        const updatedConversation = message.conversation
        handleNewMessageOrConversation({
          recentTwilioMessage: message,
          recentTwilioConversation: updatedConversation
        })
      })

      sdkClient.on('messageUpdated', async ({ message, updateReasons }) => {
        if (updateReasons[0] === 'deliveryReceipt') {
          try {
            const deliveryReceipt = await message.getDetailedDeliveryReceipts()
            if (!deliveryReceipt?.length) {
              return
            }
            const { conversationSid, messageSid, status, errorCode } =
              deliveryReceipt[0]
            appDispatch(
              twilioActions.updateMessageStatus({
                conversationSid,
                messageSid,
                status,
                errorCode
              })
            )
          } catch (error) {
            console.error(error)
          }
        }
      })

      sdkClient.on(
        'conversationUpdated',
        async ({ conversation, updateReasons }) => {
          if (updateReasons[0] === 'lastReadMessageIndex') {
            const conversationSid = conversation.sid

            /* conversation.getUnreadMessagesCount() does not always return an accurate count since it is updated on Twilio's end with a delay,
            so we have added a slight delay on our end before we invoke the method to ensure greater accuracy */
            await new Promise((resolve) => setTimeout(resolve, 250)) // 250 ms --> quarter-second delay (1 second = 1000 ms)
            const unreadMessagesCount = await getUnreadMessagesCount(
              conversation
            )
            setUnreadMessagesCountInRedux(conversationSid, unreadMessagesCount)
          }
        }
      )

      sdkClient.on('conversationAdded', async (conversation) => {
        /* Since this event is called not only for new conversations but for the current ones too,
           this validation is required so the inner code is not triggered when the app loads */
        if (amountOfConversationsRef.current === 0) {
          return
        }
        amountOfConversationsRef.current += 1
        const mostRecentMessage = await getMostRecentMessage(conversation)
        handleNewMessageOrConversation({
          recentTwilioMessage: mostRecentMessage,
          recentTwilioConversation: conversation
        })
      })

      sdkClient.on('tokenAboutToExpire', async () => {
        const result = await getTwilioToken({
          oktaAccessToken
        })
        if (result.data?.token) {
          await sdkClient.updateToken(result.data.token)
        }
      })
    }
    // set Twilio Context-related error
    if (isTwilioTokenError) {
      setIsTwilioContextError(true)
      appDispatch(twilioActions.setIsLoading({ value: false }))
    }
  }, [
    appDispatch,
    canUserViewMessaging,
    isTwilioContextError,
    isTwilioTokenError,
    isTwilioTokenFetchUninit,
    oktaAccessToken,
    twilioToken,
    didFeatureFlagsLoad,
    wasClientInstanceSetRef,
    getTwilioToken,
    handleNewMessageOrConversation,
    getConversations,
    getUnreadMessagesCount,
    setUnreadMessagesCountInRedux,
    getMostRecentMessage,
    isConversationWithCustomerIdEnabled
  ])

  return (
    <context.Provider
      // Todo: Error handling on client when not defined in context!
      value={{
        client: client as TwilioConversationClient,
        isError: isTwilioContextError,
        error
      }}
    >
      {children}
    </context.Provider>
  )
}
