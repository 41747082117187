import { SkuPriceT, StylePriceT } from 'types/Product'
import { formatProductPrice } from 'utils/formatPrice'

type MinAndMaxT = { min: string; max: string }
type DisplayStylePriceT = Record<string, MinAndMaxT>
type DisplaySkuPriceT = Record<string, string>

export const isStyleSoldOut = (stylePrice: StylePriceT): boolean => {
  const SOLD_OUT = '$0.00'
  const formattedStylePrices = formatStylePrices(stylePrice)
  return (
    formattedStylePrices.totalPriceRange.min === SOLD_OUT &&
    formattedStylePrices.totalPriceRange.max === SOLD_OUT
  )
}

export const formatSkuPrices = (skuPrice: SkuPriceT): DisplaySkuPriceT => {
  const formattedPrices: DisplaySkuPriceT = {}

  if (skuPrice.currentPriceType) {
    formattedPrices.currentPriceType = skuPrice.currentPriceType
  }

  if (skuPrice.clearance) {
    formattedPrices.clearance = formatProductPrice(
      skuPrice.clearance.price.units,
      skuPrice.clearance.price.nanos
    )
  }

  if (skuPrice.promotion) {
    formattedPrices.promotion = formatProductPrice(
      skuPrice.promotion.price.units,
      skuPrice.promotion.price.nanos
    )
  }

  if (skuPrice.regular) {
    formattedPrices.regular = formatProductPrice(
      skuPrice.regular.price.units,
      skuPrice.regular.price.nanos
    )
  }

  return formattedPrices
}

export const formatStylePrices = (
  stylePrice: StylePriceT
): DisplayStylePriceT => {
  const formattedPrices: DisplayStylePriceT = {}

  if (stylePrice.clearance) {
    formattedPrices.clearance = {
      min: formatProductPrice(
        stylePrice.clearance.priceRange.min.units,
        stylePrice.clearance.priceRange.min.nanos
      ),
      max: formatProductPrice(
        stylePrice.clearance.priceRange.max.units,
        stylePrice.clearance.priceRange.max.nanos
      )
    }
  }

  if (stylePrice.promotion) {
    formattedPrices.promotion = {
      min: formatProductPrice(
        stylePrice.promotion.priceRange.min.units,
        stylePrice.promotion.priceRange.min.nanos
      ),
      max: formatProductPrice(
        stylePrice.promotion.priceRange.max.units,
        stylePrice.promotion.priceRange.max.nanos
      )
    }
  }

  if (stylePrice.regular) {
    formattedPrices.regular = {
      min: formatProductPrice(
        stylePrice.regular.priceRange.min.units,
        stylePrice.regular.priceRange.min.nanos
      ),
      max: formatProductPrice(
        stylePrice.regular.priceRange.max.units,
        stylePrice.regular.priceRange.max.nanos
      )
    }
  }

  if (stylePrice.totalPriceRange) {
    formattedPrices.totalPriceRange = {
      min: formatProductPrice(
        stylePrice.totalPriceRange.min.units,
        stylePrice.totalPriceRange.min.nanos
      ),
      max: formatProductPrice(
        stylePrice.totalPriceRange.max.units,
        stylePrice.totalPriceRange.max.nanos
      )
    }
  }

  return formattedPrices
}

export const shouldShowPriceRange = (price: MinAndMaxT): boolean => {
  return !!price.min && !!price.max && price.min !== price.max
}
