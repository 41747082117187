import { ReactElement } from 'react'
import { Box, Typography } from '@mui/material'

const NoFollowUps = (): ReactElement => {
  return (
    <Box>
      <Typography variant="caption" color="GrayText">
        No outstanding follow ups
      </Typography>
    </Box>
  )
}

export default NoFollowUps
