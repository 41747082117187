import { Box, Typography, Button } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'

const LearnMore = (): JSX.Element => {
  const stylingHubLink =
    'https://nordstrom.sharepoint.com/sites/PersonalStylingandSales/SitePages/Discover.aspx'
  const payCalendarLink =
    'https://nordstrom.service-now.com/esc?id=kb_article&table=kb_knowledge&sysparm_article=KB0050392'
  const pbSurveyLink = 'https://survey.alchemer.com/s3/7999498/9ef8fdf11f3d'

  const { isPBSurveyLinkEnabled } = useFeatureFlags()

  const handleVisitClick = (link: string) => {
    const attributes = {
      page: 'homepage'
    }
    interface SourceMap {
      [key: string]: string
    }
    const sourceMap: SourceMap = {
      [stylingHubLink]: 'stylingHubLinkClick',
      [payCalendarLink]: 'payCalendarLinkClick',
      [pbSurveyLink]: 'personalBookSurveyLinkClick'
    }
    if (sourceMap[link]) {
      generateNewRelicLogs(sourceMap[link], attributes)
    }

    window.open(link, '_blank', 'noreferrer')
  }

  return (
    <Box px={2} mt={3}>
      <Typography variant="h6">Learn more</Typography>
      <Box px={2}>
        <Typography variant="body2" mt={2}>
          Styling hub
          <Button
            startIcon={<LaunchIcon />}
            onClick={() => handleVisitClick(stylingHubLink)}
            sx={{ ml: 2 }}
          >
            Visit
          </Button>
        </Typography>
        <Typography variant="body2" mt={2}>
          Pay period calendar
          <Button
            startIcon={<LaunchIcon />}
            onClick={() => handleVisitClick(payCalendarLink)}
            sx={{ ml: 2 }}
          >
            Visit
          </Button>
        </Typography>
        {isPBSurveyLinkEnabled?.active && (
          <Typography variant="body2" mt={2}>
            Personal Book survey
            <Button
              startIcon={<LaunchIcon />}
              onClick={() => handleVisitClick(pbSurveyLink)}
              sx={{ ml: 2 }}
            >
              Visit
            </Button>
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default LearnMore
