import { ReactElement } from 'react'
import { Container, Typography } from '@mui/material'
import { AppointmentListAppointmentDateT } from 'types/Appointments'
import AppointmentsDate from './AppointmentsDate'

const AppointmentsDateList = ({
  appointmentsList
}: {
  appointmentsList: AppointmentListAppointmentDateT[]
}): ReactElement => {
  return (
    <>
      <Container>
        <Typography>
          Showing your appointments for the next three weeks.
        </Typography>
      </Container>

      {appointmentsList.map((appointmentDate) => (
        <AppointmentsDate
          key={appointmentDate.date}
          appointmentDate={appointmentDate}
        />
      ))}
    </>
  )
}

export default AppointmentsDateList
