import orderBy from 'lodash/fp/orderBy'
import groupBy from 'lodash/fp/groupBy'
import flow from 'lodash/fp/flow'
import moment from 'moment'
import {
  ConsentedCustomerT,
  ConsentedCustomerListItemT,
  ExtendedConsentedCustomerListItemT,
  TripsByCustomerIdT,
  LastPurchaseDateByCustomerIdT,
  LastPurchaseDateWithEmployeeByCustomerIdT
} from 'types/ConsentedCustomer'
import { ConversationsRecord } from 'types/Twilio'

export interface DataForFilteringAndSorting {
  conversations: ConversationsRecord
  tripsCount?: Record<string, number>
  lastPurchaseDate?: Record<string, string>
  lastPurchaseDateWithEmployee?: Record<string, string>
}

const NO_CUSTOMER_ON_FILE_KEY = 'No customer name on file'

const firstNameToSort = (customer: ConsentedCustomerListItemT) =>
  customer.firstName ? customer.firstName.toLowerCase() : ''
const lastNameToSort = (customer: ConsentedCustomerListItemT) =>
  customer.lastName ? customer.lastName.toLowerCase() : ''
const groupingCriteria = (customer: ConsentedCustomerListItemT) =>
  customer.firstName && customer.lastName
    ? customer.firstName?.[0].toUpperCase()
    : NO_CUSTOMER_ON_FILE_KEY

export const mapCustomerListToAlphabeticalSections = (
  customerList: ConsentedCustomerListItemT[]
): Record<string, ConsentedCustomerListItemT[]> =>
  flow(
    orderBy<ConsentedCustomerListItemT>(
      [firstNameToSort, lastNameToSort],
      ['asc', 'asc']
    ),
    groupBy<ConsentedCustomerListItemT>(groupingCriteria),
    (customersMap) => {
      const noCustomerOnFileData = customersMap[NO_CUSTOMER_ON_FILE_KEY]
      if (noCustomerOnFileData) {
        const orderedNoCustomerOnFileData = orderBy<ConsentedCustomerListItemT>(
          (customer) => customer.email
        )('asc')(noCustomerOnFileData)
        delete customersMap[NO_CUSTOMER_ON_FILE_KEY]
        customersMap[NO_CUSTOMER_ON_FILE_KEY] = orderedNoCustomerOnFileData
      }
      return customersMap
    }
  )(customerList)

export const filterByQuerySearch = (
  query: string,
  customers: ConsentedCustomerListItemT[]
): ConsentedCustomerListItemT[] =>
  customers.filter((item) => {
    const firstName = item.firstName?.toLowerCase() || ''
    const lastName = item.lastName?.toLowerCase() || ''
    const lowerCaseQuery = query.toLowerCase().replace(/\s/g, '') //remove the whitespace

    return (
      (firstName + lastName).includes(lowerCaseQuery) ||
      (lastName + firstName).includes(lowerCaseQuery) ||
      item.email?.includes(lowerCaseQuery) ||
      item.mobile?.includes(lowerCaseQuery)
    )
  })

export const shouldDisplayNewCustomerBadge: (
  customerCreationTime?: ConsentedCustomerT['relationshipDate']
) => boolean = (
  customerCreationTime?: ConsentedCustomerT['relationshipDate']
) => {
  if (!customerCreationTime) return false
  return moment(new Date()).diff(moment(customerCreationTime), 'days') < 8
}

const addExtendedCustomerDataByCustomerId = <
  T extends keyof ExtendedConsentedCustomerListItemT
>(
  customers: ExtendedConsentedCustomerListItemT[],
  properyName: T,
  propertyByCustomerId: Record<string, ExtendedConsentedCustomerListItemT[T]>
): ExtendedConsentedCustomerListItemT[] =>
  customers.map((customer) => {
    const propertyValue = propertyByCustomerId[customer.customerId]
    return {
      ...customer,
      [properyName]: propertyValue
    }
  })

export const addTripsCountsByCustomerIds = (
  customers: ExtendedConsentedCustomerListItemT[],
  tripsByCustomerId: TripsByCustomerIdT
): ExtendedConsentedCustomerListItemT[] =>
  addExtendedCustomerDataByCustomerId(
    customers,
    'tripsCount',
    tripsByCustomerId
  )

export const addLastPurchaseDateByCustomerId = (
  customers: ExtendedConsentedCustomerListItemT[],
  lastPurchaseDateByCustomerId: LastPurchaseDateByCustomerIdT
): ExtendedConsentedCustomerListItemT[] =>
  addExtendedCustomerDataByCustomerId(
    customers,
    'lastPurchaseDate',
    lastPurchaseDateByCustomerId
  )

export const addLastPurchaseDateWithEmployeeByCustomerId = (
  customers: ExtendedConsentedCustomerListItemT[],
  lastPurchaseDateWithEmployeeByCustomerId: LastPurchaseDateWithEmployeeByCustomerIdT
): ExtendedConsentedCustomerListItemT[] =>
  addExtendedCustomerDataByCustomerId(
    customers,
    'lastPurchaseDateWithEmployee',
    lastPurchaseDateWithEmployeeByCustomerId
  )
