export enum CustomerBookFilterKeys {
  ANNIVERSARY = 'qualification',
  NORDY_CLUB = 'loyaltyStatus',
  CARDMEMBER = 'cardmember',
  NORDSTROM_NOTES = 'nordstromNotes',
  BENEFITS = 'benefits',
  TRIPS_COUNT = 'tripsCount',
  LAST_PURCHASE_WITH_YOU = 'lastPurchaseDateWithEmployee',
  LAST_PURCHASE = 'lastPurchaseDate',
  LAST_MESSAGE_TIME = 'lastMessage',
  SAVED_BRANDS = 'brands'
}

export type CustomerBookFilterOption = {
  name: string
  checked: boolean
  dateEA?: string
  nameOverride?: string
}

export type CustomerBookFilter = {
  title: string
  options: CustomerBookFilterOption[]
  selectedOptionsCounter: number
  disabled: boolean
}
export type CustomerBookFilters = Record<
  CustomerBookFilterKeys,
  CustomerBookFilter
>

export enum CustomerBookNoteExpirationFilterCategories {
  TWO_WEEKS = 'Expiring within 2 weeks',
  ONE_MONTH = 'Expiring within 1 month',
  THREE_MONTHS = 'Expiring within 3 months',
  AVAILABLE_NOTES = 'Any available notes'
}

export enum AnniversaryEventFilterCategories {
  ICON_EA = 'Icon EA',
  AMBASSADOR_EA = 'Ambassador EA',
  INFLUENCER_EA = 'Influencer EA',
  ACCESS_PASS = 'Access Pass',
  PUBLIC_SALE = 'Public Sale'
}

/* Anniversary Dates - 2024 */
export enum AnniversaryDateFilterCategories {
  ICON_EA = '7/9',
  AMBASSADOR_EA = '7/10',
  INFLUENCER_EA = '7/11',
  ACCESS_PASS = '7/13',
  PUBLIC_SALE = '7/15'
}

export enum CustomerBookDateFilterCategories {
  ONE_MONTH = 'Last 30 days',
  MORE_THAN_ONE_MONTH = '1+ month ago',
  MORE_THAN_THREE_MONTHS = '3+ months ago',
  MORE_THAN_SIX_MONTHS = '6+ months ago',
  MORE_THAN_NINE_MONTHS = '9+ months ago',
  MORE_THAN_TWELVE_MONTHS = '12+ months ago',
  NO_VALUE = 'No value'
}

export enum CustomerBookDateFilterEmptyValues {
  NO_MESSAGES = 'No messages',
  NO_PURCHASES = 'No purchases',
  MORE_THAN_TWELVE_MONTHS_OR_NO_PURCHASES = '12+ months ago or no purchases'
}

export enum CUSTOMER_BOOK_TRIPS_COUNT_FILTER_CATEGORIES {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE_OR_MORE = '3+'
}

export const CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES = {
  DOUBLE_POINTS: 'Personal double points day available',
  ALTERATION_BALANCE: 'Alterations Notes reimbursement available'
} as const
