import { ReactElement } from 'react'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import Drawer from 'components/Drawer'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'
import RecentItemsNavigation from 'pages/RecentItemsDrawer/components/RecentItemsNavigation'
import BeautyRestocks from './components/BeautyRestocks/BeautyRestocks'
import { BeautyRestockOrderItemT } from 'types/Orders'

export type PropsT = {
  handleClickForMiniPDP: ({
    webStyleId,
    miniPDPOpenedFrom,
    rmsSku
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    rmsSku: string
  }) => void
  onClose: () => void
  open: boolean
  beautyPurchaseHistoryData: BeautyRestockOrderItemT[]
}

const BeautyRestocksDrawer = (props: PropsT): ReactElement | null => {
  const { handleClickForMiniPDP, onClose, open, beautyPurchaseHistoryData } =
    props

  return (
    <Drawer onClose={onClose} open={open}>
      <BoxWithGladlySpace sx={{ px: 2 }}>
        <RecentItemsNavigation
          title="Recent Beauty Purchases"
          onClick={onClose}
        />
        <BeautyRestocks
          handleClickForMiniPDP={handleClickForMiniPDP}
          beautyRestockData={beautyPurchaseHistoryData}
        />
      </BoxWithGladlySpace>
    </Drawer>
  )
}

export default BeautyRestocksDrawer
