import { Box, Button, Typography } from '@mui/material'
import { ExtendedFollowUpT } from 'types/FollowUps'
import { useRef, useState } from 'react'
import FollowUpCards from './FollowUpCards'
import ShowAllFollowUpsButton from './ShowAllFollowUpsButton'
import NoFollowUps from './NoFollowUps'
import { CopyErrorDataT } from 'types/CopyErrorData'
import FollowUpsError from './FollowUpsError'

type PropsT = {
  followUps: ExtendedFollowUpT[]
  customerId: string
  completedFollowUpsCount: number
  error?: CopyErrorDataT
}

const MAX_COLLAPSED_FOLLOW_UPS = 3

const FollowUpSuggestions = ({
  followUps,
  customerId,
  completedFollowUpsCount,
  error
}: PropsT): JSX.Element => {
  const listRef = useRef<HTMLDivElement>(null)
  const [scrollPosition, setScrollPosition] = useState<number | undefined>(0)
  const [isListExpanded, setIsListExpanded] = useState(false)

  const collapsedFollowUps = followUps.slice(0, MAX_COLLAPSED_FOLLOW_UPS)

  const toggleIsListExpanded = () => {
    if (!isListExpanded) {
      setScrollPosition(listRef.current?.scrollHeight)
    } else {
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' })
    }

    setIsListExpanded(!isListExpanded)
  }

  return (
    <Box ref={listRef} sx={{ px: '16px', pb: '12px' }}>
      <Typography variant="subtitle2" mt="12px">
        Follow up
      </Typography>

      {followUps.length > 0 ? (
        <FollowUpCards
          followUps={isListExpanded ? followUps : collapsedFollowUps}
          customerId={customerId}
        />
      ) : !error ? (
        <NoFollowUps />
      ) : (
        <FollowUpsError error={error} />
      )}

      {followUps.length > MAX_COLLAPSED_FOLLOW_UPS && (
        <ShowAllFollowUpsButton
          isListExpanded={isListExpanded}
          toggleIsListExpanded={toggleIsListExpanded}
        />
      )}

      {completedFollowUpsCount > 0 && (
        <Button fullWidth variant="outlined">
          View Completed
        </Button>
      )}
    </Box>
  )
}

export default FollowUpSuggestions
