import {
  ReactElement,
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback
} from 'react'
import { Container, Typography, Stack, Box } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import { useNavigation } from 'contexts/NavigationContext'
import { useAppSelector } from 'app/hooks'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import {
  customerBookFiltersCounterSelector,
  customerBookFiltersSelector
} from 'services/customerBookFiltersSlice'
import {
  useGetConsentedCustomerListQuery,
  useUserHasActiveCommunicationsQuery,
  useGetConsentedCustomerListWithoutFiltersQuery,
  useLazyGetExtendedCustomerListDataQuery,
  useGetFollowUpOpportunitiesQuery
} from 'services/clientelingApi'
import { checkBrowserClient } from 'utils/userAgentDetector'
import useWindowDimensions from 'utils/useWindowDimensions'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import SearchBar from './components/SearchBar'
import FiltersButton from './components/FiltersButton'
import CustomerHomeHeader from './components/Header'
import FiltersDrawer, { FILTERS_HASH } from './components/FiltersDrawer'
import { ConsentedCustomerListItemT } from 'types/ConsentedCustomer'
import { CustomerBookFilters } from 'types/CustomerBookFilters'
import { filterByQuerySearch } from './utils/customers'
import { applyFilters } from './utils/filterHelpers'
import oktaTokenStorage from 'utils/okta-token-utils'
import { hideWidget, showWidget } from 'venderScripts/gladly'
import ArchivedCustomerItem from './components/ArchivedCustomerItem/ArchivedCustomerItem'
import CustomerList from './components/CustomerList'
import EmptyCustomerBook from './components/EmptyCustomerBook'
import { conversationsSelector } from 'services/twilioSlice/selectors'
import SortModal, { SORT_STORAGE_KEY } from './components/SortModal/SortModal'
import SortButton from './components/SortButton/SortButton'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { NR_CLIENTELING_PAGES } from 'constants/clienteling/nrClientelingPages'
import {
  NR_CUSTOMER_BOOK_PAGE_VIEW_CUSTOMER,
  NR_CUSTOMER_BOOK_PAGE_VIEW_FOLLOW_UPS
} from 'constants/clienteling/newRelicEvents/nrCustomerBookPage'
import {
  extendCustomersForSorting,
  isExtendedCustomerSortSelected
} from './utils/sortHelpers'
import FollowUpsSummary from './components/FollowUpsSummary'
import { CUSTOMER_SORT_OPTIONS, CustomerSortKeysT } from './constants'

const isMobileDevice = checkBrowserClient.isMobile()
const CustomerListContainer = ({
  children,
  isCommunicationsActive,
  isError
}: {
  children: JSX.Element
  isCommunicationsActive: boolean | undefined
  isError: boolean
}): ReactElement => {
  return (
    <Container
      sx={{
        maxWidth: 1040,
        height: '100%',
        paddingRight: isMobileDevice && 0,
        paddingLeft: isMobileDevice && 0,
        mt: '20px',
        mb: '72px'
      }}
    >
      {(isCommunicationsActive || isError) && children}
    </Container>
  )
}

enum EmptyStateValues {
  NO_CUSTOMERS = 'noCustomers',
  NO_FILTERS_RESULTS = 'noFiltersResults',
  NO_SEARCH_RESULTS = 'noSearchResults'
}

const CustomerHome = (): ReactElement => {
  const [requestWithFiltersSuccessful, setRequestWithFiltersSuccessful] =
    useState(true)
  const [isSortModalOpen, setIsSortModalOpen] = useState(false)
  const [query, setQuery] = useState<string>('')
  const [emptyState, setEmptyState] = useState<EmptyStateValues>(
    EmptyStateValues.NO_CUSTOMERS
  )
  const [customers, setCustomers] = useState<ConsentedCustomerListItemT[]>([])
  const [sortFunctionKey, setSortFunctionKey] = useState<CustomerSortKeysT>(
    CUSTOMER_SORT_OPTIONS.BY_ALPHABETICAL
  )

  const employeeId = oktaTokenStorage
    .getEmployeeNumberFromOktaToken()
    .toString()

  const listRef = useRef<HTMLUListElement>(null)
  const { height } = useWindowDimensions()

  const history = useHistory()
  const { hash } = useLocation()

  const featureFlags = useFeatureFlags()
  const {
    isClientelingAnniversaryStylingAppEnabled,
    isCustomerListTripsFilterEnabled,
    isCustomerListLastPurchaseFilterEnabled,
    isCustomerListTripsSortEnabled,
    isNordstromPurchaseSortEnabled,
    isCustomerListLastPurchaseWithEmployeeFilterEnabled,
    isFollowUpsFeatureEnabled,
    isCustomerListLastPurchaseWithEmployeeSortEnabled
  } = featureFlags

  const featureFlagsLoaded = !!Object.keys(featureFlags)?.length

  const customerBookFiltersCounter = useAppSelector(
    customerBookFiltersCounterSelector
  )
  const customerBookFilters = useAppSelector(customerBookFiltersSelector)

  const isExtendedCustomerFilterSelected =
    !!customerBookFilters?.tripsCount?.selectedOptionsCounter ||
    !!customerBookFilters?.lastPurchaseDate?.selectedOptionsCounter ||
    !!customerBookFilters?.lastPurchaseDateWithEmployee.selectedOptionsCounter

  const isExtendedCustomerFilterOnAndOpenOrSelected =
    (!!isCustomerListTripsFilterEnabled?.active ||
      !!isCustomerListLastPurchaseFilterEnabled?.active ||
      !!isCustomerListLastPurchaseWithEmployeeFilterEnabled?.active) &&
    (isExtendedCustomerFilterSelected || hash.startsWith(`#${FILTERS_HASH}`))

  const isExtendedCustomerSortOnAndOpenOrSelected =
    (!!isCustomerListTripsSortEnabled?.active ||
      !!isNordstromPurchaseSortEnabled?.active ||
      !!isCustomerListLastPurchaseWithEmployeeSortEnabled?.active) &&
    (isExtendedCustomerSortSelected(sortFunctionKey) || isSortModalOpen)

  const conversations = useAppSelector(conversationsSelector)

  const customerListErrorTitle = 'Issue getting your customers.'

  const {
    data: customerListData = {
      customers: [],
      filters: {} as CustomerBookFilters,
      hasArchivedCustomers: false
    },
    isLoading: customerListDataIsLoading,
    isError: customerListDataIsError,
    endpointName: customerListDataEndpointName,
    error: customerListDataError
  } = useGetConsentedCustomerListQuery(
    {
      retrieveFilters: true,
      retrieveQualification: !!isClientelingAnniversaryStylingAppEnabled?.active
    },
    {
      skip: !featureFlagsLoaded
    }
  )

  const [
    getExtendedCustomerListData,
    {
      data: extendedCustomerData,
      isLoading: extendedCustomerDataIsLoading,
      isError: extendedCustomerDataIsError,
      error: extendedCustomerDataError,
      endpointName: extendedCustomerListDataEndpoint
    }
  ] = useLazyGetExtendedCustomerListDataQuery()

  const { data: followUpsData, isLoading: followUpsDataIsLoading } =
    useGetFollowUpOpportunitiesQuery(undefined, {
      skip: !isFollowUpsFeatureEnabled?.active
    })

  const customersWithFollowUpsCount = Object.keys(followUpsData ?? {}).length

  useEffect(() => {
    if (
      customerListDataError &&
      'status' in customerListDataError &&
      (customerListDataError.status === 504 ||
        customerListDataError.status === 499)
    ) {
      setRequestWithFiltersSuccessful(false)
    }
  }, [customerListDataError])

  const {
    data: customerListDataWithoutFilters = {
      customers: [],
      filters: {} as CustomerBookFilters
    }
  } = useGetConsentedCustomerListWithoutFiltersQuery(undefined, {
    skip: requestWithFiltersSuccessful
  })

  // To render the customer list from the request without filters
  useEffect(() => {
    if (
      !requestWithFiltersSuccessful &&
      customerListDataWithoutFilters.customers.length
    ) {
      setCustomers(customerListDataWithoutFilters.customers)
    }
  }, [customerListDataWithoutFilters, requestWithFiltersSuccessful])

  const { data: userCommunicationsData } = useUserHasActiveCommunicationsQuery({
    employeeId
  })

  const { setDisplayAppBar } = useNavigation()

  useEffect(() => {
    setDisplayAppBar(true)
  }, [setDisplayAppBar])

  const hasCustomers = useMemo(
    () => customers && customers?.length > 0,
    [customers]
  )

  const customerListSize = useMemo(() => customers?.length, [customers])

  useEffect(() => {
    window.addEventListener('beforeunload', storeScrollPosition)
  }, [])

  useEffect(() => {
    if (hasCustomers && !customerListDataIsLoading) {
      const position = localStorage.getItem('customerListScrollPosition')
      if (position) {
        setScrollPosition(position)
      }
    }
  }, [customerListDataIsLoading, hasCustomers])

  const setScrollPosition = (position: string) => {
    if (listRef.current) {
      listRef.current.scrollTop = Number(position) || 0
      localStorage.removeItem('customerListScrollPosition')
    }
  }

  const storeScrollPosition = () => {
    if (listRef.current) {
      localStorage.setItem(
        'customerListScrollPosition',
        String(listRef.current?.scrollTop)
      )
    }
  }

  const goToDetails = (customerId: string) => {
    generateNewRelicLogs(NR_CUSTOMER_BOOK_PAGE_VIEW_CUSTOMER, {
      page: NR_CLIENTELING_PAGES.CUSTOMER_BOOK_PAGE,
      ocpId: customerId
    })
    storeScrollPosition()
    history.push({
      pathname: `/customers/details/${customerId}`,
      state: { sourcePath: window.location.pathname }
    })
  }

  const goToFollowUpsPage = (followUpsCount: number) => {
    generateNewRelicLogs(NR_CUSTOMER_BOOK_PAGE_VIEW_FOLLOW_UPS, {
      page: NR_CLIENTELING_PAGES.CUSTOMER_BOOK_PAGE,
      followUpsCount
    })
    storeScrollPosition()
    history.push({
      pathname: '/customers/followups',
      state: { sourcePath: window.location.pathname }
    })
  }

  useEffect(() => {
    if (
      !extendedCustomerData &&
      (isExtendedCustomerFilterOnAndOpenOrSelected ||
        isExtendedCustomerSortOnAndOpenOrSelected)
    ) {
      getExtendedCustomerListData(
        {
          shouldFetchTripsCount:
            !!isCustomerListTripsFilterEnabled?.active ||
            !!isCustomerListTripsSortEnabled?.active,
          shouldFetchLastPurchaseDate:
            !!isCustomerListLastPurchaseFilterEnabled?.active ||
            !!isNordstromPurchaseSortEnabled?.active,
          shouldFetchLastPurchaseDateWithEmployee:
            !!isCustomerListLastPurchaseWithEmployeeFilterEnabled?.active ||
            !!isCustomerListLastPurchaseWithEmployeeSortEnabled?.active
        },
        true
      )
    }
  }, [
    isCustomerListTripsFilterEnabled?.active,
    isCustomerListTripsSortEnabled?.active,
    isCustomerListLastPurchaseFilterEnabled?.active,
    isNordstromPurchaseSortEnabled?.active,
    isCustomerListLastPurchaseWithEmployeeFilterEnabled?.active,
    isCustomerListLastPurchaseWithEmployeeSortEnabled?.active,
    extendedCustomerData,
    isExtendedCustomerFilterOnAndOpenOrSelected,
    isExtendedCustomerSortOnAndOpenOrSelected,
    getExtendedCustomerListData
  ])

  useEffect(() => {
    if (query) {
      setEmptyState(EmptyStateValues.NO_SEARCH_RESULTS)
    }
  }, [query])

  useEffect(() => {
    if (customerBookFiltersCounter > 0) {
      setEmptyState(EmptyStateValues.NO_FILTERS_RESULTS)
    }
  }, [customerBookFiltersCounter])

  const dataToFilterAndSort = useMemo(
    () => ({
      conversations,
      tripsCount: extendedCustomerData?.tripsCount,
      lastPurchaseDate: extendedCustomerData?.lastPurchaseDate,
      lastPurchaseDateWithEmployee:
        extendedCustomerData?.lastPurchaseDateWithEmployee
    }),
    [conversations, extendedCustomerData]
  )

  const featureFlagsForSorting = useMemo(
    () => ({
      isCustomerListTripsSortEnabledActive:
        !!isCustomerListTripsSortEnabled?.active,
      isNordstromPurchaseSortEnabledActive:
        !!isNordstromPurchaseSortEnabled?.active,
      isCustomerListLastPurchaseWithEmployeeSortEnabledActive:
        !!isCustomerListLastPurchaseWithEmployeeSortEnabled?.active
    }),
    [
      isCustomerListTripsSortEnabled,
      isNordstromPurchaseSortEnabled,
      isCustomerListLastPurchaseWithEmployeeSortEnabled
    ]
  )

  const featureFlagsForFilters = useMemo(
    () => ({
      isCustomerListTripsFilterEnabledActive:
        !!isCustomerListTripsFilterEnabled?.active,
      isCustomerListLastPurchaseFilterEnabledActive:
        !!isCustomerListLastPurchaseFilterEnabled?.active,
      isCustomerListLastPurchaseWithEmployeeFilterEnabledActive:
        !!isCustomerListLastPurchaseWithEmployeeFilterEnabled?.active
    }),
    [
      isCustomerListTripsFilterEnabled,
      isCustomerListLastPurchaseFilterEnabled,
      isCustomerListLastPurchaseWithEmployeeFilterEnabled
    ]
  )

  const processCustomers = useCallback(
    (
      initialCustomers: ConsentedCustomerListItemT[]
    ): ConsentedCustomerListItemT[] => {
      let processedCustomers = query
        ? filterByQuerySearch(query, initialCustomers)
        : initialCustomers
      processedCustomers = applyFilters(
        processedCustomers,
        { customerBookFilters, customerBookFiltersCounter },
        dataToFilterAndSort,
        featureFlagsForFilters
      )
      return extendCustomersForSorting(
        processedCustomers,
        sortFunctionKey,
        dataToFilterAndSort,
        featureFlagsForSorting
      )
    },
    [
      query,
      customerBookFilters,
      customerBookFiltersCounter,
      dataToFilterAndSort,
      featureFlagsForFilters,
      sortFunctionKey,
      featureFlagsForSorting
    ]
  )

  useEffect(() => {
    if (!customerListData.customers.length) return

    const processedCustomers = processCustomers(customerListData.customers)
    setCustomers(processedCustomers)
  }, [customerListData.customers, processCustomers])

  useEffect(() => {
    const sortStorageKey = sessionStorage.getItem(SORT_STORAGE_KEY)

    if (sortStorageKey) {
      setSortFunctionKey(sortStorageKey as CustomerSortKeysT)
    }
  }, [sortFunctionKey, setSortFunctionKey])

  const onFiltersOpen = useCallback(() => {
    history.replace({ hash: FILTERS_HASH })
    hideWidget()
  }, [history])

  const onFiltersClose = useCallback(() => {
    history.replace({ hash: undefined })
    showWidget()
  }, [history])

  const handleSortModalClose = () => {
    setIsSortModalOpen(false)
  }

  // Component for empty list from service response
  const NO_CUSTOMERS_FROM_API = () => (
    <>
      {!hasCustomers &&
        emptyState === EmptyStateValues.NO_CUSTOMERS &&
        (!customerListData?.hasArchivedCustomers ? (
          <EmptyCustomerBook />
        ) : (
          <div style={{ paddingTop: '12px' }}>
            <ArchivedCustomerItem />
          </div>
        ))}
    </>
  )

  // Component for empty list when no results are found after filtering
  const NO_CUSTOMERS_FROM_FILTER = () => (
    <>
      {!hasCustomers && emptyState === EmptyStateValues.NO_FILTERS_RESULTS && (
        <>
          <FiltersButton
            filtersSelected={customerBookFiltersCounter}
            onClick={onFiltersOpen}
          />
          <div
            style={{
              paddingLeft: isMobileDevice ? 20 : 0,
              paddingRight: isMobileDevice ? 20 : 0
            }}
          >
            <Typography variant="subtitle2" marginBottom="4px">
              No customers found
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Adjust the filters and try again.
            </Typography>
          </div>
        </>
      )}
    </>
  )

  if (customerListDataIsLoading || followUpsDataIsLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      {isSortModalOpen && (
        <SortModal
          isOpen={isSortModalOpen}
          handleClose={handleSortModalClose}
          setSortFunctionKey={setSortFunctionKey}
          defaultKey={sortFunctionKey}
          isCustomerListTripsSortEnabledActive={
            !!isCustomerListTripsSortEnabled?.active
          }
          extendedCustomerDataIsLoading={extendedCustomerDataIsLoading}
          isNordstromPurchaseSortEnabledActive={
            !!isNordstromPurchaseSortEnabled?.active
          }
          isCustomerListLastPurchaseWithEmployeeSortEnabledActive={
            !!isCustomerListLastPurchaseWithEmployeeSortEnabled?.active
          }
          extendedCustomerDataCopyError={
            extendedCustomerDataIsError
              ? {
                  endpoint: extendedCustomerListDataEndpoint,
                  errorData: extendedCustomerDataError,
                  identifiers: {
                    employeeId
                  }
                }
              : undefined
          }
        />
      )}
      <CustomerHomeHeader
        employeeId={employeeId}
        isCustomerHomeError={customerListDataIsError}
      />
      <CustomerListContainer
        isCommunicationsActive={userCommunicationsData?.isActive}
        isError={customerListDataIsError}
      >
        {customerListDataIsError ? (
          <PageError
            errorTitle={customerListErrorTitle}
            errorDetails={{
              endpoint: customerListDataEndpointName,
              errorData: customerListDataError,
              identifiers: {
                customerId: 'NO-DATA',
                curationId: 'NO-DATA',
                employeeId,
                shoppingSessionId: 'NO-DATA'
              }
            }}
          />
        ) : (
          <>
            <Stack paddingX={isMobileDevice ? '16px' : 0}>
              <SearchBar
                query={query}
                setQuery={setQuery}
                placeholder="Search by name, phone, or email"
                testId="searchCustomer"
              />
            </Stack>
            {customers.length > 0 &&
              !customerListDataWithoutFilters.customers.length && (
                <Box display={'flex'}>
                  <FiltersButton
                    filtersSelected={customerBookFiltersCounter}
                    onClick={onFiltersOpen}
                  />
                  <Box sx={{ paddingLeft: '8px' }}>
                    <SortButton
                      sortSelected={
                        sortFunctionKey !==
                        CUSTOMER_SORT_OPTIONS.BY_ALPHABETICAL
                      }
                      onClick={() => setIsSortModalOpen(true)}
                    />
                  </Box>
                </Box>
              )}

            <FollowUpsSummary
              customersWithFollowUps={customersWithFollowUpsCount}
              onClick={() => goToFollowUpsPage(customersWithFollowUpsCount)}
            />
            <CustomerList
              customers={customers}
              customerCardAction={goToDetails}
              customerListSize={customerListSize}
              listRef={listRef}
              query={query}
              maxHeight={height - 328}
              displayArchivedSection={!query && !customerBookFiltersCounter}
              displayEmptySearchResults={
                !!query &&
                !customers.length &&
                emptyState === EmptyStateValues.NO_SEARCH_RESULTS
              }
              sortFunctionKey={sortFunctionKey}
            />
            <NO_CUSTOMERS_FROM_API />
            <NO_CUSTOMERS_FROM_FILTER />
          </>
        )}
      </CustomerListContainer>
      <FiltersDrawer
        onClose={onFiltersClose}
        customersCount={customerListSize}
        extendedCustomerDataIsLoading={extendedCustomerDataIsLoading}
        extendedCustomerDataCopyError={
          extendedCustomerDataIsError
            ? {
                endpoint: extendedCustomerListDataEndpoint,
                errorData: extendedCustomerDataError,
                identifiers: {
                  employeeId
                }
              }
            : undefined
        }
      />
    </>
  )
}

export default CustomerHome
