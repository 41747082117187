import { ReactElement, useState } from 'react'
import { ConsentedCustomerT } from 'types/ConsentedCustomer'
import { useHistory } from 'react-router-dom'
import { Container, Typography, Divider, Button, Box } from '@mui/material'
import SavedBrands from '../../../SnapshotDrawer/components/SavedBrands'
import ShoppedBrands from '../../../SnapshotDrawer/components/ShoppedBrands'
import SavedSizes from '../../../SnapshotDrawer/components/SavedSizes'
import FashionPillar from '../../../SnapshotDrawer/components/FashionPillar'
import { formatPhoneNumber } from 'utils/formatPhoneNumber'
import { copyToClipboard } from 'utils/copyToClipboard'
import BaseSnackbar from 'components/BaseSnackbar'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import {
  EMAIL_COPIED_TO_CLIPBOARD,
  MEMBERSHIP_COPIED_TO_CLIPBOARD,
  CARDMEMBER_COPIED_TO_CLIPBOARD,
  colors,
  CUSTOMER_MEMBERSHIP_LINK,
  CARDMEMBER_APPLICATION_LINK
} from '../../constants'
import ListItemInfo from '../ListItemInfo'
import { formatCustomerAddress, formatLoyaltyStatus } from '../../utils'
import NordstromNotesInfo from '../NordstromNotesInfo/NordstromNotesInfo'
import AddressesModal from './AddressesModal'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { useAppSelector } from 'app/hooks'
import { useCreateConversationMutation } from 'services/twilioApi'
import { twilioSelectors } from 'services/twilioSlice'
import { getTime } from 'utils/date-utils'
import {
  NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS,
  NR_CUSTOMER_DETAILS_PAGE_CUSTOMER_DETAILS_TAB_CLICKED_TEXT,
  NR_CUSTOMER_DETAILS_PAGE_CUSTOMER_DETAILS_TAB_CLICKED_EMAIL,
  NR_CUSTOMER_DETAILS_PAGE_CUSTOMER_DETAILS_TAB_CLICKED_CREATE_STYLEBOARD
} from 'constants/clienteling/newRelicEvents/nrCustomerDetailsPage'
import ConsentedCustomerTripsListItem from 'components/ConsentedCustomerTripsListItem'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import FollowUpSuggestions from './FollowUpSuggestions'
import { CopyErrorDataT } from 'types/CopyErrorData'

interface PropsT {
  customer: ConsentedCustomerT
  completedFollowUpsCount: number
  endpointName?: string
  setDidConversationCreationFailed: (value: boolean) => void
  setConversationCreationError: (value: string) => void
  setIsFullPageDisabled: (value: boolean) => void
  refetchCustomerData: () => void
  followUpsError?: CopyErrorDataT
}

const SectionDivider = ({ width = '24px' }: { width?: string }) => (
  <Divider
    sx={{ borderBottomWidth: width, borderBottomColor: colors.backgroundGrey }}
  />
)

const ConsentedCustomerInfo = ({
  customer,
  completedFollowUpsCount,
  endpointName,
  setDidConversationCreationFailed,
  setConversationCreationError,
  setIsFullPageDisabled,
  refetchCustomerData,
  followUpsError
}: PropsT): ReactElement => {
  const {
    isConsentedCustomerTripsDetailsAndSnapshotEnabled,
    isFollowUpsFeatureEnabled
  } = useFeatureFlags()

  const [copiedToClipBoard, setCopiedToClipBoard] = useState({
    status: false,
    text: ''
  })
  const [isModalOpen, setIsModalOpen] = useState(false)

  const {
    email,
    mobile,
    customerId,
    optoutEmail,
    loyaltyStatus,
    cardHolder,
    cardInWallet,
    shoppedBrands,
    savedSizes,
    nordstromNotes,
    personalDoublePointsDays,
    alterationBalance,
    totalNoteValue,
    addresses,
    errors,
    additionalCustomerIds
  } = customer

  const { shoppedBrands: isShoppedBrandsError, savedSizes: isSavedSizesError } =
    errors || {}

  const conversation = useAppSelector((state) =>
    twilioSelectors.conversationByCustomerIdSelector(
      state,
      customerId,
      additionalCustomerIds
    )
  )
  const lastContacted = conversation?.mostRecentMessageDate
    ? `Last message: ${getTime(conversation.mostRecentMessageDate, {
        today: '[Today at] h:mma',
        older: 'MMM Do, YYYY'
      })}`
    : `No messages`

  const nrCustomerDetailsEventAttributes = {
    page: NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.CUSTOMER_DETAILS,
    ocpId: customerId
  }

  const history = useHistory()
  const goToCreateStyleBoard = () => {
    const attributes = {
      ocpId: customerId
    }
    generateNewRelicLogs(
      NR_CUSTOMER_DETAILS_PAGE_CUSTOMER_DETAILS_TAB_CLICKED_CREATE_STYLEBOARD,
      nrCustomerDetailsEventAttributes
    )
    generateNewRelicLogs(
      'startBoardForCustomerFromCustomerDetailsClick',
      attributes
    )
    history.push({
      pathname: '/review',
      search: `?createFor=${customer.customerId}`
    })
  }

  const onClickEmail = () => {
    generateNewRelicLogs(
      NR_CUSTOMER_DETAILS_PAGE_CUSTOMER_DETAILS_TAB_CLICKED_EMAIL,
      nrCustomerDetailsEventAttributes
    )
    setCopiedToClipBoard({ status: false, text: '' })
    copyToClipboard(email)
      .then((result) =>
        setCopiedToClipBoard({
          status: result,
          text: EMAIL_COPIED_TO_CLIPBOARD
        })
      )
      .catch((error) => {
        throw error
      })
  }

  const onClickMembershipLink = () => {
    setCopiedToClipBoard({ status: false, text: '' })
    copyToClipboard(CUSTOMER_MEMBERSHIP_LINK)
      .then((result) =>
        setCopiedToClipBoard({
          status: result,
          text: MEMBERSHIP_COPIED_TO_CLIPBOARD
        })
      )
      .catch((error) => {
        throw error
      })
  }

  const onClickCardmemberLink = () => {
    setCopiedToClipBoard({ status: false, text: '' })
    copyToClipboard(CARDMEMBER_APPLICATION_LINK)
      .then((result) =>
        setCopiedToClipBoard({
          status: result,
          text: CARDMEMBER_COPIED_TO_CLIPBOARD
        })
      )
      .catch((error) => {
        throw error
      })
  }

  const onClickTextButton = () => {
    generateNewRelicLogs(
      NR_CUSTOMER_DETAILS_PAGE_CUSTOMER_DETAILS_TAB_CLICKED_TEXT,
      {
        ...nrCustomerDetailsEventAttributes,
        conversationSid: conversation?.sid
      }
    )
    linkMessaging()
  }

  const reformattedMobileNumber = mobile ? `+1${mobile}` : ''

  const [createConversation] = useCreateConversationMutation()

  const linkMessaging = async () => {
    setIsFullPageDisabled(true)
    try {
      if (conversation) {
        history.push(`/messages/${conversation.sid}`)
      } else if (reformattedMobileNumber) {
        const newOrExistingConversationSid = (
          await createConversation({
            customerId
          }).unwrap()
        ).conversationSid
        refetchCustomerData()
        history.push(`/messages/${newOrExistingConversationSid}`)
      }
    } catch (e) {
      setConversationCreationError(e as string)
      setDidConversationCreationFailed(true)
    } finally {
      setIsFullPageDisabled(false)
    }
  }

  const hasMultipleAddresses = addresses && addresses.length > 1
  const defaultShippingAddress = addresses?.find((address) =>
    address.addressTypes.includes('DEFAULTSHIPPING')
  )

  const standardShippingAddresses = addresses?.filter(
    (address) => !address.addressTypes.includes('DEFAULTSHIPPING')
  )

  return (
    <>
      <AddressesModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        defaultShippingAddress={defaultShippingAddress}
        standardShippingAddresses={standardShippingAddresses}
      />
      <SectionDivider width="16px" />
      {!!isFollowUpsFeatureEnabled?.active && (
        <FollowUpSuggestions
          followUps={customer.followUps ?? []}
          customerId={customerId}
          completedFollowUpsCount={completedFollowUpsCount}
          error={followUpsError}
        />
      )}
      <SectionDivider />
      <ListItemInfo
        title="Mobile"
        text={mobile ? formatPhoneNumber(mobile) : 'No saved number'}
        secondaryText={lastContacted}
        styleOverride={{
          secondaryColor: mobile ? colors.primary : colors.subtitleGrey
        }}
      >
        {mobile ? (
          <Box sx={{ ml: '15px' }}>
            <Button variant="outlined" onClick={onClickTextButton}>
              Text
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </ListItemInfo>
      <ListItemInfo
        title="Email"
        text={email ? email : 'No saved email'}
        styleOverride={{
          secondaryColor: email ? colors.primary : colors.subtitleGrey
        }}
      >
        {email ? (
          <Box sx={{ ml: '15px' }}>
            {!optoutEmail ? (
              <Button variant="outlined" onClick={() => onClickEmail()}>
                Email
              </Button>
            ) : (
              <Typography
                variant="subtitle2"
                textAlign="center"
                minWidth="90px"
                color="#6B777B"
              >
                Do not email
              </Typography>
            )}
          </Box>
        ) : (
          <></>
        )}
      </ListItemInfo>
      <ListItemInfo
        title="Primary shipping address"
        text={
          defaultShippingAddress
            ? formatCustomerAddress(defaultShippingAddress)
            : addresses && addresses.length
            ? 'No primary address set'
            : 'No saved address'
        }
        styleOverride={{
          secondaryColor: defaultShippingAddress
            ? colors.primary
            : colors.subtitleGrey
        }}
      >
        {hasMultipleAddresses ||
        (addresses && addresses.length && !defaultShippingAddress) ? (
          <ChevronRightIcon
            data-testid="address-modal-open-icon"
            sx={{
              color: colors.primary,
              ':hover': {
                cursor: 'pointer'
              }
            }}
            onClick={() => setIsModalOpen(true)}
          />
        ) : (
          <></>
        )}
      </ListItemInfo>
      <Container sx={{ mt: 2, mb: '16px', textAlign: 'center' }}>
        <Button
          variant="outlined"
          fullWidth={true}
          sx={{ maxWidth: '343px', alignContent: 'center' }}
          onClick={goToCreateStyleBoard}
        >
          Create Style Board
        </Button>
        {copiedToClipBoard.status && (
          <BaseSnackbar message={copiedToClipBoard.text} />
        )}
      </Container>
      <SectionDivider />
      {!!isConsentedCustomerTripsDetailsAndSnapshotEnabled?.active && (
        <Box sx={{ px: '16px' }}>
          <ConsentedCustomerTripsListItem customerId={customerId} />
          <SectionDivider />
        </Box>
      )}
      <ListItemInfo
        title="Nordy Club status"
        text={
          loyaltyStatus ? formatLoyaltyStatus(loyaltyStatus) : 'Not a member'
        }
        styleOverride={{
          secondaryColor: loyaltyStatus ? colors.primary : colors.subtitleGrey
        }}
      >
        {!loyaltyStatus ? (
          <Button variant="text" onClick={onClickMembershipLink}>
            Copy Membership Link
          </Button>
        ) : (
          <></>
        )}
      </ListItemInfo>
      <ListItemInfo
        title="Cardmember"
        text={cardHolder || cardInWallet ? 'Yes' : 'No'}
        styleOverride={{
          secondaryColor:
            cardHolder || cardInWallet ? colors.primary : colors.subtitleGrey
        }}
      >
        {!(cardHolder || cardInWallet) ? (
          <Button variant="text" onClick={onClickCardmemberLink}>
            Copy Application Link
          </Button>
        ) : (
          <></>
        )}
      </ListItemInfo>
      <SectionDivider />
      <NordstromNotesInfo
        loyaltyStatus={loyaltyStatus}
        nordstromNotes={nordstromNotes}
        totalNoteValue={totalNoteValue}
        alterationBalance={alterationBalance}
        personalDoublePointsDays={personalDoublePointsDays}
        nordstromCardHolder={cardHolder || cardInWallet}
      />
      <SectionDivider />
      <Box sx={{ px: '16px', pb: '12px' }}>
        <SavedBrands customerId={customerId} />
      </Box>
      <Divider />
      <Box sx={{ px: '16px', pb: '12px' }}>
        <ShoppedBrands
          brands={shoppedBrands}
          customerId={customerId}
          isError={isShoppedBrandsError}
          endpointName={endpointName}
        />
      </Box>
      <SectionDivider />
      <Box sx={{ px: '16px', pb: '12px' }}>
        <SavedSizes
          savedSizes={savedSizes}
          customerId={customerId}
          isError={isSavedSizesError}
          endpointName={endpointName}
        />
      </Box>
      <SectionDivider />
      <Box sx={{ px: '16px', pb: '12px' }}>
        <FashionPillar customerId={customerId} />
      </Box>
    </>
  )
}

export default ConsentedCustomerInfo
