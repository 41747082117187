import { PriceTypes, SkuDetailsT } from 'types/Product'
import { formatSkuPrices } from '../helpers'
import Price from '../Price'
import { Box } from '@mui/material'

const SkuPrice = ({
  selectedSku
}: {
  selectedSku: SkuDetailsT
}): JSX.Element => {
  const formattedSkuPrice = formatSkuPrices(selectedSku.price)
  const { currentPriceType } = formattedSkuPrice
  const isSalePrice =
    currentPriceType === PriceTypes.PROMOTION ||
    currentPriceType === PriceTypes.CLEARANCE

  if (!selectedSku.isOnlinePurchasable) {
    return (
      <Box my={1}>
        <Price price={'Sold Out'} isSale={false} isStrikeThrough={false} />
      </Box>
    )
  }

  if (isSalePrice) {
    return (
      <Box my={1}>
        <Price
          price={formattedSkuPrice.promotion || formattedSkuPrice.clearance}
          isSale={true}
          isStrikeThrough={false}
        />
        <Price
          price={formattedSkuPrice.regular}
          isSale={false}
          isStrikeThrough={true}
        />
      </Box>
    )
  }

  return (
    <Box my={1}>
      <Price
        price={formattedSkuPrice.regular}
        isSale={false}
        isStrikeThrough={false}
      />
    </Box>
  )
}

export default SkuPrice
