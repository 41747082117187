import { ReactElement, useState } from 'react'
import { Box, Container } from '@mui/material'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import DividerComponent from 'pages/CurationHome/components/DividerComponent'
import { isUSSeller, isStyleAdvisor } from 'utils/userPermissions'
import Drafts from './components/Drafts'
import oktaTokenStorage from 'utils/okta-token-utils'
import { useGetEmployeeQuery } from 'services/clientelingApi'
import WelcomeHome from './components/Welcome'
import SalesAndInsights from './components/SalesAndInsights'
import LearnMore from './components/LearnMore'
import QueueSection from './components/QueueSection'
import AlertMessage from './components/AlertMessage'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import Customers from './components/Customers'

const Home = (): ReactElement => {
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  const showInviteButton = !isStyleAdvisor()
  const {
    data: employeeData,
    isLoading,
    isFetching
  } = useGetEmployeeQuery({ employeeId })

  const { isPBSurveyLinkEnabled, isHomePhase2Enabled } = useFeatureFlags()

  const surveyAlertDismissed = JSON.parse(
    localStorage.getItem('survey-alert-dismissed') || 'false'
  )
  const [showSurveyAlert, setShowSurveyAlert] = useState(!surveyAlertDismissed)

  const onClose = () => {
    localStorage.setItem('survey-alert-dismissed', 'true')
    setShowSurveyAlert(false)
    const attributes = {
      page: 'homepage'
    }
    generateNewRelicLogs('surveyAlertCloseClick', attributes)
  }

  return (
    <BoxWithGladlySpace>
      <Container disableGutters>
        <Box>
          <WelcomeHome
            employee={employeeData}
            isLoading={isLoading}
            isFetching={isFetching}
            showInviteButton={showInviteButton}
          />
          {showSurveyAlert && isPBSurveyLinkEnabled?.active && (
            <AlertMessage onClose={onClose} />
          )}
          {isHomePhase2Enabled?.active && (
            <>
              <DividerComponent marginSize={2} />
              <SalesAndInsights />
            </>
          )}
          {!isStyleAdvisor() && isHomePhase2Enabled?.active && (
            <>
              <DividerComponent marginSize={2} />
              <Customers />
            </>
          )}
          {!isUSSeller() && (
            <>
              <DividerComponent marginSize={2} />
              <QueueSection />
            </>
          )}
          <DividerComponent marginSize={2} />
          <Drafts />
          <DividerComponent marginSize={2} />
          <LearnMore />
        </Box>
      </Container>
    </BoxWithGladlySpace>
  )
}

export default Home
