import { ReactElement } from 'react'
import { Typography } from '@mui/material'
import { SalesMetricT } from 'types/SalesApi'
import { formatPrice } from 'utils/formatPrice'
import moment from 'moment'

type PropsT = {
  salesData: SalesMetricT
}

const AllSales = (props: PropsT): ReactElement => {
  const { salesData } = props

  return (
    <>
      <Typography variant="subtitle2">
        {moment(salesData.business_date).format('MMM Do')} - Net sales
      </Typography>
      <Typography sx={{ pt: 1 }} variant="h5">
        {formatPrice.format(salesData.net_amt_ty)}
      </Typography>
      <Typography sx={{ pt: 3 }} variant="subtitle2">
        Pay period
      </Typography>
      <Typography sx={{ py: 1 }} variant="h5">
        {formatPrice.format(salesData.net_amt_pp2d)}
      </Typography>
      <Typography color="secondary" variant="body2">
        Last year: {formatPrice.format(salesData.net_amt_pp2d_ly)}
      </Typography>
      <Typography sx={{ pt: 3 }} variant="subtitle2">
        Year to date
      </Typography>
      <Typography sx={{ py: 1 }} variant="h5">
        {formatPrice.format(salesData.net_amt_y2d)}
      </Typography>
      <Typography color="secondary" variant="body2">
        Last year: {formatPrice.format(salesData.net_amt_y2d_ly)}
      </Typography>
    </>
  )
}

export default AllSales
