import {
  ListItemText,
  Box,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Button
} from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { SingleCustomerData } from 'types/FollowUps'
import { FOLLOW_UP_TEXT } from 'constants/clienteling/followUpsText'
import { useMemo } from 'react'

type PropsT = {
  customerData: SingleCustomerData
  onFollowUpClick: () => void
  onCheckFollowUp: () => void
}

const MultipleFollowUps = ({
  customerData,
  onFollowUpClick,
  onCheckFollowUp
}: PropsT): JSX.Element => {
  const customerFollowUpsWithIds = useMemo(() => {
    return customerData.followUps.map((followUp) => ({
      followUp,
      id: crypto.randomUUID()
    }))
  }, [customerData])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <CardHeader
        title={
          <>
            <strong>{`${customerData.firstName} ${customerData.lastName}`}</strong>{' '}
            has multiple follow-up suggestions
          </>
        }
        titleTypographyProps={{ variant: 'body2' }}
        action={
          <IconButton onClick={onFollowUpClick}>
            <ChevronRightIcon />
          </IconButton>
        }
      />
      <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
        {customerFollowUpsWithIds.map(({ followUp, id }, index) => (
          <Box
            key={id}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              backgroundColor: '#F2F5F6',
              height: '58px',
              marginBottom:
                index !== customerData.followUps.length - 1 ? '12px' : 0,
              alignItems: 'center'
            }}
          >
            <IconButton
              sx={{ marginLeft: '16px', marginRight: '14px' }}
              onClick={onCheckFollowUp}
            >
              <CheckBoxOutlineBlankIcon />
            </IconButton>
            <ListItemText
              key={followUp.followUpKind}
              primary={FOLLOW_UP_TEXT[followUp.followUpKind]}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </Box>
        ))}
      </CardContent>
      <CardActions>
        <Button variant="text" sx={{ paddingTop: 0 }}>
          Mark All Complete
        </Button>
      </CardActions>
    </Box>
  )
}

export default MultipleFollowUps
