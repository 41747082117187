import { ReactElement, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material'
import AppointmentDetailsItem from '../../../components/AppointmentDetailsItem'
import { useGetCustomerByIdentifierMutation } from 'services/clientelingApi'
import {
  CONSENT_SEARCH_BY,
  RELATIONSHIP_STATUS
} from 'constants/clienteling/consentConstants'
import { formatPhoneNumber } from 'utils/formatPhoneNumber'
import PageError from 'components/PageError'

type PropsT = {
  customerName: string
  customerPhone: string
  customerEmail: string
}

const CUSTOMER_STATUS: Record<string, string> = {
  MOBILE_FOUND: 'Not in customer book',
  ALREADY_IN_BOOK: 'In customer book',
  INVITATION_PENDING: 'Invited to customer book',
  DECLINED: 'Not in customer book',
  EMAIL_FOUND_NO_MOBILE: 'Not in customer book'
}

const getCustomerStatus = ({
  relationshipStatus = '',
  mobile = '',
  invitationStatus = ''
}): string => {
  if (relationshipStatus === RELATIONSHIP_STATUS.VERIFIED) {
    return CUSTOMER_STATUS.ALREADY_IN_BOOK
  } else if (relationshipStatus === RELATIONSHIP_STATUS.DENIED) {
    return CUSTOMER_STATUS.DECLINED
  } else if (invitationStatus === RELATIONSHIP_STATUS.PENDING) {
    return CUSTOMER_STATUS.INVITATION_PENDING
  } else if (!mobile) {
    return CUSTOMER_STATUS.EMAIL_FOUND_NO_MOBILE
  } else {
    return CUSTOMER_STATUS.MOBILE_FOUND
  }
}

const CustomerInfo = ({
  customerName,
  customerPhone,
  customerEmail
}: PropsT): ReactElement => {
  const history = useHistory()
  const [
    getCustomerByIdentifier,
    {
      data: customerData,
      isLoading: isGetCustomerByIdentifierLoading,
      isError: isGetCustomerByIdentifierError,
      error: customerByIdentifierError,
      endpointName: getCustomerByIdentifierEndpointName
    }
  ] = useGetCustomerByIdentifierMutation()

  useEffect(() => {
    getCustomerByIdentifier({
      identifier: customerEmail,
      searchBy: CONSENT_SEARCH_BY.EMAIL
    })
  }, [customerEmail, getCustomerByIdentifier])

  const isNot404Error =
    isGetCustomerByIdentifierError &&
    'status' in customerByIdentifierError &&
    customerByIdentifierError?.status !== 404

  const statusText = isGetCustomerByIdentifierLoading
    ? 'Getting status...'
    : isNot404Error
    ? 'Error fetching customer status'
    : customerData?.relationshipStatus || customerData?.invitationStatus
    ? getCustomerStatus({
        relationshipStatus: customerData?.relationshipStatus,
        mobile: customerData.mobile,
        invitationStatus: customerData?.invitationStatus
      })
    : 'Not in customer book'

  const doesNotHaveRelationship =
    customerData?.relationshipStatus !== RELATIONSHIP_STATUS.VERIFIED &&
    customerData?.invitationStatus !== RELATIONSHIP_STATUS.PENDING

  return (
    <List
      sx={{
        paddingBottom: '16px',
        bgcolor: 'white'
      }}
    >
      <ListItem>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            mb: 2
          }}
        >
          <Box>
            <ListItemText
              primary={
                <>
                  <Typography
                    component="span"
                    variant="subtitle2"
                    sx={{ color: 'text.primary' }}
                  >
                    Customer
                  </Typography>
                </>
              }
            />
            <Typography variant="body2">{customerName}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {statusText}
            </Typography>
          </Box>
          <Box>
            {!isGetCustomerByIdentifierLoading && doesNotHaveRelationship && (
              <Button
                variant="outlined"
                onClick={() => history.push('/customers/invite')}
              >
                Invite Customer
              </Button>
            )}
          </Box>
        </Box>
      </ListItem>
      {isNot404Error && (
        <Box sx={{ mt: 0, mb: 2 }}>
          <PageError
            errorTitle="Error retrieving customer details - "
            errorDetails={{
              errorData: customerByIdentifierError,
              endpoint: getCustomerByIdentifierEndpointName
            }}
          />
        </Box>
      )}
      <Divider />

      {customerPhone && (
        <AppointmentDetailsItem title="Mobile">
          {formatPhoneNumber(customerPhone)}
        </AppointmentDetailsItem>
      )}
      {customerEmail && (
        <AppointmentDetailsItem title="Email">
          {customerEmail}
        </AppointmentDetailsItem>
      )}
    </List>
  )
}

export default CustomerInfo
