import { ReactElement, useState } from 'react'
import {
  Container,
  IconButton,
  Link,
  Typography,
  Tabs,
  Tab,
  Box
} from '@mui/material'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import AttributionsFeed from './components/AttributionsFeed'
import Sales from './components/Sales'
import NotFound from 'pages/NotFound'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import BaseInformationDialog from 'components/BaseInformationDialog'
import useReportingLinks from 'hooks/useReportingLinks'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

export const InformationDialogText = ({
  payrollCalendarUrl
}: {
  payrollCalendarUrl?: string
}): ReactElement => (
  <>
    The information shown in this tool is unaudited and reflects raw
    transactional data — it is not the final sales on which commissions are
    calculated. Final pay period numbers are available on your Payslip and
    Commission Statement following the end of the pay period.{' '}
    {payrollCalendarUrl && (
      <Link color="secondary.main" href={payrollCalendarUrl} target="_blank">
        Payroll calendar
      </Link>
    )}
  </>
)

const ReportingHome = (): JSX.Element => {
  const history = useHistory()
  const location = useLocation()

  const { payrollCalendarUrl } = useReportingLinks()

  const handleTabChange = (_event: React.SyntheticEvent, newPath: string) => {
    if (newPath === '/reporting/insights') {
      generateNewRelicLogs('reportingInsightsClick')
    } else {
      generateNewRelicLogs('reportingSalesClick')
    }
    history.push(newPath)
  }

  const [open, setOpen] = useState(false)

  const onClickReportingInfo = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Container disableGutters>
      <Box px={2}>
        <Typography
          variant="h6"
          mb={'2'}
          display="flex"
          alignItems="center"
          component="h1"
        >
          Reporting
          <IconButton
            aria-label="info"
            color="info"
            size="small"
            onClick={onClickReportingInfo}
          >
            <InfoOutlinedIcon
              sx={{
                width: '20px',
                height: '20px'
              }}
            />
          </IconButton>
        </Typography>
        <BaseInformationDialog
          cancel={handleClose}
          contextText={
            <InformationDialogText payrollCalendarUrl={payrollCalendarUrl} />
          }
          onClose={handleClose}
          open={open}
          title={'Reporting Information'}
        />
        <Tabs value={location.pathname} onChange={handleTabChange}>
          <Tab value="/reporting" label="Sales" />
          <Tab value="/reporting/insights" label="Insights" />
        </Tabs>
      </Box>
      <Switch>
        <Route exact path="/reporting" component={Sales} />
        <Route exact path="/reporting/insights" component={AttributionsFeed} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Container>
  )
}

export default ReportingHome
