import { ReactElement } from 'react'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import Drawer from 'components/Drawer'
import RecentItems from './components/RecentItems'
import RecentItemsNavigation from './components/RecentItemsNavigation'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'

export type PropsT = {
  handleClickForMiniPDP: ({
    webStyleId,
    miniPDPOpenedFrom,
    rmsSku
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    rmsSku: string
  }) => void
  onClose: () => void
  open: boolean
}

const RecentItemsDrawer = (props: PropsT): ReactElement | null => {
  const { handleClickForMiniPDP, onClose, open } = props

  return (
    <Drawer onClose={onClose} open={open}>
      <BoxWithGladlySpace sx={{ px: 2 }}>
        <RecentItemsNavigation title="Recent Items" onClick={onClose} />
        <RecentItems handleClickForMiniPDP={handleClickForMiniPDP} />
      </BoxWithGladlySpace>
    </Drawer>
  )
}

export default RecentItemsDrawer
