import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  AppointmentT,
  GetAppointmentLinkResponseT,
  GetAppointmentParamsT,
  PersonalizedAppointmentLinkParamsT,
  AppointmentListAppointmentT
} from 'types/Appointments'
import setStandardHeaders from '../utils/set-standard-headers'
import oktaTokenStorage from '../utils/okta-token-utils'

const TEST_STORE = 'test-store-appointments'

export const getStore: (store: string) => string | null = (store) => {
  // Looks for a test store in local storage
  const testingStore = window.localStorage.getItem(TEST_STORE)
  return testingStore || store
}

export const appointmentsService = createApi({
  reducerPath: 'appointmentsService',
  tagTypes: ['BookingLink', 'AppointmentsList', 'AppointmentDetails'],
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      headers.set('X-Nord-Gateway', 'UTG')

      setStandardHeaders({ headers: headers })

      const accessToken = oktaTokenStorage.getOktaAccessToken()
      const idToken = oktaTokenStorage.getOktaIdToken()

      if (accessToken) {
        headers.set('Nord-Okta-Id-Token', idToken)
        headers.set('Authorization', `Bearer ${accessToken}`)
      }
      return headers
    },
    baseUrl: process.env.REACT_APP_APPOINTMENTS_SVC
  }),
  endpoints: (builder) => ({
    getPersonalizedLink: builder.query<
      GetAppointmentLinkResponseT,
      PersonalizedAppointmentLinkParamsT
    >({
      query: ({ store, email }) => {
        const appointmentsStore = getStore(store)
        return {
          url: `/booking/staff/email/${email}/store/${appointmentsStore}/link`,
          method: 'GET'
        }
      },
      providesTags: ['BookingLink']
    }),
    getAppointmentsList: builder.query<
      { appointments: AppointmentListAppointmentT[] },
      { store: string; email: string }
    >({
      query: ({ store, email }) => {
        const appointmentsStore = getStore(store)
        return {
          url: `/employees/${email}/appointments?storeNumber=${appointmentsStore}`,
          method: 'GET'
        }
      },
      providesTags: ['AppointmentsList']
    }),
    getAppointment: builder.query<AppointmentT, GetAppointmentParamsT>({
      query: ({ appointmentId }) => {
        return {
          url: `/employees/appointments/${appointmentId}`,
          method: 'GET'
        }
      },
      providesTags: ['AppointmentDetails']
    })
  })
})

export const {
  useGetPersonalizedLinkQuery,
  useGetAppointmentsListQuery,
  useGetAppointmentQuery
} = appointmentsService
