import { ReactElement, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useGetItemByUPCMutation } from 'services/employeeExperienceApi'
import { emDashCharacter } from 'pages/constants'
import BasePageAlert from 'components/BasePageAlert'
import Drawer from 'components/Drawer'
import DrawerNavigation from 'components/DrawerNavigation'
import BarcodeScanner from 'components/BarcodeScanner'
import ScanditScanner from 'components/BarcodeScanner/Scandit/ScanditScanner'
import { useSDK } from 'components/BarcodeScanner/Scandit/ScanditSDK'
import DrawerSearchBar from 'components/SearchBar'
import LoadingBackdrop from 'components/LoadingBackdrop'
import CameraNotAvailable from './components/CameraNotAvailable'
import OfferNotFoundDialog from './components/OfferNotFoundDialog'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { useIsScanningWithScanditEnabledForUser } from 'utils/useScanningFeatures'
import { validateNumberOnlyInput } from './helpers/validateNumberOnlyInput'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'

enum OfferError {
  NOT_FOUND = 'not-found',
  OTHER = 'other'
}

enum AddItemError {
  UNAVAILABLE = 'unavailable',
  OTHER = 'other'
}

export type PropsT = {
  onClose: () => void
  open: boolean
  handleOpenMiniPDP: ({
    webStyleId,
    miniPDPOpenedFrom,
    rmsSku
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    rmsSku: string
  }) => void
  shoppingSessionId?: string
}

const ScannerDrawer = (props: PropsT): ReactElement | null => {
  const { handleOpenMiniPDP, onClose, open: isDrawerOpen } = props

  const [addedItemBrandAndName, setAddedItemBrandAndName] = useState('')
  const [addItemError, setAddItemError] = useState<AddItemError>()
  const [inputError, setInputError] = useState(false)
  const [isCameraAllowed, setIsCameraAllowed] = useState<boolean>(true)
  const [offerError, setOfferError] = useState<OfferError>()
  const [query, setQuery] = useState('')
  const isMobileDevice = !!checkBrowserClient.isMobile()

  const scanditSDK = useSDK()

  const shouldUseScanditScanner = useIsScanningWithScanditEnabledForUser()

  const [
    getItemDetailsByUPC,
    {
      data: upcData,
      endpointName: upcEndpoint,
      error: upcError,
      isLoading: isUpcLoading,
      reset: resetUPCData
    }
  ] = useGetItemByUPCMutation()

  const shouldShowScanner =
    isMobileDevice && !upcData && !offerError && isDrawerOpen && isCameraAllowed

  const handleGetByUPC = (upc: string) => {
    if (offerError) {
      setOfferError(undefined)
    }

    if (!validateNumberOnlyInput(upc)) {
      setInputError(true)
      return
    }

    const trimmedUpc = upc.trim()
    getItemDetailsByUPC(trimmedUpc)
      .unwrap()
      .then((fulfilled) => {
        fulfilled.webStyleId &&
          handleOpenMiniPDP({
            webStyleId: fulfilled.webStyleId,
            miniPDPOpenedFrom: 'ScannerDrawer',
            rmsSku: fulfilled.rmsSku
          })
        return
      })
      .catch((error) => {
        let offerError = OfferError.OTHER
        if ('status' in error) {
          const errorMessage = JSON.stringify(error.data)
          if (
            errorMessage?.includes('Offer not found') ||
            errorMessage?.includes('Invalid UPC')
          ) {
            offerError = OfferError.NOT_FOUND
          }
        }
        setOfferError(offerError)
      })
  }

  const handleInputChange = (value: string) => {
    if (inputError) setInputError(false)

    setQuery(value)
  }

  const handleNotAllowedOrPermissionsError = () => {
    setIsCameraAllowed(false)
  }

  const onDrawerClose = () => {
    resetSearch()
    if (addedItemBrandAndName) setAddedItemBrandAndName('')
    if (addItemError) setAddItemError(undefined)
    resetUPCData()
    onClose()
  }

  const resetSearch = () => {
    if (inputError) setInputError(false)
    if (query) setQuery('')
    if (offerError) setOfferError(undefined)
  }

  return (
    <Drawer onClose={onDrawerClose} open={isDrawerOpen}>
      <LoadingBackdrop open={isUpcLoading} />
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box sx={{ px: 2 }}>
          <DrawerNavigation
            title="Scan items"
            headerEndButton={null}
            onClick={onDrawerClose}
            showBackButton={false}
          />
          <Box mb={2}>
            <Typography ml={0.5} variant="caption" align="center">
              Scan barcode or enter UPC manually.
            </Typography>
          </Box>
          <DrawerSearchBar
            handleInputChange={handleInputChange}
            isError={inputError}
            isNumericOnlyTextField={true}
            numericInputHelperText={'UPC should be numbers only'}
            onClick={() => handleGetByUPC(query)}
            placeholder={'UPC'}
            query={query}
            resetSearch={resetSearch}
          />
        </Box>
        {offerError === OfferError.OTHER && (
          <Box sx={{ px: 2 }}>
            <BasePageAlert
              alertTitle={`Issue scanning item ${emDashCharacter} try again`}
              errorDetails={{ endpoint: upcEndpoint, errorData: upcError }}
            />
          </Box>
        )}

        <OfferNotFoundDialog
          open={offerError === OfferError.NOT_FOUND}
          resetOfferError={() => setOfferError(undefined)}
        />
        {shouldShowScanner &&
          (shouldUseScanditScanner ? (
            <ScanditScanner getByUPC={handleGetByUPC} scanditSDK={scanditSDK} />
          ) : (
            <BarcodeScanner
              handleNotAllowedOrPermissionsError={
                handleNotAllowedOrPermissionsError
              }
              onScanSuccess={handleGetByUPC}
            />
          ))}
        {(!isMobileDevice || isCameraAllowed === false) && (
          <CameraNotAvailable
            isCameraPermissionDenied={isCameraAllowed === false}
          />
        )}
      </Box>
    </Drawer>
  )
}

export default ScannerDrawer
