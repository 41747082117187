import { NR_CLIENTELING_PAGES } from '../nrClientelingPages'
import { Type as NOTIFICATION_TYPE } from 'types/Notification'

const { NOTIFICATIONS_PAGE } = NR_CLIENTELING_PAGES
const { NEW_CUSTOMER, CUSTOMER_RENEWED, CUSTOMER_ENDED, APPOINTMENT_CREATED } =
  NOTIFICATION_TYPE

export const NR_NOTIFICATIONS_PAGE_CLICKED_NEW_CUSTOMER = `${NOTIFICATIONS_PAGE}-clicked_${NEW_CUSTOMER}`
export const NR_NOTIFICATIONS_PAGE_CLICKED_RENEWED_CUSTOMER = `${NOTIFICATIONS_PAGE}-clicked_${CUSTOMER_RENEWED}`
export const NR_NOTIFICATIONS_PAGE_CLICKED_ENDED_CUSTOMER = `${NOTIFICATIONS_PAGE}-clicked_${CUSTOMER_ENDED}`
export const NR_NOTIFICATIONS_PAGE_CLICKED_APPOINTMENT_CREATED = `${NOTIFICATIONS_PAGE}-clicked_${APPOINTMENT_CREATED}`
