import {
  NR_CUSTOMER_DETAILS_PAGE_CLICKED_CUSTOMER_DETAILS_TAB,
  NR_CUSTOMER_DETAILS_PAGE_CLICKED_PURCHASES_TAB,
  NR_CUSTOMER_DETAILS_PAGE_CLICKED_WISH_LISTS_TAB,
  NR_CUSTOMER_DETAILS_PAGE_CLICKED_STYLEBOARDS_TAB
} from 'constants/clienteling/newRelicEvents/nrCustomerDetailsPage'

export const colors = {
  backgroundGrey: '#F5F5F5',
  notesBorder: '#1f21223b',
  primaryLight: '#8484FB',
  infoIcon: '#006BE2',
  subtitleGrey: '#6F7780',
  primary: '#1F2122',
  borderGrey: '#1f21221f'
}

export const EMPTY_NOTES_MESSAGES = {
  NO_NOTES: 'No Notes available',
  NO_PDPD: 'No days available'
}

const BASIC_ALTERATIONS_BENEFIT = 'Free basic alterations'

export const ALTERATIONS_BENEFITS = {
  NONE: BASIC_ALTERATIONS_BENEFIT,
  MEMBER: BASIC_ALTERATIONS_BENEFIT,
  NORDY_WITHOUT_CARD: BASIC_ALTERATIONS_BENEFIT,
  REGULAR: (alterationBalance: number): string =>
    `${BASIC_ALTERATIONS_BENEFIT}\n$${alterationBalance} available for Notes reimbursement`,
  ICON: 'Unlimited free alterations'
}

export const EMAIL_COPIED_TO_CLIPBOARD =
  'Email address copied. Paste in Outlook to send your message.'
export const MEMBERSHIP_COPIED_TO_CLIPBOARD = 'Nordy Club link copied'
export const CARDMEMBER_COPIED_TO_CLIPBOARD =
  'Cardmember application link copied'

export const CUSTOMER_MEMBERSHIP_LINK =
  'https://www.nordstrom.com/my-account/rewards'
export const CARDMEMBER_APPLICATION_LINK =
  'https://www.nordstrom.com/becomecardmember'

export const SHOPPED_BRAND_ERROR_TITLE = 'Issue getting shopped brands.'
export const SAVED_SIZE_ERROR_TITLE = 'Issue getting saved sizes.'
export const CONVERSATION_FAILED_MESSAGE = 'Error creating conversation.'
export const NO_SAVED_NUMBER_ERROR = 'Customer has no saved number'

export const MAP_TAB_POSITION_TO_NR_EVENT = [
  {
    LABEL: 'Details',
    NR_EVENT: NR_CUSTOMER_DETAILS_PAGE_CLICKED_CUSTOMER_DETAILS_TAB
  },
  {
    LABEL: 'Purchases',
    NR_EVENT: NR_CUSTOMER_DETAILS_PAGE_CLICKED_PURCHASES_TAB
  },
  {
    LABEL: 'Wish Lists',
    NR_EVENT: NR_CUSTOMER_DETAILS_PAGE_CLICKED_WISH_LISTS_TAB
  },
  {
    LABEL: 'Style boards',
    NR_EVENT: NR_CUSTOMER_DETAILS_PAGE_CLICKED_STYLEBOARDS_TAB
  }
]
