import { Box, Button } from '@mui/material'

type PropsT = {
  isListExpanded: boolean
  toggleIsListExpanded: () => void
}

const ShowAllFollowUpsButton = ({
  isListExpanded,
  toggleIsListExpanded
}: PropsT): JSX.Element => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        mb: '12px'
      }}
    >
      <Button variant="text" onClick={toggleIsListExpanded}>
        {isListExpanded ? 'Show Less' : 'Show More'}
      </Button>
    </Box>
  )
}

export default ShowAllFollowUpsButton
