import { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'
import CurationReviewContainer from './components/CurationReviewContainer'
import ResumeCurationWrapper from './components/ResumeCurationWrapper'
import CreateCurationWrapper from './components/CreateCurationWrapper'
import StartCurationWrapper from './components/StartCurationWrapper'
import StartPreferredRequestWrapper from './components/StartPreferredRequestWrapper'
import PublicBoardWrapper from './components/PublicBoardWrapper'
import GeneralRequestWrapper from './components/GeneralRequestWrapper'

const CurationReviewCheckURLParams = (): ReactElement => {
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const ocpId = searchParams.get('createFor')
  const preferredRequestId = searchParams.get('preferredRequestId')
  const publicBoardParam = searchParams.get('publicBoard')
  const resumeCurationId = searchParams.get('resumeCuration')
  const startCurationParam = searchParams.get('startCuration')
  const generalRequestParam = searchParams.get('generalRequest')
  const occasionGroup = searchParams.get('occasionGroup')

  if (ocpId) {
    return <CreateCurationWrapper ocpId={ocpId} />
  }

  if (preferredRequestId) {
    return (
      <StartPreferredRequestWrapper preferredRequestId={preferredRequestId} />
    )
  }

  if (publicBoardParam) {
    return <PublicBoardWrapper />
  }

  if (resumeCurationId) {
    return <ResumeCurationWrapper resumeCurationId={resumeCurationId} />
  }

  if (startCurationParam) {
    return <StartCurationWrapper occasionGroup={occasionGroup} />
  }

  if (generalRequestParam) {
    return <GeneralRequestWrapper />
  }

  return <CurationReviewContainer />
}

export default CurationReviewCheckURLParams
