import { Box, Container, Typography } from '@mui/material'
import { checkBrowserClient } from 'utils/userAgentDetector'
import CustomerBookButton from '../CustomerBookButton/CustomerBookButton'
import { ReactElement } from 'react'

type AppointmentsHomeHeaderProps = { isEmptyList: boolean }

const AppointmentsHomeHeader = ({
  isEmptyList
}: AppointmentsHomeHeaderProps): ReactElement => {
  const isMobileDevice = checkBrowserClient.isMobile()
  const isCustomerBookButtonVisible = isEmptyList && !isMobileDevice

  return (
    <Container
      sx={{
        height: '100%',
        paddingRight: isMobileDevice && 0,
        paddingLeft: isMobileDevice && 0,
        mt: '20px'
      }}
    >
      <Box
        sx={{
          mt: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: '20px'
        }}
      >
        <Typography variant="h6" sx={{ marginLeft: isMobileDevice && '16px' }}>
          Appointments
        </Typography>
        {isCustomerBookButtonVisible && <CustomerBookButton />}
      </Box>
    </Container>
  )
}

export default AppointmentsHomeHeader
