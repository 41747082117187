import { ReactElement, useEffect, useMemo, useState } from 'react'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { useNavigation } from 'contexts/NavigationContext'
import {
  useTheme,
  Box,
  Stack,
  Button,
  Alert,
  AlertTitle,
  IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import {
  useGetCompletedFollowUpsForCustomerQuery,
  useGetConsentedCustomerQuery,
  useUpdateCustomerArchiveStatusMutation
} from 'services/clientelingApi'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import useWindowDimensions from 'utils/useWindowDimensions'
import WishList from 'components/WishList'
import RecentPurchasesList from '../SnapshotDrawer/pages/RecentPurchasesList'
import DetailsHeader from './components/DetailsHeader'
import ConsentedCustomerInfo from './components/ConsentedCustomerInfo'
import LinkCopiedSnackbar from './components/LinkCopiedSnackbar'
import TabPanel from './components/TabPanel'
import StyleBoards from './components/StyleBoards'
import oktaTokenStorage from 'utils/okta-token-utils'
import {
  CONVERSATION_FAILED_MESSAGE,
  NO_SAVED_NUMBER_ERROR,
  MAP_TAB_POSITION_TO_NR_EVENT,
  colors
} from './constants'
import { shouldDisplayNewCustomerBadge } from '../CustomerHome/utils/customers'
import { filterAnniversaryEligible } from '../CustomerHome/utils/filterHelpers'
import isEmpty from 'lodash/isEmpty'
import CopyErrorData from 'components/CopyErrorData'
import CopiedErrorSnackbar from 'components/CopiedErrorSnackbar'
import LoadingBackdrop from 'components/LoadingBackdrop'
import BaseSnackbar from 'components/BaseSnackbar'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { NR_CLIENTELING_PAGES } from 'constants/clienteling/nrClientelingPages'
import {
  NR_CUSTOMER_DETAILS_PAGE_ARCHIVE_CUSTOMER,
  NR_CUSTOMER_DETAILS_PAGE_UNARCHIVE_CUSTOMER
} from 'constants/clienteling/newRelicEvents/nrCustomerDetailsPage'

type StateLinkT = {
  isStyleBoardLinkCopySuccess?: boolean
  firstName: string
  lastName: string
}

const SNACKBAR_TIMEOUT = 5000
const UndoButton = ({ action }: { action: () => void }) => (
  <Button sx={{ color: colors.primaryLight }} size="small" onClick={action}>
    Undo
  </Button>
)

const CustomerDetails = (): ReactElement => {
  const [featureFlagsLoaded, setFeatureFlagsLoaded] = useState(false)
  const [isFullPageDisabled, setIsFullPageDisabled] = useState(false)
  const [tabPosition, setTabPosition] = useState(0)
  const [customerArchivedToastActive, setCustomerArchivedToastActive] =
    useState(false)
  const [didConversationCreationFail, setDidConversationCreationFailed] =
    useState(false)
  const [conversationCreationError, setConversationCreationError] = useState('')
  const [isCopyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)
  const history = useHistory<{ sourcePath: string }>()
  const { customerId }: { customerId: string } = useParams()
  const employeeId = oktaTokenStorage
    .getEmployeeNumberFromOktaToken()
    .toString()
  const { state } = useLocation<StateLinkT>()
  const { setDisplayAppBar } = useNavigation()
  const theme = useTheme()
  const { height } = useWindowDimensions()
  const featureFlags = useFeatureFlags()
  const {
    isConsentedCustomerDetailsStyleBoardsEnabled,
    isClientelingAnniversaryStylingAppEnabled,
    isCustomerDetailsWithAdditionalIdsEnabled,
    isFollowUpsFeatureEnabled
  } = featureFlags

  const nrCustomerDetailsEventAttributes = {
    page: NR_CLIENTELING_PAGES.CUSTOMER_DETAILS_PAGE,
    ocpId: customerId
  }

  useEffect(() => {
    if (!isEmpty(featureFlags)) {
      setFeatureFlagsLoaded(true)
    }
  }, [featureFlags])

  const nonConsentedErrorTitle = 'Customer details not available'
  const nonConsentedErrorDescription =
    'This customer hasn’t consented to share their personal details.'
  const apiErrorTitle = 'Issue getting customer details.'
  const snackBarAlertTitle = didConversationCreationFail
    ? CONVERSATION_FAILED_MESSAGE
    : NO_SAVED_NUMBER_ERROR

  const [
    updateCustomerArchiveStatus,
    {
      data: updatedData,
      isLoading: isUpdateCustomerArchiveStatusLoading,
      isError: isErrorUpdatingArchiveStatus,
      error: errorArchive,
      endpointName: endpointArchive,
      originalArgs: originalArgsArchive
    }
  ] = useUpdateCustomerArchiveStatusMutation()

  const {
    data: completedFollowUpsForCustomerData,
    isLoading: completedFollowUpsForCustomerDataIsLoading
  } = useGetCompletedFollowUpsForCustomerQuery(
    {
      customerId,
      page: 1,
      pageSize: 2
    },
    { skip: !isFollowUpsFeatureEnabled?.active }
  )

  const {
    data,
    isLoading,
    isError,
    endpointName,
    error,
    originalArgs,
    refetch
  } = useGetConsentedCustomerQuery(
    {
      customerId,
      retrieveQualification:
        !!isClientelingAnniversaryStylingAppEnabled?.active,
      retrieveSavedSizes: true,
      retrieveShoppedBrands: true,
      retrieveFollowUps: !!isFollowUpsFeatureEnabled?.active,
      fetchAdditionalCustomerIds:
        !!isCustomerDetailsWithAdditionalIdsEnabled?.active
    },
    { skip: !featureFlagsLoaded }
  )

  useEffect(() => {
    if (
      data &&
      updatedData &&
      data.customer.archived !== updatedData.archived
    ) {
      refetch()
    }
  }, [updatedData, data, refetch])

  const handleUpdateCustomerArchiveStatus = (value: boolean) => {
    if (data?.customer) {
      value
        ? generateNewRelicLogs(
            NR_CUSTOMER_DETAILS_PAGE_ARCHIVE_CUSTOMER,
            nrCustomerDetailsEventAttributes
          )
        : generateNewRelicLogs(
            NR_CUSTOMER_DETAILS_PAGE_UNARCHIVE_CUSTOMER,
            nrCustomerDetailsEventAttributes
          )
      updateCustomerArchiveStatus({
        relationshipCustomerId: data.customer.relationshipCustomerId,
        archived: value
      })
      setCustomerArchivedToastActive(true)
    }
  }

  const isArchivedCustomer = useMemo(() => {
    return updatedData ? updatedData?.archived : data?.customer.archived
  }, [data, updatedData])

  const customerFullName = state?.firstName
    ? `${state.firstName} ${state?.lastName}`
    : data?.customer.firstName && data?.customer.lastName
    ? `${data.customer.firstName} ${data.customer.lastName}`
    : ''

  const customerEmail =
    !customerFullName && data?.customer.email ? data?.customer.email : ''

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    generateNewRelicLogs(
      MAP_TAB_POSITION_TO_NR_EVENT[newValue].NR_EVENT,
      nrCustomerDetailsEventAttributes
    )
    setTabPosition(newValue)
    localStorage.setItem('tabPosition', String(newValue))
  }

  const goBackAction = () => {
    localStorage.removeItem('tabPosition')
    localStorage.removeItem('stackScrollPosition')
    const goToRoute = history.location.state?.sourcePath
    if (goToRoute) {
      if (goToRoute === '/review') {
        return history.push('/customers')
      }
      history.push(goToRoute)
    } else {
      history.goBack()
    }
  }

  useEffect(() => {
    const storedTabPosition = localStorage.getItem('tabPosition')
    if (storedTabPosition) {
      setTabPosition(Number(storedTabPosition))
    }
  }, [])

  useEffect(() => {
    setDisplayAppBar(false)
  }, [setDisplayAppBar])

  if (isLoading || completedFollowUpsForCustomerDataIsLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <Stack
        direction="column"
        position="sticky"
        zIndex="1000"
        bgcolor="white"
        top="0"
      >
        <DetailsHeader
          customerName={customerFullName ? customerFullName : customerEmail}
          goBackAction={goBackAction}
          displayNewCustomerBadge={() =>
            shouldDisplayNewCustomerBadge(data?.customer.relationshipDate)
          }
          anniversaryEligibleDate={
            filterAnniversaryEligible(data?.customer.qualification).eligibleDate
          }
          isArchivedCustomer={isArchivedCustomer}
          updateArchiveStatus={handleUpdateCustomerArchiveStatus}
        />
        {(isErrorUpdatingArchiveStatus || !customerFullName) && (
          <Box
            sx={{
              backgroundColor: 'white',
              mb: '8px',
              [theme.breakpoints.up('md')]: {
                width: '80%',
                alignSelf: 'center'
              }
            }}
          >
            <Box
              sx={{
                [theme.breakpoints.down('md')]: {
                  paddingX: 2
                }
              }}
            >
              {!customerFullName && (
                <Alert severity="info" sx={{ mt: 1 }}>
                  <AlertTitle>Customer name is not on file</AlertTitle>
                  Customer can add their name in account on Nordstrom.com.
                </Alert>
              )}
              {isErrorUpdatingArchiveStatus && (
                <PageError
                  isFullWidth
                  isErrorTitlePersonalized
                  errorTitle={
                    isArchivedCustomer
                      ? 'Error moving customer to customer book - try again'
                      : 'Error archiving customer - try again'
                  }
                  errorDetails={{
                    endpoint: endpointArchive,
                    errorData: errorArchive,
                    identifiers: {
                      customerId: customerId,
                      employeeId
                    },
                    originalArgs: originalArgsArchive
                  }}
                />
              )}
            </Box>
          </Box>
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <Tabs
            value={tabPosition}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              alignSelf: 'center',
              [theme.breakpoints.up('md')]: {
                width: '80%'
              },
              width: '100%'
            }}
          >
            <Tab
              value={0}
              label={MAP_TAB_POSITION_TO_NR_EVENT[0].LABEL}
              sx={{ minWidth: 'fit-content', flex: 1 }}
            />
            <Tab
              value={1}
              label={MAP_TAB_POSITION_TO_NR_EVENT[1].LABEL}
              sx={{ minWidth: 'fit-content', flex: 1 }}
            />
            <Tab
              value={2}
              label={MAP_TAB_POSITION_TO_NR_EVENT[2].LABEL}
              sx={{ minWidth: 'fit-content', flex: 1 }}
            />
            {isConsentedCustomerDetailsStyleBoardsEnabled?.active && (
              <Tab
                value={3}
                label={MAP_TAB_POSITION_TO_NR_EVENT[3].LABEL}
                sx={{ minWidth: 'fit-content', flex: 1 }}
              />
            )}
          </Tabs>
        </Box>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor:
            isError || tabPosition === 2 ? '#FFF' : colors.backgroundGrey,
          pb: tabPosition === 1 ? 0 : '40px',
          minHeight: height - 196
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            [theme.breakpoints.up('md')]: {
              width: '80%',
              alignSelf: 'center'
            }
          }}
        >
          {isError && (
            <PageError
              errorTitle={
                error && 'status' in error && error.status === 403
                  ? nonConsentedErrorTitle
                  : apiErrorTitle
              }
              errorDescription={
                error && 'status' in error && error.status === 403
                  ? nonConsentedErrorDescription
                  : undefined
              }
              errorDetails={{
                endpoint: endpointName,
                errorData: error,
                identifiers: {
                  customerId: customerId,
                  curationId: 'NO-DATA',
                  employeeId,
                  shoppingSessionId: 'NO-DATA'
                },
                originalArgs: originalArgs
              }}
            />
          )}
          {data && (
            <>
              <LoadingBackdrop open={isFullPageDisabled} />
              <TabPanel value={tabPosition} index={0}>
                <ConsentedCustomerInfo
                  customer={data.customer}
                  completedFollowUpsCount={
                    completedFollowUpsForCustomerData?.data.length ?? 0
                  }
                  endpointName={endpointName}
                  setDidConversationCreationFailed={
                    setDidConversationCreationFailed
                  }
                  setIsFullPageDisabled={setIsFullPageDisabled}
                  setConversationCreationError={setConversationCreationError}
                  refetchCustomerData={refetch}
                  followUpsError={
                    data.customer.errors?.followUps
                      ? {
                          endpoint: endpointName,
                          identifiers: { customerId: data.customer.customerId }
                        }
                      : undefined
                  }
                />
              </TabPanel>
              <TabPanel value={tabPosition} index={1}>
                <RecentPurchasesList
                  customerId={data.customer.customerId}
                  hideSnapshotNavigation
                  sourcePath={window.location.pathname}
                />
              </TabPanel>
              <TabPanel value={tabPosition} index={2}>
                <WishList
                  customerId={data.customer.customerId}
                  hideSnapshotNavigation
                  isInCustomerDetails={true}
                />
              </TabPanel>
              <TabPanel value={tabPosition} index={3}>
                <StyleBoards
                  customerId={data.customer.customerId}
                  customerName={data.customer.firstName}
                />
              </TabPanel>
            </>
          )}
        </Box>
      </Box>
      {state?.isStyleBoardLinkCopySuccess && <LinkCopiedSnackbar />}
      <BaseSnackbar open={didConversationCreationFail}>
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setDidConversationCreationFailed(false)
                setConversationCreationError('')
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity="error"
        >
          <AlertTitle>
            <>
              <strong>{snackBarAlertTitle}</strong>
              {didConversationCreationFail && (
                <CopyErrorData
                  setCopyToClipboardSuccess={setCopyToClipboardSuccess}
                  errorDetails={{ errorData: conversationCreationError }}
                />
              )}
            </>
          </AlertTitle>
        </Alert>
      </BaseSnackbar>
      {isCopyToClipboardSuccess && (
        <CopiedErrorSnackbar
          setCopyToClipboardSuccess={setCopyToClipboardSuccess}
        />
      )}
      <BaseSnackbar
        open={
          !isUpdateCustomerArchiveStatusLoading &&
          !!updatedData &&
          customerArchivedToastActive
        }
        autoHideDuration={SNACKBAR_TIMEOUT}
        message={
          isArchivedCustomer
            ? 'Customer archived'
            : 'Customer moved to customer book'
        }
        onClose={() => setCustomerArchivedToastActive(false)}
        action={
          <UndoButton
            action={() =>
              handleUpdateCustomerArchiveStatus(!isArchivedCustomer)
            }
          />
        }
      />
    </>
  )
}

export default CustomerDetails
