import { ReactElement } from 'react'
import { Grid } from '@mui/material'
import NoRecentItems from '../NoRecentItems'
import PageError from 'components/PageError'
import RecentItemCard from '../RecentItemCard'
import RecentItemsLoading from '../RecentItemsLoading'
import { useGetRecentProductsQuery as useGetRecentItemsQuery } from 'services/curationSvc'
import oktaTokenStorage from 'utils/okta-token-utils'
import { EmployeeProductT } from 'types/Product'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'

export type PropsT = {
  handleClickForMiniPDP: ({
    webStyleId,
    miniPDPOpenedFrom,
    rmsSku
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    rmsSku: string
  }) => void
}

const RecentItems = (props: PropsT): ReactElement => {
  const { handleClickForMiniPDP } = props

  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()

  const {
    data: recentItemsData,
    endpointName: recentItemsEndpoint,
    error: recentItemsError,
    isError: isGetRecentItemsError,
    isLoading: isGetRecentItemsLoading
  } = useGetRecentItemsQuery(employeeId)

  if (isGetRecentItemsLoading) {
    return <RecentItemsLoading />
  }

  if (isGetRecentItemsError) {
    return (
      <PageError
        errorDetails={{
          endpoint: recentItemsEndpoint,
          errorData: recentItemsError,
          identifiers: {
            employeeId
          }
        }}
      />
    )
  }

  if (recentItemsData && recentItemsData.length === 0) {
    return <NoRecentItems />
  }

  return (
    <Grid container>
      {recentItemsData?.map((item: EmployeeProductT, index) => {
        if (item.rmsSku || item.webStyleId) {
          return (
            <RecentItemCard
              key={`${item.rmsSku}-${index}`}
              brandName={item.brandName}
              clearancePrice={item.clearancePrice}
              color={item.color}
              currentPriceType={item.currentPriceType}
              enticements={item.enticements}
              imageUrl={item.imageUrl}
              itemName={item.itemName}
              itemNumber={item.itemNumber}
              onClickForMiniPDP={handleClickForMiniPDP}
              promotionPrice={item.promotionPrice}
              regularPrice={item.regularPrice}
              rmsSku={item.rmsSku}
              size={item.size}
              webStyleId={item.webStyleId}
              miniPDPOpenedFrom="RecentItemsDrawer"
            />
          )
        }
      })}
    </Grid>
  )
}

export default RecentItems
