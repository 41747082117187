import { useGetConsentedCustomerPurchaseHistoryQuery } from 'services/clientelingApi'
import { Skeleton } from '@mui/material'
import ConsentedCustomerTripsContent from './ConsentedCustomerTripsContent'
import ConsentedCustomerTripsError from './ConsentedCustomerTripsError'
import AdditionalDetails from './AdditionalDetails'
import { CONSENTED_CUSTOMER_TRIPS_ADDITIONAL_DETAILS } from './constants'
import { useState } from 'react'

type PropsT = {
  customerId: string
}

const ConsentedCustomerTripsListItem = ({
  customerId
}: PropsT): JSX.Element => {
  const [isAdditionalDetailsOpen, setIsAdditionalDetailsOpen] = useState(false)
  const { data, isLoading, isError, error, endpointName } =
    useGetConsentedCustomerPurchaseHistoryQuery({
      customerId
    })

  const numberOfTrips = data?.numberOfTrips ?? 0

  const showInfoModal = () => {
    setIsAdditionalDetailsOpen(true)
  }

  if (isError) {
    return (
      <ConsentedCustomerTripsError
        errorDetails={{
          errorData: error,
          endpoint: endpointName,
          identifiers: {
            customerId
          }
        }}
      />
    )
  }

  return isLoading ? (
    <Skeleton
      sx={{
        marginLeft: '16px'
      }}
    >
      <ConsentedCustomerTripsContent
        numberOfTrips={numberOfTrips}
        showInfoModal={showInfoModal}
      />
    </Skeleton>
  ) : (
    <>
      <ConsentedCustomerTripsContent
        numberOfTrips={numberOfTrips}
        showInfoModal={showInfoModal}
      />
      <AdditionalDetails
        open={isAdditionalDetailsOpen}
        onClose={() => setIsAdditionalDetailsOpen(false)}
        dialogText={CONSENTED_CUSTOMER_TRIPS_ADDITIONAL_DETAILS}
      />
    </>
  )
}

export default ConsentedCustomerTripsListItem
