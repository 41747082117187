import { Card } from '@mui/material'
import { SingleCustomerData } from 'types/FollowUps'
import MultipleFollowUps from '../MultipleFollowUps'
import SingleFollowUp from '../SingleFollowUp'

type PropsT = {
  customerId: string
  customerData: SingleCustomerData
  hasMultipleFollowUps: boolean
  onFollowUpClick: () => void
  onCheckFollowUp: () => void
}

const FollowUpCard = ({
  customerId,
  customerData,
  hasMultipleFollowUps,
  onFollowUpClick,
  onCheckFollowUp
}: PropsT): JSX.Element => {
  return (
    <Card
      key={customerId}
      data-testid="follow-up-card"
      sx={{
        marginBottom: '16px'
      }}
    >
      {hasMultipleFollowUps ? (
        <MultipleFollowUps
          customerData={customerData}
          onFollowUpClick={onFollowUpClick}
          onCheckFollowUp={onCheckFollowUp}
        />
      ) : (
        <SingleFollowUp
          customerData={customerData}
          onFollowUpClick={onFollowUpClick}
          onCheckFollowUp={onCheckFollowUp}
        />
      )}
    </Card>
  )
}

export default FollowUpCard
