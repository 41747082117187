import moment from 'moment'
import {
  AppointmentListAppointmentDateT,
  AppointmentListAppointmentT
} from 'types/Appointments'

const getAppointmentsByDate = (appointments: AppointmentListAppointmentT[]) => {
  const appointmentsByDate: Record<string, AppointmentListAppointmentT[]> = {}

  appointments.forEach((appointment) => {
    const appointmentDate = moment(appointment.startAt).format('LL')
    if (appointmentsByDate[appointmentDate]) {
      return appointmentsByDate[appointmentDate].push(appointment)
    }

    return (appointmentsByDate[appointmentDate] = [appointment])
  })

  return appointmentsByDate
}

const getSortedAppointmentDates = (
  appointmentsByDate: Record<string, AppointmentListAppointmentT[]>
) => {
  const dates: { date: string; appointments: AppointmentListAppointmentT[] }[] =
    []

  Object.entries(appointmentsByDate).map(([date, appointments]) => {
    const sortedAppointmentsByTime = appointments.sort((a, b) => {
      return Date.parse(a.startAt) - Date.parse(b.startAt)
    })
    dates.push({ date, appointments: sortedAppointmentsByTime })
  })

  return dates.sort((a, b) => {
    return Date.parse(a.date) - Date.parse(b.date)
  })
}

export const getSortedAppointmentsByDate = (
  appointments: AppointmentListAppointmentT[]
): AppointmentListAppointmentDateT[] => {
  const appointmentsByDate = getAppointmentsByDate(appointments)

  const sortedAppointmentsByDate = getSortedAppointmentDates(appointmentsByDate)

  return sortedAppointmentsByDate
}
