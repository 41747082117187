import { Typography } from '@mui/material'

const Price = ({
  price,
  isSale,
  isStrikeThrough
}: {
  price: string
  isSale: boolean
  isStrikeThrough: boolean
}): JSX.Element => {
  return (
    <Typography
      variant={isStrikeThrough ? 'body2' : 'subtitle2'}
      color={isSale ? 'error' : 'text.primary'}
      sx={{ textDecoration: isStrikeThrough ? 'line-through' : 'none' }}
    >
      {price}
    </Typography>
  )
}

export default Price
