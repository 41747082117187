import { ReactElement } from 'react'
import { Box, Link, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import useReportingLinks from 'hooks/useReportingLinks'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'

const SalesTerms = (): ReactElement => {
  const { salesIncentivesUrl } = useReportingLinks()
  const { isReportingStoreEnabled } = useFeatureFlags()

  return (
    <Box
      mt={4}
      p={2}
      color="secondary.main"
      sx={{ backgroundColor: 'grey.50' }}
    >
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ mr: 0.5 }}>
          <InfoIcon />
        </Typography>
        <Typography variant="subtitle2">Sales terms</Typography>
      </Box>
      <Typography variant="body2">
        <Typography variant="subtitle2" component="span">
          {`All: `}
        </Typography>
        combination of unaudited store and style boards sales—includes wholesale
        and unowned (i.e., hybrid, marketplace and concession) products
      </Typography>
      {isReportingStoreEnabled?.active && (
        <Typography variant="body2">
          <Typography variant="subtitle2" component="span">
            {`Store: `}
          </Typography>
          unaudited in-store and online purchases made for customers through
          point of sale—includes wholesale and unowned (i.e., hybrid,
          marketplace and concession) products
        </Typography>
      )}
      <Typography variant="body2">
        <Typography variant="subtitle2" component="span">
          {`Style board: `}
        </Typography>
        unaudited sales of shipped items from your public and customer style
        boards
      </Typography>
      <Typography variant="body2">
        <Typography variant="subtitle2" component="span">
          {`Net: `}
        </Typography>
        total number of unaudited sales minus number of returned and canceled
        items
      </Typography>
      <Typography variant="body2">
        <Typography variant="subtitle2" component="span">
          {`Gross: `}
        </Typography>
        total unaudited sales—doesn’t include returned or canceled items
      </Typography>
      <Typography variant="body2">
        <Typography variant="subtitle2" component="span">
          {`Year to Date: `}
        </Typography>
        <Link color="secondary.main" href={salesIncentivesUrl} target="_blank">
          Nordstrom sales incentive plans
        </Link>
      </Typography>
    </Box>
  )
}

export default SalesTerms
