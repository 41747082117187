import React from 'react'
import { Redirect } from 'react-router-dom'
import { CurationT } from 'types/Curation'

interface PublishCurationRedirectProps {
  conversationSidForRedirect: string
  didConversationCreationFail: boolean
  didUserClickFinish: boolean
  inProgressCuration: CurationT
  isConsented: boolean
  isOutsideQueueRequest: boolean
  isSellerInitiatedCuration: boolean
  linkToPublishedCuration: string
}

const PublishCurationRedirect: React.FC<PublishCurationRedirectProps> = ({
  conversationSidForRedirect,
  didConversationCreationFail,
  didUserClickFinish,
  inProgressCuration,
  isConsented,
  isOutsideQueueRequest,
  isSellerInitiatedCuration,
  linkToPublishedCuration
}) => {
  // If an error occurs while creating a Twilio conversation redirect to the customer details page
  if (
    didUserClickFinish &&
    didConversationCreationFail &&
    isSellerInitiatedCuration &&
    !!inProgressCuration.ocpId
  ) {
    return (
      <Redirect
        to={{
          pathname: `/customers/details/${inProgressCuration.ocpId}`,
          state: {
            isStyleBoardLinkCopySuccess: true
          }
        }}
      />
    )
  }
  if (didUserClickFinish && isConsented) {
    if (
      isSellerInitiatedCuration &&
      !!inProgressCuration.ocpId &&
      conversationSidForRedirect
    ) {
      return (
        <Redirect
          to={{
            pathname: `/messages/${conversationSidForRedirect}`,
            state: {
              isStyleBoardLinkCopySuccess: true
            }
          }}
        />
      )
    }
  } else {
    if (isSellerInitiatedCuration && !inProgressCuration.ocpId) {
      return (
        <Redirect
          to={{
            pathname: '/styleboards',
            state: {
              isStyleBoardLinkCopySuccess: true,
              curationIdLink: linkToPublishedCuration
            }
          }}
        />
      )
    }
    if (isOutsideQueueRequest) {
      return (
        <Redirect
          to={{
            pathname: '/styleboards',
            state: {
              isStyleBoardLinkCopySuccess: true,
              isOutsideQueueRequest: true
            }
          }}
        />
      )
    }
    return (
      <Redirect
        to={{
          pathname: '/styleboards',
          state: { isPublishCurationSuccess: true }
        }}
      />
    )
  }

  return null
}

export default PublishCurationRedirect
