import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Typography, IconButton } from '@mui/material'
import { ChevronRight } from '@mui/icons-material'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

type PropsT = {
  count: number
  timestamps: TimestampsT
}

type TimestampsT = {
  startOfDay: string
  endOfDay: string
}

const ItemsOrdered = (props: PropsT): ReactElement => {
  const history = useHistory()
  const { count, timestamps } = props
  const { startOfDay, endOfDay } = timestamps

  const copy = {
    title: 'Purchased style board items',
    subtitle: 'Today'
  }

  const handleClick = () => {
    const attributes = {
      page: 'homepage'
    }
    generateNewRelicLogs('purchasedStyleBoardsItemsClick', attributes)
    history.push({
      pathname: `/reporting/insights`,
      search: `?startDate=${startOfDay}&endDate=${endOfDay}`
    })
  }

  return (
    <Box
      px={2}
      py={2}
      mt={2}
      sx={{
        backgroundColor: '#F2F5F6',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography variant="subtitle2">{copy.title}</Typography>
          <Typography variant="body2">{copy.subtitle}</Typography>
        </Box>
        <Box>
          <IconButton
            data-testid="go-to-reporting-insights"
            aria-label="go to reporting insights"
            onClick={handleClick}
            sx={{ padding: 0 }}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>
      <Typography variant="subtitle2">{count}</Typography>
    </Box>
  )
}

export default ItemsOrdered
