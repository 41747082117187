import { StylePriceT } from 'types/Product'
import { formatStylePrices, shouldShowPriceRange } from '../helpers'
import { Box } from '@mui/material'
import Price from '../Price'

const StylePrice = ({
  stylePrice,
  isStyleSoldOut
}: {
  stylePrice: StylePriceT
  isStyleSoldOut: boolean
}): JSX.Element => {
  const formattedStylePrices = formatStylePrices(stylePrice)
  const { totalPriceRange } = formattedStylePrices

  const salePrices =
    formattedStylePrices.promotion || formattedStylePrices.clearance

  if (salePrices) {
    return (
      <Box my={1}>
        <Price
          price={
            shouldShowPriceRange(formattedStylePrices.totalPriceRange)
              ? `${totalPriceRange.min} - ${totalPriceRange.max}`
              : salePrices.min || salePrices.max
          }
          isSale={true}
          isStrikeThrough={false}
        />
        <Price
          price={
            shouldShowPriceRange(formattedStylePrices.regular)
              ? `${formattedStylePrices.regular.min} - ${formattedStylePrices.regular.max}`
              : formattedStylePrices.regular.min ||
                formattedStylePrices.regular.max
          }
          isSale={false}
          isStrikeThrough={true}
        />
      </Box>
    )
  }

  return (
    <Box my={1}>
      <Price
        price={
          isStyleSoldOut
            ? 'Sold Out'
            : shouldShowPriceRange(totalPriceRange)
            ? `${totalPriceRange.min} - ${totalPriceRange.max}`
            : totalPriceRange.min || totalPriceRange.min
        }
        isSale={false}
        isStrikeThrough={false}
      />
    </Box>
  )
}

export default StylePrice
