import { Box, Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import Carousel from 'components/Carousel'
import ItemImage from 'components/ItemImage'
import PriceRange from 'components/PriceRange'
import { PolarisSearchProductsT } from 'types/PolarisProduct'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'

interface PropsT {
  data: PolarisSearchProductsT
  onClickForMiniPDP?: ({
    webStyleId,
    miniPDPOpenedFrom,
    imgUrl
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    imgUrl: string
  }) => void
  miniPDPOpenedFrom: MiniPDPLocationsT
}

const ProductImageCarousel = (props: PropsT): JSX.Element => {
  const { data, onClickForMiniPDP, miniPDPOpenedFrom } = props
  const isMobile = checkBrowserClient.isMobile()

  return (
    <Carousel displayArrows={!isMobile} closeSpacing={true}>
      {data &&
        data.products &&
        data.products.map((item) => (
          <Box key={item.webStyleId} sx={{ width: '130px' }}>
            <Button
              aria-label="view product details"
              onClick={() => {
                if (onClickForMiniPDP && item?.webStyleId) {
                  onClickForMiniPDP({
                    webStyleId: item.webStyleId.toString(),
                    miniPDPOpenedFrom,
                    imgUrl: item.imgUrl
                  })
                }
              }}
              sx={{ maxWidth: '120px', padding: 0 }}
              startIcon={
                <AddIcon
                  sx={{
                    position: 'absolute',
                    top: '2%',
                    right: '2%',
                    backgroundColor: 'white',
                    borderRadius: '4px',
                    padding: '5px',
                    width: '1.5em',
                    height: 'auto',
                    opacity: '0.95'
                  }}
                />
              }
            >
              <ItemImage imageUrl={item.imgUrl} height={184} width={120} />
            </Button>
            <Typography
              variant={'body2'}
              my={0.5}
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                maxWidth: '90px'
              }}
            >
              {item.brandName}
            </Typography>
            <PriceRange price={item.price} />
          </Box>
        ))}
    </Carousel>
  )
}

export default ProductImageCarousel
