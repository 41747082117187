import flow from 'lodash/fp/flow'
import intersection from 'lodash/fp/intersection'
import not from 'lodash/fp/negate'
import map from 'lodash/fp/map'
import reduceFp from 'lodash/fp/reduce'
import omitBy from 'lodash/fp/omitBy'
import filter from 'lodash/fp/filter'
import reduce from 'lodash/reduce'
import mapValues from 'lodash/mapValues'
import overEvery from 'lodash/overEvery'
import transform from 'lodash/transform'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'
import {
  ConsentedCustomerListItemT,
  ExtendedConsentedCustomerListItemT,
  LastPurchaseDateByCustomerIdT,
  LastPurchaseDateWithEmployeeByCustomerIdT,
  NordstromNoteT,
  qualificationT,
  TripsByCustomerIdT
} from 'types/ConsentedCustomer'
import {
  CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES,
  CUSTOMER_BOOK_TRIPS_COUNT_FILTER_CATEGORIES,
  CustomerBookDateFilterCategories,
  CustomerBookFilterKeys,
  CustomerBookFilterOption,
  CustomerBookFilters,
  CustomerBookNoteExpirationFilterCategories,
  AnniversaryEventFilterCategories,
  AnniversaryDateFilterCategories
} from 'types/CustomerBookFilters'
import { ConversationsRecord } from 'types/Twilio'
import { getConversationsWithCustomerIds } from 'services/twilioSlice/utils'
import {
  addLastPurchaseDateByCustomerId,
  addLastPurchaseDateWithEmployeeByCustomerId,
  addTripsCountsByCustomerIds,
  DataForFilteringAndSorting
} from './customers'
import { formatLoyaltyStatus } from 'pages/CustomerDetails/utils'

interface FilterConfig {
  customerBookFiltersCounter: number
  customerBookFilters: CustomerBookFilters
}

interface FeatureFlagsForFilters {
  isCustomerListTripsFilterEnabledActive?: boolean
  isCustomerListLastPurchaseFilterEnabledActive?: boolean
  isCustomerListLastPurchaseWithEmployeeFilterEnabledActive?: boolean
}

export const optionsFilterByQuerySearch = (
  query: string,
  options: CustomerBookFilterOption[]
): CustomerBookFilterOption[] =>
  options.filter((item) => item.name?.toLowerCase().includes(query))

export const getFilteredCustomers = (
  customers: ConsentedCustomerListItemT[],
  filters: CustomerBookFilters,
  customerData?: {
    conversations?: ConversationsRecord
    tripsByCustomerId?: TripsByCustomerIdT
    lastPurchaseByCustomerId?: LastPurchaseDateByCustomerIdT
    lastPurchaseDateWithEmployeeByCustomerId?: LastPurchaseDateWithEmployeeByCustomerIdT
  }
): ConsentedCustomerListItemT[] => {
  const enabledFilterOptions = getEnabledFiltersOptions(filters)
  const filterCriteriaEvaluators = transform(
    enabledFilterOptions,
    (evaluators, options, filterId) => {
      if (options.length === 0) return
      evaluators.push(
        createFilterCriteriaEvaluator(
          options,
          filterId as CustomerBookFilterKeys
        )
      )
    },
    [] as ((customer: ConsentedCustomerListItemT) => boolean)[]
  )
  let customersToFilter: ExtendedConsentedCustomerListItemT[] = [...customers]
  if (customerData?.conversations) {
    customersToFilter = [
      ...getConversationsWithCustomerIds(
        customersToFilter,
        customerData.conversations
      )
    ]
  }
  if (customerData?.tripsByCustomerId) {
    customersToFilter = [
      ...addTripsCountsByCustomerIds(
        customersToFilter,
        customerData.tripsByCustomerId
      )
    ]
  }
  if (customerData?.lastPurchaseByCustomerId) {
    customersToFilter = [
      ...addLastPurchaseDateByCustomerId(
        customersToFilter,
        customerData.lastPurchaseByCustomerId
      )
    ]
  }
  if (customerData?.lastPurchaseDateWithEmployeeByCustomerId) {
    customersToFilter = [
      ...addLastPurchaseDateWithEmployeeByCustomerId(
        customersToFilter,
        customerData.lastPurchaseDateWithEmployeeByCustomerId
      )
    ]
  }
  return customersToFilter.filter((customer) =>
    overEvery(filterCriteriaEvaluators)(customer)
  )
}

const getEnabledFiltersOptions = (filters: CustomerBookFilters) => {
  const values = mapValues(filters, (filter) => {
    const checkedOptions = reduce(
      filter.options,
      (acc, option) => {
        if (option.checked) {
          acc.push(option.name)
        }
        return acc
      },
      [] as string[]
    )
    return checkedOptions
  })
  return values
}

export const filterAnniversaryEligible = (
  qualification?: qualificationT
): { eligibleEA: string; eligibleDate: string } => {
  let eligibleEA = AnniversaryEventFilterCategories.PUBLIC_SALE
  let eligibleDate = AnniversaryDateFilterCategories.PUBLIC_SALE
  if (qualification) {
    switch (true) {
      case qualification.staggeredEA1:
        eligibleEA = AnniversaryEventFilterCategories.ICON_EA
        eligibleDate = AnniversaryDateFilterCategories.ICON_EA
        break
      case qualification.staggeredEA2:
        eligibleEA = AnniversaryEventFilterCategories.AMBASSADOR_EA
        eligibleDate = AnniversaryDateFilterCategories.AMBASSADOR_EA
        break
      case qualification.staggeredEA3:
        eligibleEA = AnniversaryEventFilterCategories.INFLUENCER_EA
        eligibleDate = AnniversaryDateFilterCategories.INFLUENCER_EA
        break
      case qualification.staggeredEA4:
        eligibleEA = AnniversaryEventFilterCategories.ACCESS_PASS
        eligibleDate = AnniversaryDateFilterCategories.ACCESS_PASS
        break
      default:
        eligibleEA = AnniversaryEventFilterCategories.PUBLIC_SALE
        eligibleDate = AnniversaryDateFilterCategories.PUBLIC_SALE
        break
    }
  }
  return { eligibleEA, eligibleDate }
}

export const countExpiringNotesPerCategory = (
  notes: NordstromNoteT[]
): Record<string, number> =>
  flow(
    filter<NordstromNoteT>((note) => note.noteState === 'Active'),
    map((note) => moment(note.expirationDate).diff(moment(new Date()), 'days')),
    filter((daysUntilExpiration) => daysUntilExpiration >= 0),
    reduceFp(
      (acc, due) => {
        if (due <= 15) {
          acc[CustomerBookNoteExpirationFilterCategories.TWO_WEEKS] += 1
          acc[CustomerBookNoteExpirationFilterCategories.ONE_MONTH] += 1
          acc[CustomerBookNoteExpirationFilterCategories.THREE_MONTHS] += 1
          acc[CustomerBookNoteExpirationFilterCategories.AVAILABLE_NOTES] += 1
        } else if (due <= 30) {
          acc[CustomerBookNoteExpirationFilterCategories.ONE_MONTH] += 1
          acc[CustomerBookNoteExpirationFilterCategories.THREE_MONTHS] += 1
          acc[CustomerBookNoteExpirationFilterCategories.AVAILABLE_NOTES] += 1
        } else if (due <= 90) {
          acc[CustomerBookNoteExpirationFilterCategories.THREE_MONTHS] += 1
          acc[CustomerBookNoteExpirationFilterCategories.AVAILABLE_NOTES] += 1
        } else {
          acc[CustomerBookNoteExpirationFilterCategories.AVAILABLE_NOTES] += 1
        }

        return acc
      },
      {
        [CustomerBookNoteExpirationFilterCategories.TWO_WEEKS]: 0,
        [CustomerBookNoteExpirationFilterCategories.ONE_MONTH]: 0,
        [CustomerBookNoteExpirationFilterCategories.THREE_MONTHS]: 0,
        [CustomerBookNoteExpirationFilterCategories.AVAILABLE_NOTES]: 0
      }
    ),
    omitBy((count) => count === 0)
  )(notes) as Record<string, number>

const getLastPurcharseWithEmployeeFilterWithCounters = (pastDate: string) => {
  const pastDateMoment = moment(new Date(pastDate))
  const now = moment(new Date())
  const datesDiffInMonths = now.diff(pastDateMoment, 'months', true)

  const filterOptionsWithCounter = Object.values(
    CustomerBookDateFilterCategories
  ).reduce((acc, category) => {
    acc[category] = 0
    return acc
  }, {} as Record<CustomerBookDateFilterCategories, number>)

  if (!pastDate) {
    filterOptionsWithCounter[
      CustomerBookDateFilterCategories.MORE_THAN_TWELVE_MONTHS
    ] += 1
  } else {
    if (datesDiffInMonths <= 1) {
      filterOptionsWithCounter[CustomerBookDateFilterCategories.ONE_MONTH] += 1
    }
    if (datesDiffInMonths > 1) {
      filterOptionsWithCounter[
        CustomerBookDateFilterCategories.MORE_THAN_ONE_MONTH
      ] += 1
    }
    if (datesDiffInMonths > 3) {
      filterOptionsWithCounter[
        CustomerBookDateFilterCategories.MORE_THAN_THREE_MONTHS
      ] += 1
    }
    if (datesDiffInMonths > 6) {
      filterOptionsWithCounter[
        CustomerBookDateFilterCategories.MORE_THAN_SIX_MONTHS
      ] += 1
    }
    if (datesDiffInMonths > 9) {
      filterOptionsWithCounter[
        CustomerBookDateFilterCategories.MORE_THAN_NINE_MONTHS
      ] += 1
    }
    if (datesDiffInMonths > 12) {
      filterOptionsWithCounter[
        CustomerBookDateFilterCategories.MORE_THAN_TWELVE_MONTHS
      ] += 1
    }
  }
  return filterOptionsWithCounter
}

const getDateDependentFilterWithCounters = (pastDate: string) => {
  const pastDateMoment = moment(new Date(pastDate))
  const now = moment(new Date())
  const datesDiffInMonths = now.diff(pastDateMoment, 'months', true)

  const filterOptionsWithCounter = Object.values(
    CustomerBookDateFilterCategories
  ).reduce((acc, category) => {
    acc[category] = 0
    return acc
  }, {} as Record<CustomerBookDateFilterCategories, number>)

  if (!pastDate) {
    filterOptionsWithCounter[CustomerBookDateFilterCategories.NO_VALUE] += 1
  } else {
    if (datesDiffInMonths <= 1) {
      filterOptionsWithCounter[CustomerBookDateFilterCategories.ONE_MONTH] += 1
    }
    if (datesDiffInMonths > 1) {
      filterOptionsWithCounter[
        CustomerBookDateFilterCategories.MORE_THAN_ONE_MONTH
      ] += 1
    }
    if (datesDiffInMonths > 3) {
      filterOptionsWithCounter[
        CustomerBookDateFilterCategories.MORE_THAN_THREE_MONTHS
      ] += 1
    }
    if (datesDiffInMonths > 6) {
      filterOptionsWithCounter[
        CustomerBookDateFilterCategories.MORE_THAN_SIX_MONTHS
      ] += 1
    }
    if (datesDiffInMonths > 9) {
      filterOptionsWithCounter[
        CustomerBookDateFilterCategories.MORE_THAN_NINE_MONTHS
      ] += 1
    }
    if (datesDiffInMonths > 12) {
      filterOptionsWithCounter[
        CustomerBookDateFilterCategories.MORE_THAN_TWELVE_MONTHS
      ] += 1
    }
  }
  return filterOptionsWithCounter
}

const getBenefitsFilterWithCounters = (benefitsValuesToEvaluate: {
  personalDoublePointsDays: number
  alterationBalance: number
}) => {
  const { personalDoublePointsDays, alterationBalance } =
    benefitsValuesToEvaluate

  const filterOptionsWithCounter = Object.values(
    CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES
  ).reduce((acc, category) => {
    acc[category] = 0
    return acc
  }, {} as Record<typeof CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES[keyof typeof CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES], number>)

  if (personalDoublePointsDays) {
    filterOptionsWithCounter[
      CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES.DOUBLE_POINTS
    ] += 1
  }

  if (alterationBalance) {
    filterOptionsWithCounter[
      CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES.ALTERATION_BALANCE
    ] += 1
  }

  return filterOptionsWithCounter
}

const getTripsCountFilterWithCounters = (tripsCount: number) => {
  const filterOptionsWithCounter = Object.values(
    CUSTOMER_BOOK_TRIPS_COUNT_FILTER_CATEGORIES
  ).reduce((acc, category) => {
    acc[category] = 0
    return acc
  }, {} as Record<CUSTOMER_BOOK_TRIPS_COUNT_FILTER_CATEGORIES, number>)

  if (!tripsCount) {
    filterOptionsWithCounter[
      CUSTOMER_BOOK_TRIPS_COUNT_FILTER_CATEGORIES.ZERO
    ] += 1
  } else if (tripsCount === 1) {
    filterOptionsWithCounter[
      CUSTOMER_BOOK_TRIPS_COUNT_FILTER_CATEGORIES.ONE
    ] += 1
  } else if (tripsCount === 2) {
    filterOptionsWithCounter[
      CUSTOMER_BOOK_TRIPS_COUNT_FILTER_CATEGORIES.TWO
    ] += 1
  } else if (tripsCount >= 3) {
    filterOptionsWithCounter[
      CUSTOMER_BOOK_TRIPS_COUNT_FILTER_CATEGORIES.THREE_OR_MORE
    ] += 1
  }
  return filterOptionsWithCounter
}

const valueIsIncluded = (
  options: string[],
  value: string | boolean,
  filterKey: CustomerBookFilterKeys
) => {
  let returnValue = ''
  switch (filterKey) {
    case CustomerBookFilterKeys.NORDY_CLUB:
      returnValue = value
        ? formatLoyaltyStatus(value as string)
        : 'No loyalty status'
      break
    case CustomerBookFilterKeys.CARDMEMBER:
      returnValue = value ? 'Yes' : 'No'
      break
    default:
      returnValue = value as string
  }
  return Array.isArray(options) && options.includes(returnValue)
}

const createFilterCriteriaEvaluator =
  (options: string[], filterKey: CustomerBookFilterKeys) =>
  (customer: ExtendedConsentedCustomerListItemT) => {
    const valueToEvaluate =
      filterKey !== CustomerBookFilterKeys.BENEFITS && customer[filterKey]

    const benefitsValuesToEvaluate = calculateBenefitsToEvaluate(customer)

    const getEnabledOptions = (values: string[]) => {
      const intersectionValue = intersection(options)(values)
      return intersectionValue
    }
    const isIncludedInSelectedFilterOptions = (
      value: typeof valueToEvaluate
    ) => {
      if (Array.isArray(valueToEvaluate)) {
        if (filterKey === CustomerBookFilterKeys.NORDSTROM_NOTES) {
          return flow(
            countExpiringNotesPerCategory,
            Object.keys,
            getEnabledOptions,
            not(isEmpty)
          )((value as NordstromNoteT[]) ?? [])
        } else {
          return flow(getEnabledOptions, not(isEmpty))(value as string[])
        }
      } else {
        if (filterKey === CustomerBookFilterKeys.ANNIVERSARY) {
          const valueAnniversary = filterAnniversaryEligible(
            valueToEvaluate as qualificationT
          ).eligibleEA
          return valueIsIncluded(options, valueAnniversary, filterKey)
        } else if (filterKey === CustomerBookFilterKeys.TRIPS_COUNT) {
          const filterOptionsWithCounters = getTripsCountFilterWithCounters(
            (valueToEvaluate ?? 0) as number
          )
          const onlyEnabledOption = getEnabledOptions(
            Object.keys(filterOptionsWithCounters)
          )[0]
          const hasEnabledOptionResults =
            filterOptionsWithCounters[
              onlyEnabledOption as CUSTOMER_BOOK_TRIPS_COUNT_FILTER_CATEGORIES
            ] > 0
          return hasEnabledOptionResults
        } else if (filterKey === CustomerBookFilterKeys.BENEFITS) {
          const filterOptionsWithCounters = getBenefitsFilterWithCounters(
            benefitsValuesToEvaluate
          )
          const enabledOptions = getEnabledOptions(
            Object.keys(filterOptionsWithCounters)
          )

          const enabledOptionsIncludesDoublePoints = enabledOptions.includes(
            CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES.DOUBLE_POINTS
          )
          const enabledOptionsIncludesAlterationBalance =
            enabledOptions.includes(
              CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES.ALTERATION_BALANCE
            )

          const hasEnabledDoublePointsOptionResults =
            enabledOptionsIncludesDoublePoints &&
            filterOptionsWithCounters[
              CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES.DOUBLE_POINTS as typeof CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES[keyof typeof CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES]
            ] > 0
          const hasEnabledAlterationBalanceOptionResults =
            enabledOptionsIncludesAlterationBalance &&
            filterOptionsWithCounters[
              CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES.ALTERATION_BALANCE as typeof CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES[keyof typeof CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES]
            ] > 0

          return (
            hasEnabledDoublePointsOptionResults ||
            hasEnabledAlterationBalanceOptionResults
          )
        } else if (
          filterKey === CustomerBookFilterKeys.LAST_MESSAGE_TIME ||
          filterKey === CustomerBookFilterKeys.LAST_PURCHASE ||
          filterKey === CustomerBookFilterKeys.LAST_PURCHASE_WITH_YOU
        ) {
          const filterOptionsWithCounters =
            filterKey === CustomerBookFilterKeys.LAST_PURCHASE_WITH_YOU
              ? getLastPurcharseWithEmployeeFilterWithCounters(
                  valueToEvaluate as string
                )
              : getDateDependentFilterWithCounters(valueToEvaluate as string)
          const onlyEnabledOption = getEnabledOptions(
            Object.keys(filterOptionsWithCounters)
          )[0]
          const hasEnabledOptionResults =
            filterOptionsWithCounters[
              onlyEnabledOption as CustomerBookDateFilterCategories
            ] > 0
          return hasEnabledOptionResults
        } else {
          return valueIsIncluded(
            options,
            valueToEvaluate as string | boolean,
            filterKey
          )
        }
      }
    }

    return isIncludedInSelectedFilterOptions(valueToEvaluate)
  }

const calculateBenefitsToEvaluate = (
  customer: ExtendedConsentedCustomerListItemT
) => {
  const personalDoublePointsDays = customer.personalDoublePointsDays ?? 0

  const alterationBalance =
    (customer.loyaltyStatus === 'Nordy Influencer' ||
      customer.loyaltyStatus === 'Nordy Ambassador') &&
    customer.alterationBalance
      ? customer.alterationBalance
      : 0

  return {
    personalDoublePointsDays,
    alterationBalance
  }
}

export const applyFilters = (
  customers: ConsentedCustomerListItemT[],
  filters: FilterConfig,
  filterData: DataForFilteringAndSorting,
  featureFlags: FeatureFlagsForFilters
): ConsentedCustomerListItemT[] => {
  if (filters.customerBookFiltersCounter === 0) return customers
  return getFilteredCustomers(customers, filters.customerBookFilters, {
    conversations: filterData.conversations,
    tripsByCustomerId: featureFlags.isCustomerListTripsFilterEnabledActive
      ? filterData.tripsCount
      : undefined,
    lastPurchaseByCustomerId:
      featureFlags.isCustomerListLastPurchaseFilterEnabledActive
        ? filterData.lastPurchaseDate
        : undefined,
    lastPurchaseDateWithEmployeeByCustomerId:
      featureFlags.isCustomerListLastPurchaseWithEmployeeFilterEnabledActive
        ? filterData.lastPurchaseDateWithEmployee
        : undefined
  })
}
