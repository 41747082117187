import { List, ListItem } from '@mui/material'
import { ExtendedFollowUpT } from 'types/FollowUps'
import FollowUpCard from './FollowUpCard'
import { useHistory } from 'react-router-dom'
import { isPurchaseBasedFollowUp } from 'utils/determineFollowUpKind'

type PropsT = {
  followUps: ExtendedFollowUpT[]
  customerId: string
}

const FollowUpCards = ({ followUps, customerId }: PropsT): JSX.Element => {
  const history = useHistory()

  const goToPurchaseDetails = (orderId: string) => () => {
    history.push({
      pathname: `/purchase-history/order/${orderId}`,
      state: {
        customerId
      }
    })
  }

  const followUpKindsWithIds = followUps.map((followUp) => ({
    followUp,
    id: crypto.randomUUID()
  }))

  return (
    <List>
      {followUpKindsWithIds.map(({ id, followUp }) => (
        <ListItem key={id} sx={{ paddingX: '0', paddingY: '6px' }}>
          <FollowUpCard
            followUp={followUp}
            onClick={
              isPurchaseBasedFollowUp(followUp.followUpKind)
                ? goToPurchaseDetails(followUp.meta?.orderId ?? '')
                : undefined
            }
          />
        </ListItem>
      ))}
    </List>
  )
}

export default FollowUpCards
