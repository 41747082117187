import { Redirect, useHistory } from 'react-router-dom'
import {
  Container,
  Typography,
  List,
  AppBar,
  Toolbar,
  IconButton,
  Button
} from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import LoadingSpinner from 'components/LoadingSpinner'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import {
  useGetCompletedFollowUpsForEmployeeQuery,
  useGetFollowUpOpportunitiesQuery
} from 'services/clientelingApi'
import { FollowUpCustomersData } from 'types/FollowUps'
import FollowUpCard from './components/FollowUpCard'
import PageError from 'components/PageError'
import FollowUpEmptyState from './components/FollowUpEmptyState'

const FollowUps = (): JSX.Element => {
  const featureFlags = useFeatureFlags()
  const { isFollowUpsFeatureEnabled } = featureFlags
  const history = useHistory()
  const { data, isLoading, isError, error, endpointName } =
    useGetFollowUpOpportunitiesQuery()
  const {
    data: employeeCompletedFollowUps,
    isLoading: isCompletedFollowUpsLoading
  } = useGetCompletedFollowUpsForEmployeeQuery({ page: 1, pageSize: 50 })

  const followUpsWithCustomers = data || ({} as FollowUpCustomersData)
  const completedFollowUpsCount = employeeCompletedFollowUps?.totalCount || 0

  const hasMultipleFollowUps = (
    customerId: string,
    customers: FollowUpCustomersData
  ) => {
    const customer = customers[customerId]
    return customer ? customer.followUps.length > 1 : false
  }

  const goBackAction = () => {
    history.goBack()
  }

  const goToCustomerDetails = (customerId: string) => {
    history.push(`/customers/details/${customerId}`)
  }

  const checkFollowUp = () => {
    // TODO: NON-2072
  }

  if (Object.keys(featureFlags)?.length && !isFollowUpsFeatureEnabled?.active) {
    return <Redirect to="/customers" />
  }

  if (isLoading || isCompletedFollowUpsLoading) {
    return <LoadingSpinner />
  }

  return (
    <>
      <AppBar
        position="static"
        style={{ backgroundColor: 'white', boxShadow: 'none' }}
      >
        <Toolbar sx={{ marginY: '8px' }}>
          <IconButton
            onClick={goBackAction}
            size="large"
            sx={{ marginRight: '16px' }}
            data-testid="goBackButton"
          >
            <ArrowBackIosIcon sx={{ color: 'text.primary' }} />
          </IconButton>
          <Typography variant="h6" color="text.primary">
            Follow up
          </Typography>
        </Toolbar>
      </AppBar>
      <Container sx={{ maxWidth: '1040px' }}>
        {isError && (
          <PageError
            isFullWidth
            errorTitle="Issue getting your follow ups - "
            errorDetails={{ endpoint: endpointName, errorData: error }}
          />
        )}
        {!isError && Object.keys(followUpsWithCustomers).length === 0 && (
          <FollowUpEmptyState />
        )}
        {!isError && Object.keys(followUpsWithCustomers).length > 0 && (
          <List>
            {Object.entries(followUpsWithCustomers).map(
              ([customerId, customerData]) => {
                const hasMultiple = hasMultipleFollowUps(
                  customerId,
                  followUpsWithCustomers
                )
                return (
                  <FollowUpCard
                    key={customerId}
                    customerId={customerId}
                    customerData={customerData}
                    hasMultipleFollowUps={hasMultiple}
                    onFollowUpClick={() => goToCustomerDetails(customerId)}
                    onCheckFollowUp={checkFollowUp}
                  />
                )
              }
            )}
          </List>
        )}
        {!isError && completedFollowUpsCount > 0 && (
          <Button fullWidth variant="outlined" sx={{ mb: 2 }}>
            View Completed
          </Button>
        )}
      </Container>
    </>
  )
}

export default FollowUps
