import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import reduce from 'lodash/reduce'
import { RootState } from 'app/store'
import {
  CustomerBookFilterKeys,
  CustomerBookFilters,
  CustomerBookFilter,
  CustomerBookNoteExpirationFilterCategories,
  AnniversaryEventFilterCategories,
  AnniversaryDateFilterCategories,
  CustomerBookDateFilterCategories,
  CustomerBookFilterOption,
  CUSTOMER_BOOK_TRIPS_COUNT_FILTER_CATEGORIES,
  CustomerBookDateFilterEmptyValues,
  CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES
} from 'types/CustomerBookFilters'

export const FILTERS_KEY = 'filtersStorageKey'

const storeFilters = (filters: CustomerBookFilters) =>
  sessionStorage.setItem(FILTERS_KEY, JSON.stringify(filters))

export const getFilterFromStorage: () => CustomerBookFilters = () => {
  const stringFilters = sessionStorage.getItem(FILTERS_KEY)
  return stringFilters
    ? (JSON.parse(stringFilters) as CustomerBookFilters)
    : ({} as CustomerBookFilters)
}

export const initializeAnniversaryFilter: () => CustomerBookFilter = () => ({
  title: 'Anniversary shopping access',
  selectedOptionsCounter: 0,
  disabled: false,
  options: [
    {
      name: AnniversaryEventFilterCategories.ICON_EA,
      checked: false,
      dateEA: AnniversaryDateFilterCategories.ICON_EA
    },
    {
      name: AnniversaryEventFilterCategories.AMBASSADOR_EA,
      checked: false,
      dateEA: AnniversaryDateFilterCategories.AMBASSADOR_EA
    },
    {
      name: AnniversaryEventFilterCategories.INFLUENCER_EA,
      checked: false,
      dateEA: AnniversaryDateFilterCategories.INFLUENCER_EA
    },
    {
      name: AnniversaryEventFilterCategories.ACCESS_PASS,
      checked: false,
      dateEA: AnniversaryDateFilterCategories.ACCESS_PASS
    },
    {
      name: AnniversaryEventFilterCategories.PUBLIC_SALE,
      checked: false,
      dateEA: AnniversaryDateFilterCategories.PUBLIC_SALE
    }
  ]
})

export const initializeNordyClubFilter: () => CustomerBookFilter = () => ({
  title: 'Nordy Club status',
  disabled: false,
  selectedOptionsCounter: 0,
  options: [
    {
      name: 'Icon',
      checked: false
    },
    {
      name: 'Ambassador',
      checked: false
    },
    {
      name: 'Influencer',
      checked: false
    },
    {
      name: 'Member',
      checked: false
    },
    {
      name: 'No loyalty status',
      checked: false
    }
  ]
})

export const initializeNordyClubBenefitsFilter: () => CustomerBookFilter =
  () => ({
    title: 'Nordy Club benefits',
    disabled: false,
    selectedOptionsCounter: 0,
    options: [
      {
        name: CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES.ALTERATION_BALANCE,
        checked: false
      },
      {
        name: CUSTOMER_BOOK_BENEFITS_FILTER_CATEGORIES.DOUBLE_POINTS,
        checked: false
      }
    ]
  })

export const initializeCardmemberFilter: () => CustomerBookFilter = () => ({
  title: 'Cardmember',
  disabled: false,
  selectedOptionsCounter: 0,
  options: [
    {
      name: 'Yes',
      checked: false
    },
    {
      name: 'No',
      checked: false
    }
  ]
})

export const initializeSavedBrandsFilter: () => CustomerBookFilter = () => ({
  title: 'Brands',
  disabled: false,
  selectedOptionsCounter: 0,
  options: []
})

export const initializeNotesFilter: () => CustomerBookFilter = () => ({
  title: 'Nordstrom Notes',
  disabled: false,
  selectedOptionsCounter: 0,
  options: Object.values(CustomerBookNoteExpirationFilterCategories).map(
    (category) => ({ name: category, checked: false })
  )
})

export const initializeTripsCountFilter: () => CustomerBookFilter = () => ({
  title: 'Trips with you (within 12 months)',
  disabled: false,
  selectedOptionsCounter: 0,
  options: Object.values(CUSTOMER_BOOK_TRIPS_COUNT_FILTER_CATEGORIES).map(
    (category) => ({ name: category, checked: false })
  )
})

export const initializeLastMessageTimeFilter: () => CustomerBookFilter =
  () => ({
    title: 'Last message',
    disabled: false,
    selectedOptionsCounter: 0,
    options: Object.values(CustomerBookDateFilterCategories).map(
      (category) => ({
        name: category,
        checked: false,
        nameOverride:
          category === CustomerBookDateFilterCategories.NO_VALUE
            ? CustomerBookDateFilterEmptyValues.NO_MESSAGES
            : undefined
      })
    )
  })

export const initializeLastPurchaseDateTimeFilter: () => CustomerBookFilter =
  () => ({
    title: 'Last Nordstrom purchase',
    disabled: false,
    selectedOptionsCounter: 0,
    options: Object.values(CustomerBookDateFilterCategories).map(
      (category) => ({
        name: category,
        checked: false,
        nameOverride:
          category === CustomerBookDateFilterCategories.NO_VALUE
            ? CustomerBookDateFilterEmptyValues.NO_PURCHASES
            : undefined
      })
    )
  })

export const initializeLastPurchaseDateWithEmployeeTimeFilter: () => CustomerBookFilter =
  () => ({
    title: 'Last purchase with you',
    disabled: false,
    selectedOptionsCounter: 0,
    options: Object.values(CustomerBookDateFilterCategories)
      .filter(
        (category) => category !== CustomerBookDateFilterCategories.NO_VALUE
      )
      .map((category) => ({
        name: category,
        checked: false,
        nameOverride:
          category === CustomerBookDateFilterCategories.MORE_THAN_TWELVE_MONTHS
            ? CustomerBookDateFilterEmptyValues.MORE_THAN_TWELVE_MONTHS_OR_NO_PURCHASES
            : undefined
      }))
  })

export const FiltersSliceInitialState: CustomerBookFilters = {
  qualification: initializeAnniversaryFilter(),
  loyaltyStatus: initializeNordyClubFilter(),
  cardmember: initializeCardmemberFilter(),
  nordstromNotes: initializeNotesFilter(),
  benefits: initializeNordyClubBenefitsFilter(),
  tripsCount: initializeTripsCountFilter(),
  lastPurchaseDateWithEmployee:
    initializeLastPurchaseDateWithEmployeeTimeFilter(),
  lastPurchaseDate: initializeLastPurchaseDateTimeFilter(),
  lastMessage: initializeLastMessageTimeFilter(),
  brands: initializeSavedBrandsFilter()
}

export const customerBookFiltersSlice = createSlice({
  name: 'customerBookFilters',
  initialState: {
    filters: FiltersSliceInitialState
  },
  reducers: {
    toggleFilterOption: (
      state,
      action: PayloadAction<{
        filterId: CustomerBookFilterKeys
        filterOptionIndex: number
      }>
    ) => {
      const { filterId, filterOptionIndex } = action.payload
      const filter = state.filters[filterId]
      const option = state.filters[filterId].options[filterOptionIndex]
      if (option.checked) {
        option.checked = false
        filter.selectedOptionsCounter -= 1
      } else {
        option.checked = true
        filter.selectedOptionsCounter += 1
      }

      switch (filterId) {
        // radio button options - only one selection allowed
        case CustomerBookFilterKeys.TRIPS_COUNT:
        case CustomerBookFilterKeys.NORDSTROM_NOTES:
        case CustomerBookFilterKeys.LAST_PURCHASE:
        case CustomerBookFilterKeys.LAST_PURCHASE_WITH_YOU:
        case CustomerBookFilterKeys.LAST_MESSAGE_TIME: {
          uncheckOtherFilterOptionsOnSelection(filter, option)
          break
        }

        case CustomerBookFilterKeys.ANNIVERSARY: {
          state.filters[CustomerBookFilterKeys.CARDMEMBER].disabled =
            !!filter.selectedOptionsCounter
          state.filters[CustomerBookFilterKeys.NORDY_CLUB].disabled =
            !!filter.selectedOptionsCounter
          break
        }

        case CustomerBookFilterKeys.CARDMEMBER: {
          uncheckOtherFilterOptionsOnSelection(filter, option)
          disableAnniversaryFilterOnSelection(state)
          break
        }

        case CustomerBookFilterKeys.NORDY_CLUB: {
          disableAnniversaryFilterOnSelection(state)
          break
        }
      }

      storeFilters(state.filters)
    },
    setFilters: (
      state,
      action: PayloadAction<{
        filters: CustomerBookFilters
      }>
    ) => {
      const { filters } = action.payload
      state.filters = filters
    },
    clearFilters: (state) => {
      const cleanFilters = {
        qualification: initializeAnniversaryFilter(),
        loyaltyStatus: initializeNordyClubFilter(),
        cardmember: initializeCardmemberFilter(),
        nordstromNotes: initializeNotesFilter(),
        benefits: initializeNordyClubBenefitsFilter(),
        tripsCount: initializeTripsCountFilter(),
        lastPurchaseDateWithEmployee:
          initializeLastPurchaseDateWithEmployeeTimeFilter(),
        lastPurchaseDate: initializeLastPurchaseDateTimeFilter(),
        lastMessage: initializeLastMessageTimeFilter(),
        brands: initializeSavedBrandsFilter()
      }
      state.filters.brands.options.forEach((option) => (option.checked = false))
      cleanFilters.brands.options = state.filters.brands.options

      state.filters = { ...cleanFilters }
      storeFilters(state.filters)
    }
  }
})

export const { toggleFilterOption, setFilters, clearFilters } =
  customerBookFiltersSlice.actions

export const customerBookFiltersSelector = (
  state: RootState
): CustomerBookFilters => state.customerBookFilters.filters

export const customerBookFiltersCounterSelector = (
  state: RootState
): number => {
  const selectedFiltersOptionsCounter = reduce(
    state.customerBookFilters.filters,
    (acc, filter) => {
      const selectedOptions = filter.selectedOptionsCounter
      return acc + selectedOptions
    },
    0
  )
  return selectedFiltersOptionsCounter
}

const uncheckOtherFilterOptionsOnSelection = (
  filter: CustomerBookFilter,
  option: CustomerBookFilterOption
) => {
  filter.options
    .filter((op) => op !== option)
    .forEach((op) => {
      if (op.checked) {
        filter.selectedOptionsCounter -= 1
        op.checked = false
      }
    })
}

const disableAnniversaryFilterOnSelection = (state: {
  filters: CustomerBookFilters
}) => {
  state.filters[CustomerBookFilterKeys.ANNIVERSARY].disabled =
    !!state.filters[CustomerBookFilterKeys.CARDMEMBER].selectedOptionsCounter ||
    !!state.filters[CustomerBookFilterKeys.NORDY_CLUB].selectedOptionsCounter
}
