import { Box, Typography, Button, Grid } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { twilioSelectors } from 'services/twilioSlice'
import { useAppSelector } from 'app/hooks'
import MarkChatUnreadIcon from '@mui/icons-material/MarkChatUnread'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import Skeleton from 'components/Skeleton'
import ErrorMessage from '../ErrorMessage'

const Customers = (): JSX.Element => {
  const history = useHistory()
  const isLoading = useAppSelector(twilioSelectors.isLoadingSelector)
  const unreadMessagesCount: number | null = useAppSelector(
    twilioSelectors.allUnreadMessagesCountSelector
  )
  const errorLoadingUnreadMessages = unreadMessagesCount === null

  const handleClick = () => {
    const attributes = {
      page: 'homepage'
    }
    generateNewRelicLogs('unreadViewMessagesClick', attributes)
    history.push({ pathname: '/messages' })
  }

  const CardContent = () => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: { xs: '100%', md: '118px' },
        padding: { xs: '12px', md: '16px' },
        borderRadius: '4px',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: { xs: '8px', sm: '12px', md: '12px' },
          height: '100%'
        }}
      >
        <Box
          my="auto"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignSelf: 'flex-start',
            borderRadius: '50%',
            width: '40px',
            height: '40px',
            marginRight: 2,
            minWidth: '40px',
            bgcolor: unreadMessagesCount === 0 ? '#eeeeee' : '#e9e6f7'
          }}
        >
          <MarkChatUnreadIcon
            sx={{
              fill: unreadMessagesCount === 0 ? '#6B777B' : '#482FE9',
              fontSize: '18px'
            }}
          />
        </Box>
        <Typography variant="subtitle2" data-testid="unreadMessagesBadge">
          {unreadMessagesCount || 0} unread{' '}
          {unreadMessagesCount === 1 ? 'message' : 'messages'}
        </Typography>
      </Box>
      <Button
        sx={{
          display: 'flex',
          alignItems: 'center',
          textDecoration: 'none',
          alignSelf: 'flex-end',
          cursor: 'pointer',
          color: '#482FE9',
          marginTop: 'auto',
          '&:hover': {
            textDecoration: 'underline'
          }
        }}
        onClick={handleClick}
      >
        <Typography
          variant="button"
          sx={{
            fontWeight: 'bold',
            marginRight: '8px'
          }}
        >
          View Messages
        </Typography>
        <ArrowForwardIosIcon
          sx={{
            fontSize: { xs: '12px', sm: '15px' },
            fontWeight: 'bold'
          }}
        />
      </Button>
    </Box>
  )

  return (
    <Box px={2} mt={3}>
      <Typography variant="h6">Customers</Typography>
      <Typography variant="body2">Stay connected with customers</Typography>
      <Grid container spacing={2} mt={2}>
        {errorLoadingUnreadMessages ? (
          <ErrorMessage
            errorTitle="Hmm, the section didn’t load"
            isInlineAlert
          />
        ) : (
          <Grid item xs={12} sm={12} md={4}>
            {isLoading ? (
              <Skeleton width="100%" height="118px" />
            ) : (
              <CardContent />
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default Customers
