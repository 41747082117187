import { ReactElement } from 'react'
import { Button, Grid } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import ItemDetails from 'components/ItemDetails'
import { EnticementDetailsT, PriceTypes } from 'types/Product'
import { determineCurrentPrice } from 'utils/priceHelpers'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'

export type PropsT = {
  brandName?: string
  clearancePrice?: number
  color?: string
  currentPriceType?: PriceTypes
  enticements?: EnticementDetailsT[]
  imageUrl?: string
  itemName?: string
  itemNumber?: string
  onClickForMiniPDP: ({
    webStyleId,
    miniPDPOpenedFrom,
    rmsSku
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    rmsSku: string
  }) => void
  promotionPrice?: number
  regularPrice?: number
  rmsSku?: string
  size?: string
  webStyleId?: string
  miniPDPOpenedFrom: MiniPDPLocationsT
  lastPurchased?: string
}

const RecentItemCard = (props: PropsT): ReactElement | null => {
  const {
    brandName,
    clearancePrice,
    color,
    currentPriceType,
    enticements,
    imageUrl,
    itemName,
    itemNumber,
    onClickForMiniPDP,
    promotionPrice,
    regularPrice,
    rmsSku,
    size,
    webStyleId,
    lastPurchased,
    miniPDPOpenedFrom
  } = props

  if (!rmsSku && !webStyleId) {
    return null
  }

  const currentPrice = determineCurrentPrice({
    clearancePrice: clearancePrice,
    priceType: currentPriceType,
    promotionPrice: promotionPrice,
    regularPrice: regularPrice
  })

  return (
    <Grid container display="flex" alignItems="center" sx={{ mb: 2 }}>
      <Grid item xs={10} display="flex">
        <ItemDetails
          brandName={brandName}
          color={color}
          currentPrice={currentPrice}
          enticements={enticements}
          imageUrl={imageUrl}
          itemName={itemName}
          itemNumber={itemNumber}
          onClickForMiniPDP={onClickForMiniPDP}
          regularPrice={regularPrice}
          size={size}
          webStyleId={webStyleId}
          rmsSku={rmsSku}
          lastPurchased={lastPurchased}
          miniPDPOpenedFrom={miniPDPOpenedFrom}
        />
      </Grid>
      <Grid item display="flex" justifyContent="center" xs={2}>
        <Button
          onClick={() =>
            webStyleId &&
            rmsSku &&
            onClickForMiniPDP({
              webStyleId,
              miniPDPOpenedFrom,
              rmsSku
            })
          }
          startIcon={<AddIcon />}
        >
          Add
        </Button>
      </Grid>
    </Grid>
  )
}

export default RecentItemCard
