import { Box, Container, Typography } from '@mui/material'
import { ReactElement } from 'react'
import { checkBrowserClient } from 'utils/userAgentDetector'
import CustomerBookButton from '../CustomerBookButton/CustomerBookButton'

const EmptyAppointmentsList = (): ReactElement => {
  const isMobileDevice = checkBrowserClient.isMobile()
  const isCustomerBookButtonVisible = isMobileDevice

  return (
    <Container
      sx={{
        height: '100%',
        paddingRight: isMobileDevice && 0,
        paddingLeft: isMobileDevice && 0,
        mt: '20px'
      }}
    >
      <Box sx={{ paddingX: isMobileDevice ? '16px' : 0 }}>
        <Typography fontSize={16} data-testid="appointments-empty-list-text">
          Looks like you don’t have any upcoming appointments. Follow up with a
          customer and book an appointment with them.
        </Typography>
        {isCustomerBookButtonVisible && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px'
            }}
          >
            <CustomerBookButton />
          </Box>
        )}
      </Box>
    </Container>
  )
}
export default EmptyAppointmentsList
