import { Button, Container, Stack, Typography } from '@mui/material'
import { ReactElement } from 'react'
import QrCodeIcon from '@mui/icons-material/QrCode'

type PropsT = {
  handleQRModalOpen: () => void
}

const QrInviteSection = ({ handleQRModalOpen }: PropsT): ReactElement => {
  return (
    <Container maxWidth="xs">
      <Stack spacing={2}>
        <Typography variant="subtitle2">
          Not finding the customer with their mobile number or email?
        </Typography>
        <Button
          fullWidth
          onClick={handleQRModalOpen}
          variant="outlined"
          startIcon={<QrCodeIcon />}
        >
          Share Invitation Link
        </Button>
      </Stack>
    </Container>
  )
}

export default QrInviteSection
