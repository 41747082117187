import { ReactElement } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import { ChevronRight } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { isStyleAdvisor } from 'utils/userPermissions'
import { SalesMetricT } from 'types/SalesApi'
import { formatPrice } from 'utils/formatPrice'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

type PropsT = {
  salesData: SalesMetricT
}

const NetSales = (props: PropsT): ReactElement => {
  const { salesData } = props
  const isMobileDevice = checkBrowserClient.isMobile()
  const history = useHistory()

  const handleClick = () => {
    const attributes = {
      page: 'homepage'
    }
    generateNewRelicLogs('netSalesClick', attributes)
    history.push('/reporting?timeframe=yesterday&channel=all')
  }

  return (
    <Box
      sx={{
        marginTop: 3,
        padding: 2,
        backgroundColor: '#f2f5f6',
        borderRadius: '4px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography variant="subtitle2">Net sales</Typography>
          <Typography variant="body2">Yesterday</Typography>
        </Box>
        <Box>
          <IconButton
            data-testid="chevron-right-icon"
            onClick={handleClick}
            aria-label="Go to reporting sales"
            sx={{
              padding: 0
            }}
          >
            <ChevronRight />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingTop: 2,
          paddingRight: isMobileDevice ? 2 : 16,
          gap: 1
        }}
      >
        {!isStyleAdvisor() && (
          <>
            <Box>
              <Typography variant="body2">All</Typography>
              <Typography variant="subtitle2">
                {formatPrice.format(salesData.net_amt_ty)}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2">Store</Typography>
              <Typography variant="subtitle2">
                {formatPrice.format(salesData.store_net_amt_ty)}
              </Typography>
            </Box>
          </>
        )}
        <Box>
          <Typography variant="body2">Style board</Typography>
          <Typography variant="subtitle2">
            {formatPrice.format(salesData.digital_net_amt_ty)}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default NetSales
