import { ReactElement, useState } from 'react'
import { Typography, Box, Button } from '@mui/material'
import { useGetDraftCurationsQuery } from 'services/curationSvc'
import { useHistory } from 'react-router-dom'
import { checkBrowserClient } from 'utils/userAgentDetector'
import Skeleton from 'components/Skeleton'
import DeleteDraftAlert from 'pages/Drafts/components/DeleteDraftAlert'
import Carousel from 'components/Carousel'
import DraftCard from './DraftCard'
import CreateStyleBoardDialog from 'pages/CurationHome/components/CreateStyleBoardDialog'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import ErrorMessage from '../ErrorMessage'

interface CTAButtonT {
  isMobileView?: boolean
  createAction: (value: boolean) => void
  curationsCount: number
}

const CTAButton = (props: CTAButtonT) => {
  const { isMobileView, curationsCount, createAction } = props
  const history = useHistory()

  const handleViewAllClick = () => {
    const attributes = {
      page: 'homepage'
    }

    generateNewRelicLogs('viewAllDraftsClick', attributes)
    history.push('/styleboards/drafts')
  }

  const onClickAction =
    curationsCount > 0 ? () => handleViewAllClick() : () => createAction(true)
  return (
    <Box marginTop={isMobileView && curationsCount > 0 ? '24px' : '0'}>
      <Button
        fullWidth={!!isMobileView}
        onClick={onClickAction}
        variant="outlined"
      >
        {curationsCount > 0 ? 'View All' : 'Create Style Board'}
      </Button>
    </Box>
  )
}

const Drafts = (): ReactElement => {
  const { endpointName, data, isLoading, isSuccess, isError, error } =
    useGetDraftCurationsQuery()
  const curationsCount = data?.curations?.length || 0
  const isMobileDevice = checkBrowserClient.isMobile()
  const [handleAbandonCurationError, setHandleAbandonCurationError] =
    useState(false)
  const [isCreateStyleBoardModalOpen, setIsCreateStyleBoardModalOpen] =
    useState<boolean>(false)

  const errorDetails = {
    endpoint: endpointName,
    errorData: error
  }
  const showButton = !isMobileDevice && !isError && !isLoading
  const showButtonMobileView = isMobileDevice && !isError && !isLoading

  return (
    <Box width={'100%'} padding={'0 16px'}>
      <CreateStyleBoardDialog
        open={isCreateStyleBoardModalOpen}
        onClose={() => {
          setIsCreateStyleBoardModalOpen(false)
        }}
        sourcePage="HomepageDrafts"
      />
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box>
          <Typography variant="h6">Drafts</Typography>
          {!isError && !isLoading && (
            <Typography
              mb={3}
              variant="body2"
              style={{
                width: isMobileDevice ? '100%' : 'calc(100vw - 250px)',
                paddingTop: curationsCount > 0 ? 'auto' : '24px',
                marginBottom:
                  curationsCount === 0 && !isMobileDevice ? '0px' : '24px',
                maxWidth: '1100px'
              }}
            >
              {curationsCount > 0
                ? 'Finish creating your style boards'
                : "Looks like you don't have any unfinished style boards. Create a new board to share with customers."}
            </Typography>
          )}
          {isError && (
            <ErrorMessage
              errorTitle="Hmm, the section didn’t load"
              errorDetails={errorDetails}
              isInlineAlert
            />
          )}
          {handleAbandonCurationError && (
            <DeleteDraftAlert
              errorDetails={{
                errorData:
                  'Still not working? Copy error info and paste in support chat.'
              }}
            />
          )}
        </Box>
        {showButton && (
          <CTAButton
            curationsCount={curationsCount}
            createAction={setIsCreateStyleBoardModalOpen}
          />
        )}
      </Box>
      {isLoading && (
        <Box display={'flex'} mt={3}>
          {[...Array(isMobileDevice ? 1 : 3)].map((_, index) => (
            <Box width={'325px'} key={index} marginRight={'24px'}>
              <Skeleton.Image aspectRatio={2} height="180px" />
            </Box>
          ))}
        </Box>
      )}
      {isSuccess && curationsCount > 0 && (
        <>
          <Carousel displayArrows={curationsCount <= 4 ? false : true}>
            {data?.curations.map((curation, index) => (
              <Box key={`draft-${index}`}>
                <DraftCard
                  curation={curation}
                  setHandleAbandonCurationError={setHandleAbandonCurationError}
                />
              </Box>
            ))}
          </Carousel>
        </>
      )}
      {showButtonMobileView && (
        <CTAButton
          isMobileView={true}
          curationsCount={curationsCount}
          createAction={setIsCreateStyleBoardModalOpen}
        />
      )}
    </Box>
  )
}

export default Drafts
