import { Box, Button } from '@mui/material'
import { useState } from 'react'
import LoadingSpinner from 'components/LoadingSpinner'
import { useGetPolarisSearchResultsQuery } from 'services/employeeExperienceApi'
import { RecommendationDetailsT } from 'types/SeedItemRecommendations'
import PageError from 'components/PageError'
import ProductImageCarousel from 'components/ProductImageCarousel'
import { ProductGenderValues } from 'types/Checkout'
import { RECS_ERROR_TITLE } from './ItemRecommendations'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import oktaTokenStorage from 'utils/okta-token-utils'
import { AccessGroupsFeatureFlagT } from 'types/FeatureFlags'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { isFeatureEnabledForUser } from 'utils/userPermissions'
import { PriceRange } from '../RecommendationsTemplate/RecommendationsTemplate'

const FLOOR_PRICE = 100
const LOWEST_FILTER_PRICE = 50

enum PriceFactor {
  PRICE_INCREASE_FACTOR = 2,
  PRICE_DECREASE_FACTOR = 0.5
}

const FeedbackButton = ({
  buttonText,
  onClick
}: {
  buttonText: string
  onClick: () => void
}) => {
  return (
    <Button onClick={onClick} variant="outlined" size="small">
      {buttonText}
    </Button>
  )
}

const roundPriceToNearest50 = (value: number): number => {
  if (value > FLOOR_PRICE) {
    return Math.round(value / 50) * 50
  }
  return value
}

const updatePriceRange = (
  factor: PriceFactor,
  isIncreasing: boolean,
  setMinAndMax: React.Dispatch<React.SetStateAction<PriceRange>>
) => {
  setMinAndMax((prev) => {
    const newMin =
      prev.min !== undefined
        ? roundPriceToNearest50(prev.min * factor)
        : undefined
    const newMax =
      prev.max !== undefined
        ? roundPriceToNearest50(prev.max * factor)
        : undefined

    if (isIncreasing) {
      if (prev.min === undefined) {
        return {
          min: LOWEST_FILTER_PRICE,
          max: newMax
        }
      }
    } else {
      if (prev.max && prev.max <= FLOOR_PRICE) {
        return {
          min: undefined,
          max: LOWEST_FILTER_PRICE
        }
      }
    }

    return {
      min: newMin,
      max: newMax
    }
  })
}

interface PropsT {
  recommendation: RecommendationDetailsT
  onClickForMiniPDP: ({
    webStyleId,
    miniPDPOpenedFrom,
    imgUrl
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    imgUrl: string
  }) => void
  curationId: number
  productGender?: ProductGenderValues
  searchTerm: string
}

const CategoryCarousel = ({
  recommendation,
  onClickForMiniPDP,
  curationId,
  productGender,
  searchTerm
}: PropsT): JSX.Element => {
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  const { isPriceRangeFeedbackEnabled } = useFeatureFlags()
  const feedbackAccessGroupsPayload =
    isPriceRangeFeedbackEnabled?.payload as AccessGroupsFeatureFlagT
  const feedbackEnabled = isFeatureEnabledForUser(feedbackAccessGroupsPayload)
  const { priceRange: { min, max } = {} } = recommendation
  const [minAndMax, setMinAndMax] = useState<PriceRange>({ min, max })

  const increasePriceRange = () => {
    updatePriceRange(PriceFactor.PRICE_INCREASE_FACTOR, true, setMinAndMax)
    const attributes = {
      curationId,
      employeeId,
      feedbackType: 'increase price',
      searchTerm
    }
    generateNewRelicLogs('priceRangeFeedbackClick', attributes)
  }

  const decreasePriceRange = () => {
    updatePriceRange(PriceFactor.PRICE_DECREASE_FACTOR, false, setMinAndMax)
    const attributes = {
      curationId,
      employeeId,
      feedbackType: 'decrease price',
      searchTerm
    }
    generateNewRelicLogs('priceRangeFeedbackClick', attributes)
  }

  const { data, endpointName, error, isLoading, isFetching, isError, refetch } =
    useGetPolarisSearchResultsQuery({
      curationId,
      searchTerm,
      minPrice: minAndMax.min,
      maxPrice: minAndMax.max,
      productGender
    })

  if (isError) {
    return (
      <PageError
        errorDetails={{ endpoint: endpointName, errorData: error }}
        errorTitle={RECS_ERROR_TITLE}
        refreshAction={refetch}
      />
    )
  }

  if (!data || isLoading || isFetching) {
    return <LoadingSpinner hideText={true} />
  }

  return (
    <>
      <ProductImageCarousel
        data={data}
        onClickForMiniPDP={onClickForMiniPDP}
        miniPDPOpenedFrom={'SeedItemRecommendations'}
      />
      {feedbackEnabled && (
        <Box mt={2}>
          <Box display="flex" gap={1} mt={1}>
            {(minAndMax.max || 0) >= FLOOR_PRICE && (
              <FeedbackButton
                buttonText={'Less expensive'}
                onClick={decreasePriceRange}
              />
            )}
            <FeedbackButton
              buttonText={'More expensive'}
              onClick={increasePriceRange}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

export default CategoryCarousel
