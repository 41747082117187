import { Card, CardHeader, Checkbox, IconButton } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { formatFollowUpText } from './formatText'
import { ExtendedFollowUpT } from 'types/FollowUps'

type PropsT = {
  followUp: ExtendedFollowUpT
  // TODO: NON-2077 will give a value to this and the chevron will be displayed.
  // No need to display it for now since connections do not have an action when clicked.
  onClick?: () => void
}

const FollowUpCard = ({ followUp, onClick }: PropsT): JSX.Element => {
  return (
    <Card
      variant="outlined"
      onClick={onClick}
      sx={{
        width: 1,
        cursor: onClick ? 'pointer' : 'default'
      }}
    >
      <CardHeader
        avatar={<Checkbox />}
        title={formatFollowUpText(followUp.followUpKind)}
        action={
          onClick ? (
            <IconButton>
              <ChevronRightIcon />
            </IconButton>
          ) : undefined
        }
        sx={{
          '& .MuiCardHeader-action': {
            alignSelf: 'center'
          }
        }}
      />
    </Card>
  )
}

export default FollowUpCard
