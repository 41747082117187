import { Dialog, DialogContent, DialogActions, Button } from '@mui/material'

type PropsT = {
  open: boolean
  onClose: () => void
  dialogText: string
}

const AdditionalDetails = ({
  open,
  onClose,
  dialogText
}: PropsT): JSX.Element => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        data-testid="modal-content"
        sx={{ color: 'text.secondary' }}
      >
        {dialogText}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} data-testid="modal-closed">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AdditionalDetails
