import { ReactElement } from 'react'
import { Container, Box, Alert, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import LaunchIcon from '@mui/icons-material/Launch'
import { BeautyRestockOrderItemT } from 'types/Orders'

const BeautyRestockAlert = (props: {
  onClick: () => void
  isBeautyRestocksEnabled: boolean
  beautyPurchaseHistoryData?: Array<BeautyRestockOrderItemT>
  onDismissed: () => void
}): ReactElement => {
  const {
    isBeautyRestocksEnabled,
    onClick,
    beautyPurchaseHistoryData,
    onDismissed
  } = props

  if (
    isBeautyRestocksEnabled &&
    beautyPurchaseHistoryData &&
    beautyPurchaseHistoryData?.length > 0
  ) {
    return (
      <Container disableGutters>
        <Box sx={{ width: '100%' }} mb={2}>
          <Alert
            severity={'info'}
            icon={false}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  onDismissed && onDismissed()
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
              <IconButton color="info" onClick={onClick}>
                <LaunchIcon />
              </IconButton>
              Customer placed a beauty order over two months ago. Check for
              restocks!
            </Box>
          </Alert>
        </Box>
      </Container>
    )
  }
  return <></>
}

export default BeautyRestockAlert
