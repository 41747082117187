import { ReactNode, useEffect } from 'react'
import { getIsUserSubscribed } from 'utils/service-worker-utils'

const LocalStorageContext = ({
  children
}: {
  children: ReactNode
}): JSX.Element => {
  useEffect(() => {
    const setIsUserSubscribedLocalStorage = async () => {
      const isUserSubscribed = await getIsUserSubscribed()
      localStorage.setItem(
        'isSubscribedToPushNotifications',
        `${isUserSubscribed}`
      )
    }

    setIsUserSubscribedLocalStorage()
  }, [])

  return <>{children}</>
}

export default LocalStorageContext
