import styled from 'styled-components'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconButton, useTheme } from '@mui/material'
import type { IconButtonProps } from '@mui/material'

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps): JSX.Element => {
  return <IconButton {...props} />
})<ExpandMoreProps>(({ theme, expand }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  }),
  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)'
}))

interface PropsT {
  expanded: boolean
  handleExpandClick: () => void
}

const ExpandIconButton = ({
  expanded,
  handleExpandClick
}: PropsT): JSX.Element => {
  const theme = useTheme()
  return (
    <ExpandMore
      theme={theme}
      expand={expanded}
      onClick={handleExpandClick}
      aria-expanded={expanded}
      aria-label="show more"
      sx={{
        width: 50
      }}
    >
      <ExpandMoreIcon />
    </ExpandMore>
  )
}

export default ExpandIconButton
