import React from 'react'
import Skeleton from 'components/Skeleton'
import { Box, Typography, Grid } from '@mui/material'
import { isPersonalStylist } from 'utils/userPermissions'
import RequestCard from '../RequestCard/RequestCard'
import {
  useGetEditRequestsInQueueQuery,
  useGetCurationsQuery
} from 'services/curationSvc'
import { useGetCurationsInQueueQuery } from 'services/employeeExperienceApi'
import EditIcon from '@mui/icons-material/Edit'
import GroupIcon from '@mui/icons-material/Group'
import FlareIcon from '@mui/icons-material/Flare'
import oktaTokenStorage from 'utils/okta-token-utils'
import { CURATION_STATE } from 'types/Curation'
import { RequestCardType, QueueType } from 'types/RequestCardTypes'
import ErrorMessage from '../ErrorMessage'

const QueueSection: React.FC = () => {
  const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
  const {
    endpointName: editRequestEndPoint,
    data: editRequests,
    error: editRequestError,
    isLoading: editRequestLoading
  } = useGetEditRequestsInQueueQuery()
  const {
    endpointName: getCurationsEndPoint,
    data: generalRequests,
    error: generalRequestError,
    isLoading: generalRequestLoading
  } = useGetCurationsInQueueQuery('ALL', {
    refetchOnMountOrArgChange: true
  })
  const {
    endpointName: preferredRequestsEndPoint,
    data: preferredRequests,
    error: preferredRequestsError,
    isLoading: preferredRequestsLoading
  } = useGetCurationsQuery({
    state: CURATION_STATE.REQUESTED,
    preferredEmployeeId: employeeId,
    perPage: 0,
    page: 1
  })

  const {
    endpointName: inProgressCurationsEndPoint,
    data: inProgressCurations,
    error: inProgressCurationsError,
    isLoading: inProgressCurationsLoading
  } = useGetCurationsQuery({
    state: CURATION_STATE.IN_PROGRESS,
    employeeId: employeeId,
    preferredEmployeeId: employeeId
  })

  const showAlertError =
    editRequestError ||
    generalRequestError ||
    preferredRequestsError ||
    inProgressCurationsError

  const showSkeletonCards =
    editRequestLoading ||
    generalRequestLoading ||
    preferredRequestsLoading ||
    inProgressCurationsLoading

  // Since page error only can handle one error we filter the first error found
  const errorDetails = [
    editRequestError && {
      endpoint: editRequestEndPoint,
      errorData: editRequestError
    },
    generalRequestError && {
      endpoint: getCurationsEndPoint,
      errorData: generalRequestError
    },
    preferredRequestsError && {
      endpoint: preferredRequestsEndPoint,
      errorData: preferredRequestsError
    },
    inProgressCurationsError && {
      endpoint: inProgressCurationsEndPoint,
      errorData: inProgressCurationsError
    }
  ].find(Boolean)

  const editRequestsCount = editRequests?.meta.count || 0
  const customerRequestsCount =
    (inProgressCurations?.meta.count || 0) +
    (preferredRequests?.meta.count || 0)
  const generalRequestsCount = generalRequests?.meta.count || 0

  const cards: Array<RequestCardType> = [
    {
      count: editRequestsCount,
      title: `edit request${editRequestsCount !== 1 ? 's' : ''}`,
      link: '/stylist/edit-requests',
      testId: 'edit-requests-view-all',
      colorIcon: editRequestsCount !== 0 ? '#e6f7ed' : '#eeeeee',
      buttonText: 'View All',
      queueType: QueueType.EDIT,
      renderIcon: () => (
        <EditIcon
          sx={{
            fill: editRequestsCount !== 0 ? '#0E8042' : '#757575',
            fontSize: 18
          }}
        />
      ),
      visible: true
    },
    {
      count: customerRequestsCount,
      title: `customer request${customerRequestsCount !== 1 ? 's' : ''}`,
      link: '/stylist/requests',
      testId: 'personal-requests-view-all',
      colorIcon: customerRequestsCount !== 0 ? '#fef6e0' : '#eeeeee',
      buttonText: 'View All',
      queueType: QueueType.PREFERRED_EMPLOYEE,
      renderIcon: () => (
        <FlareIcon
          sx={{
            fill: customerRequestsCount !== 0 ? '#FAAF00' : '#757575',
            fontSize: 18
          }}
        />
      ),
      visible: true
    },
    {
      count: generalRequestsCount,
      title: `general request${generalRequestsCount !== 1 ? 's' : ''}`,
      link: '/review?startCuration=true&occasionGroup=ALL',
      testId: 'general-requests-view-all',
      colorIcon: generalRequestsCount !== 0 ? '#f0edfe' : '#eeeeee',
      buttonText: 'Start',
      queueType: QueueType.GENERAL,
      renderIcon: () => (
        <GroupIcon
          sx={{
            fill: generalRequestsCount !== 0 ? '#482FE9' : '#757575',
            fontSize: 18
          }}
        />
      ),
      visible: isPersonalStylist() ? false : true
    }
  ]

  return (
    <Box px={2} mt={3}>
      <Typography variant="h6">Requests</Typography>
      {!showAlertError && (
        <Typography variant="body2">Respond to style board requests</Typography>
      )}
      {showAlertError && (
        <ErrorMessage
          errorTitle="Hmm, the section didn’t load"
          errorDetails={errorDetails}
          isInlineAlert
        />
      )}
      {!showAlertError && (
        <Grid container spacing={2} mt={2}>
          {cards.map((card: RequestCardType, index) => (
            <Grid
              item
              key={index}
              xs={index === cards.length - 1 && cards.length % 2 !== 0 ? 12 : 6}
              md={cards.length % 2 !== 0 ? 4 : 6}
              display="flex"
              flexDirection="column"
            >
              <Box
                flexGrow={1}
                display="flex"
                flexDirection="column"
                alignItems="stretch"
              >
                {showSkeletonCards ? (
                  <Skeleton.Image
                    aspectRatio={
                      index === cards.length - 1 && cards.length % 2 !== 0
                        ? 6
                        : 4
                    }
                    height={'118px'}
                  />
                ) : (
                  <RequestCard {...card} />
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  )
}

export default QueueSection
