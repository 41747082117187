import partition from 'lodash/partition'
import {
  ConsentedCustomerListItemT,
  ExtendedConsentedCustomerListItemT
} from 'types/ConsentedCustomer'
import {
  CriteriaKeys,
  criteriaToSectionTitle,
  CUSTOMER_SORT_CRITERIA_KEYS,
  CUSTOMER_SORT_EMPTY_SECTION_TITLES,
  CUSTOMER_SORT_OPTIONS,
  CustomerSortKeysT,
  sortFunctionToCriteria
} from '../constants'
import {
  addLastPurchaseDateByCustomerId,
  addLastPurchaseDateWithEmployeeByCustomerId,
  addTripsCountsByCustomerIds,
  DataForFilteringAndSorting
} from './customers'
import { getConversationsWithCustomerIds } from 'services/twilioSlice/utils'

type SortFunction = (
  customers: ExtendedConsentedCustomerListItemT[]
) => ExtendedConsentedCustomerListItemT[]

interface SortedCustomers {
  default: ExtendedConsentedCustomerListItemT[]
  [key: string]: ExtendedConsentedCustomerListItemT[]
}

export interface FeatureFlagsForSorting {
  isCustomerListTripsSortEnabledActive: boolean
  isNordstromPurchaseSortEnabledActive: boolean
  isCustomerListLastPurchaseWithEmployeeSortEnabledActive: boolean
}

type CustomerDataExtenderFunction = (
  customers: ConsentedCustomerListItemT[],
  sortData: DataForFilteringAndSorting
) => ExtendedConsentedCustomerListItemT[]

const safeDate = (dateString: string | undefined): Date =>
  dateString ? new Date(dateString) : new Date(0)

const safeNumber = (value: number | undefined): number =>
  value !== undefined ? value : 0

const compareStrings = (a: string, b: string): number =>
  a.localeCompare(b, undefined, { sensitivity: 'base' })

const getAlphabeticalValue = (customer: ExtendedConsentedCustomerListItemT) =>
  customer.firstName && customer.lastName
    ? `${customer.firstName} ${customer.lastName}`.toLowerCase()
    : customer.email

const sortCustomers = <T>(
  customers: ExtendedConsentedCustomerListItemT[],
  getValue: (customer: ExtendedConsentedCustomerListItemT) => T,
  comparator: (a: T, b: T) => number
): ExtendedConsentedCustomerListItemT[] => {
  return [...customers].sort((a, b) => {
    const sortedByCriteria = comparator(getValue(a), getValue(b))
    if (sortedByCriteria !== 0) return sortedByCriteria
    return compareStrings(getAlphabeticalValue(a), getAlphabeticalValue(b))
  })
}

const byAlphabetical = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] =>
  sortCustomers(
    customers,
    (customer) => getAlphabeticalValue(customer),
    compareStrings
  )

const byNewestMessages = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] =>
  sortCustomers(
    customers,
    (customer) => safeDate(customer.lastMessage),
    (a, b) => b.getTime() - a.getTime()
  )

const byOldestMessages = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] =>
  sortCustomers(
    customers,
    (customer) => safeDate(customer.lastMessage),
    (a, b) => a.getTime() - b.getTime()
  )

const byMostTrips = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] =>
  sortCustomers(
    customers,
    (customer) => safeNumber(customer.tripsCount),
    (a, b) => b - a
  )

const byFewestTrips = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] =>
  sortCustomers(
    customers,
    (customer) => safeNumber(customer.tripsCount),
    (a, b) => a - b
  )

const byMostRecentPurchase = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] =>
  sortCustomers(
    customers,
    (customer) => safeDate(customer.lastPurchaseDate),
    (a, b) => b.getTime() - a.getTime()
  )

const byLeastRecentPurchase = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] =>
  sortCustomers(
    customers,
    (customer) => safeDate(customer.lastPurchaseDate),
    (a, b) => a.getTime() - b.getTime()
  )

const byMostRecentPurchaseWithEmployee = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] =>
  sortCustomers(
    customers,
    (customer) => safeDate(customer.lastPurchaseDateWithEmployee),
    (a, b) => b.getTime() - a.getTime()
  )

const byLeastRecentPurchaseWithEmployee = (
  customers: ExtendedConsentedCustomerListItemT[]
): ExtendedConsentedCustomerListItemT[] =>
  sortCustomers(
    customers,
    (customer) => safeDate(customer.lastPurchaseDateWithEmployee),
    (a, b) => a.getTime() - b.getTime()
  )

const sortFunctionMap: Record<CustomerSortKeysT, SortFunction> = {
  [CUSTOMER_SORT_OPTIONS.BY_ALPHABETICAL]: byAlphabetical,
  [CUSTOMER_SORT_OPTIONS.BY_OLDEST_MESSAGES]: byOldestMessages,
  [CUSTOMER_SORT_OPTIONS.BY_NEWEST_MESSAGES]: byNewestMessages,
  [CUSTOMER_SORT_OPTIONS.BY_MOST_TRIPS]: byMostTrips,
  [CUSTOMER_SORT_OPTIONS.BY_FEWEST_TRIPS]: byFewestTrips,
  [CUSTOMER_SORT_OPTIONS.BY_MOST_RECENT_PURCHASE]: byMostRecentPurchase,
  [CUSTOMER_SORT_OPTIONS.BY_LEAST_RECENT_PURCHASE]: byLeastRecentPurchase,
  [CUSTOMER_SORT_OPTIONS.BY_MOST_RECENT_PURCHASE_WITH_EMPLOYEE]:
    byMostRecentPurchaseWithEmployee,
  [CUSTOMER_SORT_OPTIONS.BY_LEAST_RECENT_PURCHASE_WITH_EMPLOYEE]:
    byLeastRecentPurchaseWithEmployee
}

const getSortCriteria = (
  criteriaKey: CriteriaKeys
): keyof ExtendedConsentedCustomerListItemT => {
  return CUSTOMER_SORT_CRITERIA_KEYS[criteriaKey]
}

const getSectionTitle = (criteriaKey: CriteriaKeys): string =>
  criteriaToSectionTitle[criteriaKey as CriteriaKeys] ||
  CUSTOMER_SORT_EMPTY_SECTION_TITLES.DEFAULT

const getSortFunction = (sortFunctionKey: CustomerSortKeysT): SortFunction =>
  sortFunctionMap[sortFunctionKey] || byAlphabetical

export const mapCustomerListToSortCriteria = (
  customerList: ExtendedConsentedCustomerListItemT[],
  sortFunctionKey: CustomerSortKeysT
): SortedCustomers => {
  const criteriaKey = sortFunctionToCriteria[sortFunctionKey]
  const criteria = getSortCriteria(criteriaKey)
  const [customersWithCriteria, customersWithoutCriteria] = partition(
    customerList,
    (customer) => !!customer[criteria]
  )

  const sortFunction = getSortFunction(sortFunctionKey)
  const noCriteriaSectionTitle = getSectionTitle(criteriaKey)

  return {
    default: sortFunction(customersWithCriteria),
    [noCriteriaSectionTitle]: byAlphabetical(customersWithoutCriteria)
  }
}

export const isAlphabeticalSort = (
  selectedSortType: CustomerSortKeysT
): boolean => selectedSortType === CUSTOMER_SORT_OPTIONS.BY_ALPHABETICAL

export const isLastMessageSort = (
  selectedSortType: CustomerSortKeysT
): boolean =>
  !!(
    selectedSortType === CUSTOMER_SORT_OPTIONS.BY_NEWEST_MESSAGES ||
    selectedSortType === CUSTOMER_SORT_OPTIONS.BY_OLDEST_MESSAGES
  )
export const isTripsCountSort = (
  selectedSortType: CustomerSortKeysT
): boolean =>
  !!(
    selectedSortType === CUSTOMER_SORT_OPTIONS.BY_MOST_TRIPS ||
    selectedSortType === CUSTOMER_SORT_OPTIONS.BY_FEWEST_TRIPS
  )

export const isPurchaseDateSort = (
  selectedSortType: CustomerSortKeysT
): boolean =>
  !!(
    selectedSortType === CUSTOMER_SORT_OPTIONS.BY_MOST_RECENT_PURCHASE ||
    selectedSortType === CUSTOMER_SORT_OPTIONS.BY_LEAST_RECENT_PURCHASE
  )

export const isPurchaseWithEmployeeDateSort = (
  selectedSortType: CustomerSortKeysT
): boolean =>
  !!(
    selectedSortType ===
      CUSTOMER_SORT_OPTIONS.BY_MOST_RECENT_PURCHASE_WITH_EMPLOYEE ||
    selectedSortType ===
      CUSTOMER_SORT_OPTIONS.BY_LEAST_RECENT_PURCHASE_WITH_EMPLOYEE
  )

export const isExtendedCustomerSortSelected = (
  selectedSortType: CustomerSortKeysT
): boolean =>
  isTripsCountSort(selectedSortType) ||
  isPurchaseDateSort(selectedSortType) ||
  isPurchaseWithEmployeeDateSort(selectedSortType)

const customerDataExtenders: Record<
  CustomerSortKeysT,
  CustomerDataExtenderFunction
> = {
  [CUSTOMER_SORT_OPTIONS.BY_ALPHABETICAL]: (customers) => customers, // No additional sorting needed
  [CUSTOMER_SORT_OPTIONS.BY_OLDEST_MESSAGES]: (
    customers: ExtendedConsentedCustomerListItemT[],
    { conversations }
  ) => getConversationsWithCustomerIds(customers, conversations),
  [CUSTOMER_SORT_OPTIONS.BY_NEWEST_MESSAGES]: (
    customers: ExtendedConsentedCustomerListItemT[],
    { conversations }
  ) => getConversationsWithCustomerIds(customers, conversations),
  [CUSTOMER_SORT_OPTIONS.BY_MOST_TRIPS]: (
    customers: ExtendedConsentedCustomerListItemT[],
    { tripsCount }
  ) =>
    tripsCount ? addTripsCountsByCustomerIds(customers, tripsCount) : customers,
  [CUSTOMER_SORT_OPTIONS.BY_FEWEST_TRIPS]: (
    customers: ExtendedConsentedCustomerListItemT[],
    { tripsCount }
  ) =>
    tripsCount ? addTripsCountsByCustomerIds(customers, tripsCount) : customers,
  [CUSTOMER_SORT_OPTIONS.BY_MOST_RECENT_PURCHASE]: (
    customers: ExtendedConsentedCustomerListItemT[],
    { lastPurchaseDate }
  ) =>
    lastPurchaseDate
      ? addLastPurchaseDateByCustomerId(customers, lastPurchaseDate)
      : customers,
  [CUSTOMER_SORT_OPTIONS.BY_LEAST_RECENT_PURCHASE]: (
    customers: ExtendedConsentedCustomerListItemT[],
    { lastPurchaseDate }
  ) =>
    lastPurchaseDate
      ? addLastPurchaseDateByCustomerId(customers, lastPurchaseDate)
      : customers,
  [CUSTOMER_SORT_OPTIONS.BY_MOST_RECENT_PURCHASE_WITH_EMPLOYEE]: (
    customers: ExtendedConsentedCustomerListItemT[],
    { lastPurchaseDateWithEmployee }
  ) =>
    lastPurchaseDateWithEmployee
      ? addLastPurchaseDateWithEmployeeByCustomerId(
          customers,
          lastPurchaseDateWithEmployee
        )
      : customers,
  [CUSTOMER_SORT_OPTIONS.BY_LEAST_RECENT_PURCHASE_WITH_EMPLOYEE]: (
    customers: ExtendedConsentedCustomerListItemT[],
    { lastPurchaseDateWithEmployee }
  ) =>
    lastPurchaseDateWithEmployee
      ? addLastPurchaseDateWithEmployeeByCustomerId(
          customers,
          lastPurchaseDateWithEmployee
        )
      : customers
}

// Util function to be removed or modified when feature flags are no longer needed
const isSortTypeEnabled = (
  sortType: CustomerSortKeysT,
  featureFlags: FeatureFlagsForSorting
): boolean => {
  switch (sortType) {
    case CUSTOMER_SORT_OPTIONS.BY_MOST_TRIPS:
    case CUSTOMER_SORT_OPTIONS.BY_FEWEST_TRIPS:
      return featureFlags.isCustomerListTripsSortEnabledActive
    case CUSTOMER_SORT_OPTIONS.BY_MOST_RECENT_PURCHASE:
    case CUSTOMER_SORT_OPTIONS.BY_LEAST_RECENT_PURCHASE:
      return featureFlags.isNordstromPurchaseSortEnabledActive
    case CUSTOMER_SORT_OPTIONS.BY_MOST_RECENT_PURCHASE_WITH_EMPLOYEE:
    case CUSTOMER_SORT_OPTIONS.BY_LEAST_RECENT_PURCHASE_WITH_EMPLOYEE:
      return featureFlags.isCustomerListLastPurchaseWithEmployeeSortEnabledActive
    default:
      return true // All other sort types are always enabled
  }
}

export const extendCustomersForSorting = (
  customers: ConsentedCustomerListItemT[],
  selectedSortType: CustomerSortKeysT,
  sortData: DataForFilteringAndSorting,
  featureFlags: FeatureFlagsForSorting
): ExtendedConsentedCustomerListItemT[] => {
  if (!isSortTypeEnabled(selectedSortType, featureFlags)) {
    return customers
  }

  const extenderFunction = customerDataExtenders[selectedSortType]
  return extenderFunction(customers, sortData)
}
