import SkuPrice from './SkuPrice'
import StylePrice from './StylePrice'
import { SkuDetailsT, StylePriceT } from 'types/Product'

interface PropsT {
  isPublicBoard: boolean
  selectedSku?: SkuDetailsT
  stylePrice: StylePriceT
  isStyleSoldOut: boolean
}

const ProductPrice = (props: PropsT): JSX.Element => {
  const { isPublicBoard, selectedSku, stylePrice, isStyleSoldOut } = props

  if (selectedSku && !isPublicBoard) {
    return <SkuPrice selectedSku={selectedSku} />
  }

  return <StylePrice stylePrice={stylePrice} isStyleSoldOut={isStyleSoldOut} />
}

export default ProductPrice
