export const CONSENTED_CUSTOMER_TRIPS_TITLE =
  'Trips with you (within 12 months)'

export const PURCHASES_WITH_YOU_DISCLAIMER_DETAILS =
  'Includes up to 50 shipped orders purchased with you since October 2023. Marketplace items will not display.'

export const CONSENTED_CUSTOMER_TRIPS_ADDITIONAL_DETAILS =
  'Unaudited trips with you (within 12 months) for this customer profile. Orders containing only marketplace items will not display in this view.'

export const colors = {
  PRIMARY: '#1F2122',
  SECONDARY: '#6F7780'
} as const
