import React, { ReactElement, useState } from 'react'
import { Grid, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'
import CheckIcon from '@mui/icons-material/Check'

import { useGetFormDataQuery } from 'services/curationSvc'
import { formatSizesAndBudgetRange } from '../../../../utils/formatRequestAttributes'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import LoadingAttributes from './LoadingAttributes'
import CustomerNotes from './CustomerNotes'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { CurationT } from 'types/Curation'

const AttributesError = () => (
  <Typography variant="body2" color="error" align="center" width="100%">
    Error getting request details
  </Typography>
)

export type PropsT = {
  curation: {
    isError: boolean
    isLoading: boolean
    data?: {
      curation: CurationT
    }
  }
}

const RequestDetails = (props: PropsT): ReactElement => {
  const { curation } = props
  const { data, isLoading, isError } = curation
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const isMobileDevice = checkBrowserClient.isMobile()
  const {
    isBudgetQuestionsEnabledPesonalBook,
    isTrendToleranceEnabledPersonalBook,
    isBeautyRALEnabledPersonalBook,
    isGiftGivingRALEnabledPersonalBook,
    isBopusStoreEnabledPB,
    isGiftRalEnabledPb // Feature flag for the gift department feature
  } = useFeatureFlags()

  const { data: formData, isSuccess: isFormDataSuccess } = useGetFormDataQuery()
  const {
    trendTolerance: formDataTrendTolerance,
    budgetTolerance: formDataBudgetTolerance
  } = formData || {}

  if (isLoading) return <LoadingAttributes />

  if (isError) return <AttributesError />

  if (!data || !data.curation) return <></>

  const {
    customerNote,
    departments,
    occasions,
    sizes,
    budgetRange,
    budgetTolerance,
    trendTolerance,
    beautyNote,
    includeBeauty,
    includeGifting,
    giftNote,
    bopusStore
  } = data.curation

  const isGenderInclusiveDepartment =
    departments?.includes('womenswear') && departments?.includes('menswear')
  const department = isGenderInclusiveDepartment
    ? 'Gender-inclusive'
    : departments && departments[0]

  const transformDepartmentText =
    (!isGenderInclusiveDepartment && 'capitalize') || undefined

  const formattedSizes = sizes ? formatSizesAndBudgetRange(sizes) : null

  const formattedBudgets = budgetRange
    ? formatSizesAndBudgetRange(budgetRange)
    : null

  const showTrendTolerance = () => {
    return isTrendToleranceEnabledPersonalBook?.active && isFormDataSuccess
  }

  const showIncludeBeauty = () => {
    return isBeautyRALEnabledPersonalBook?.active && includeBeauty
  }

  const showIncludeGiftGiving = () => {
    return isGiftGivingRALEnabledPersonalBook?.active && includeGifting
  }

  const showIncludeBopus = () => {
    return isBopusStoreEnabledPB?.active && !!bopusStore
  }

  const noteGridMarginTop =
    showIncludeBeauty() || showIncludeGiftGiving() || showIncludeBopus() ? 3 : 0

  const showGiftDepartment =
    department === 'gifts' && isGiftRalEnabledPb?.active

  return (
    <Grid container width={1}>
      <Grid item width={1 / 2}>
        <Typography fontWeight="bold" variant="body2" gutterBottom>
          Department
        </Typography>
        <Typography
          variant="body2"
          textTransform={transformDepartmentText}
          gutterBottom
        >
          {department}
        </Typography>
      </Grid>

      {showGiftDepartment ? (
        <Grid item width={1} mt={noteGridMarginTop}>
          <CustomerNotes customerNote={customerNote} title="Notes" />
        </Grid>
      ) : (
        <>
          <Grid item width={1 / 2} pl={1} mb={3}>
            <Typography variant="body2" fontWeight="bold" gutterBottom>
              Occasion
            </Typography>
            {occasions?.map((occasion, index) => (
              <Typography
                key={index}
                variant="body2"
                textTransform="capitalize"
                gutterBottom
              >
                {occasion}
              </Typography>
            ))}
          </Grid>

          {trendTolerance && showTrendTolerance() && (
            <Grid item width={1} mb={3}>
              <Typography
                variant="body2"
                fontWeight="bold"
                gutterBottom
                width={1 / 2}
              >
                Trend Tolerance
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                style={{ display: 'flex' }}
              >
                {formDataTrendTolerance?.[trendTolerance]?.title}
                <Tooltip
                  title={
                    <div
                      style={{
                        lineHeight: '16px'
                      }}
                    >
                      {formDataTrendTolerance?.[trendTolerance].description}
                    </div>
                  }
                  arrow
                  {...(isMobileDevice
                    ? {
                        open: isTooltipOpen,
                        onClose: () => setIsTooltipOpen(false),
                        onClick: () => setIsTooltipOpen(!isTooltipOpen)
                      }
                    : {})}
                >
                  <InfoOutlinedIcon
                    style={{
                      color: '#006BE2',
                      marginLeft: '4px',
                      height: '20px'
                    }}
                  />
                </Tooltip>
              </Typography>
            </Grid>
          )}
          <Grid item width={1 / 2} mb={3}>
            <Typography variant="body2" fontWeight="bold" gutterBottom>
              Sizes
            </Typography>
            {formattedSizes?.map((size, index) => (
              <div key={index}>
                <Typography
                  variant="body2"
                  display="inline"
                  textTransform="capitalize"
                >
                  {`${size.key}: `}
                </Typography>
                {size?.value &&
                  Array.isArray(size.value) &&
                  size.value.map((val, index) => (
                    <Typography variant="body2" display="inline" key={index}>
                      {index > 0 ? ', ' : ''}
                      {val}
                    </Typography>
                  ))}
              </div>
            ))}
            {isGenderInclusiveDepartment && (
              <Typography color="text.secondary" variant="subtitle1">
                (Based on women&apos;s sizing)
              </Typography>
            )}
          </Grid>

          {isBudgetQuestionsEnabledPesonalBook?.active &&
            (budgetTolerance || budgetRange) && (
              <Grid item width={1} mb={3}>
                <Typography variant="body2" fontWeight="bold" gutterBottom>
                  Budget
                </Typography>
                {budgetTolerance && (
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    color="text.secondary"
                    gutterBottom
                  >
                    {formDataBudgetTolerance?.[budgetTolerance].title}
                  </Typography>
                )}
                {formattedBudgets?.map(
                  (budget, index) =>
                    budget?.value && (
                      <div key={index}>
                        <Typography
                          variant="body2"
                          display="inline"
                          textTransform="capitalize"
                        >
                          {`${budget.key}: `}
                        </Typography>
                        {Array.isArray(budget.value) ? (
                          budget.value.map((val, index) => (
                            <Typography
                              variant="body2"
                              display="inline"
                              key={index}
                            >
                              {index > 0 ? ', ' : ''}
                              up to ${val}
                            </Typography>
                          ))
                        ) : (
                          <Typography variant="body2" display="inline">
                            up to ${budget.value}
                          </Typography>
                        )}
                      </div>
                    )
                )}
              </Grid>
            )}

          {showIncludeBeauty() && (
            <Grid item width={1}>
              <Typography variant="body2" fontWeight="bold" gutterBottom>
                Include Beauty
                <CheckIcon sx={{ paddingLeft: 1, paddingTop: 1 }} />
              </Typography>
              {beautyNote && (
                <Grid item width={1}>
                  <CustomerNotes customerNote={beautyNote} />
                </Grid>
              )}
            </Grid>
          )}

          {showIncludeGiftGiving() && (
            <Grid item width={1}>
              <Typography variant="body2" fontWeight="bold" gutterBottom>
                Include gift inspo?
                <CheckIcon sx={{ paddingLeft: 1, paddingTop: 1 }} />
              </Typography>
              {giftNote && (
                <Grid item width={1}>
                  <CustomerNotes customerNote={giftNote} />
                </Grid>
              )}
            </Grid>
          )}

          {showIncludeBopus() && bopusStore && (
            <Grid item width={1}>
              <Typography variant="body2" fontWeight="bold" gutterBottom>
                Include items for pickup?
                <CheckIcon sx={{ paddingLeft: 1, paddingTop: 1 }} />
              </Typography>
              <Typography variant="body2" gutterBottom>
                Zipcode: {bopusStore.zipCode}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Store: {bopusStore.name}
              </Typography>
            </Grid>
          )}

          <Grid item width={1} mt={noteGridMarginTop}>
            <CustomerNotes customerNote={customerNote} title="Notes" />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default React.memo(RequestDetails)
