import { PriceDetailsT, ProductGenderValues } from './Checkout'
import { EnticementDetailsT, ServerEditItemsProductT } from './Product'
import { EditRequestT } from './EditRequest'
import type { UniqueIdentifier } from '@dnd-kit/core'
import { RecommendationsT } from './SeedItemRecommendations'
import { ProductSourceType } from 'utils/determineProductSourceHelper'

export type CurationT = {
  completedAt: Date
  createdAt: Date
  startedAt: Date
  customerNote?: string
  departments?: Array<string>
  description?: string
  employeeId?: string
  id: number
  occasions?: Array<string>
  ocpId: string | null
  products: Array<OfferProductT>
  shoppingSessionId?: string
  sizes?: SizesT
  budgetRange?: BudgetRangeT | null
  budgetTolerance?: BUDGET_TOLERANCE | null
  trendTolerance?: TREND_TOLERANCE | null
  state: CURATION_STATE
  styleFashionPillar?: string
  title: string
  type: CURATION_TYPE
  updatedAt: Date
  preferredEmployeeId: string | null
  hasLapsedPreferredEmployeeRequest: boolean
  CurationProducts?: CurationProductT[]
  includeBeauty: boolean | null
  beautyNote: string | null
  includeGifting: boolean | null
  giftNote: string | null
  views: number | undefined
  orders: number | undefined
  editRequests: Array<EditRequestT>
  bopusStore: BopusStoreT | null
}

export type EditRequestCurationDetailsT = {
  createdAt: Date
  curationId: number
  curationOccasions: Array<string>
  editRequestId: number
  ocpId: string
}

export type FormDataT = {
  trendTolerance: {
    [key in TREND_TOLERANCE]: {
      title: string
      description: string
    }
  }
  budgetTolerance: {
    [key in BUDGET_TOLERANCE]: {
      title: string
      description: string
    }
  }
}

export type CurationProductT = {
  id: number
  curationId: number
  rmsSku: string
  note: string
  source: ProductSourceType
}

export type OfferProductT = {
  name?: string
  imageUrl?: string
}

export type SizesT = Record<string, Array<string | number>>

// TODO: We want to decide definitively if we will receive an array or string and clean up accordingly
export type BudgetRangeT = Record<string, Array<string> | string>

export enum CURATION_STATE {
  ABANDONED = 'abandoned',
  DRAFT = 'draft',
  IN_PROGRESS = 'in_progress',
  PUBLISHED = 'published',
  REQUESTED = 'requested'
}

export enum CURATION_TYPE {
  SELLER_INITIATED = 'SellerInitiated',
  CUSTOMER_INITIATED = 'CustomerInitiated'
}

export enum BUDGET_TOLERANCE {
  FLEXIBLE = 'flexible',
  NOT_FLEXIBLE = 'not_flexible'
}

export enum TREND_TOLERANCE {
  EXTREMELY_TRENDY = 'extremely_trendy',
  LEANS_TRENDY = 'leans_trendy',
  BALANCE_OF_BOTH = 'balance_of_both',
  LEANS_TIMELESS = 'leans_timeless',
  EXTREMELY_TIMELESS = 'extremely_timeless'
}

export enum PUBLISHED_CURATION_TYPE {
  CUSTOMER = 'customer',
  PUBLIC = 'public'
}

export enum CURATION_ORDERING {
  CREATED_DESC = 'createdDesc',
  UPDATED_DESC = 'updatedDesc',
  COMPLETED_DESC = 'completedDesc',
  CREATED_ASC = 'createdAsc',
  UPDATED_ASC = 'updatedAsc',
  COMPLETED_ASC = 'completedAsc'
}

export type CurationItemT = {
  id: UniqueIdentifier
  brandName?: string
  color?: string
  enticements?: EnticementDetailsT[]
  imageAltText?: string
  imageUrl?: string
  itemNumber?: string
  itemId: string
  allItemIds?: string[]
  price?: PriceDetailsT
  productName?: string
  sizeLabel?: string
  size?: string
  rmsSku: string
  note?: string
  webStyleId?: string
  itemAddedTimestamp?: string
  description?: string
  productCategory?: string
  productGender?: ProductGenderValues
}

export type GetCurationsQueryDataT = {
  curations: [CurationT] | []
  meta: { count: number }
}

export type GetStyleBoardsQueryDataT = {
  styleBoards: CurationT[]
  meta: {
    count: number
    page?: number
    perPage?: number
  }
}

export type GetCurationsQueryParamsT = {
  customerId: string
  employeeId: string
  orderBy: CURATION_ORDERING
  state: string
  preferredEmployeeId: string
  allRecords: string
  perPage: number
  page: number
}

export type GetStyleBoardsQueryParamsT = {
  customerId: string
  employeeId: string
  orderBy: CURATION_ORDERING
  state: string | string[]
  perPage: number
  page: number
}

export type GetCurationByIdT = {
  curation: CurationT
}

export type GetEditCurationQueryDataT = {
  curation: CurationT
  products: ServerEditItemsProductT[]
  editRequestId: number | null
}

export type GetEditRequestQueueQueryDataT = {
  editRequests: [EditRequestT] | []
  meta: { count: number }
}

export type GetPublishedCurationsQueryDataT = {
  curations: CurationT[]
  meta: { count: number }
}

export type GetDraftCurationsQueryDataT = {
  curations: CurationT[] | []
  meta: { count: number }
}

export type AbandonCurationMutationDataT = {
  curation: CurationT
}

export type AbandonCurationArgsT = {
  curationId: number
  employeeId: string
  products: { rmsSku: string }[]
  isCancel: boolean
}

export type SaveCurationAsDraftMutationDataT = {
  curation: CurationT
}

export type OrderedItemsT = Array<CurationItemT>

export type SaveCurationAsDraftArgsT = {
  curationId: number
  description: string
  title: string
  notes: Record<string, string>
  orderedItems: OrderedItemsT
}

export type CreateCurationMutationDataT = {
  curation: CurationT
}

export type ResumeCurationMutationDataT = {
  curation: CurationT
}

export type ShoppingTokenDataT = {
  token: string
}

export type ItemRecommendationsT = {
  seedItemWebStyleId?: string
  itemRecommendationTerms: RecommendationsT
}

export type CurationProductsT = Array<CurationItemT>

export type CurrentReviewStateT = {
  notes: Record<string, string>
  title: string
  titleError: string
  description: string
  descriptionError: boolean
  generatedDescription: string
  generatedTitle: string
  inProgressCurationId: number | null
  isFashionMapSearchDrawerOpen: boolean
  orderedItems: string[]
  shoppingToken: string | null
  productDiscovery: ProductDiscoveryT
  itemRecommendations: ItemRecommendationsT
}

export type ProductDiscoveryT = {
  rmsSkus: Record<string, string>
  webStyleIds: Record<string, string>
}

export type StoredReviewStateT = Pick<
  CurrentReviewStateT,
  'notes' | 'title' | 'description' | 'inProgressCurationId' | 'shoppingToken'
>

export type StoredEditStateT = Pick<
  CurrentEditStateT,
  | 'checkoutBagDataNotes'
  | 'deactivatedCurationProductIds'
  | 'deactivatedCurationProductRmsSkus'
  | 'description'
  | 'duplicateSkus'
  | 'editedDescription'
  | 'editedItemCount'
  | 'editedNotes'
  | 'editedTitle'
  | 'editShoppingSessionId'
  | 'editShoppingToken'
  | 'inEditCurationId'
  | 'notes'
  | 'productDiscovery'
  | 'title'
>

export type CurationRecordT = {
  [key: string]: CurationItemT
}

export type CurationReviewLocationT = {
  productSource?: string
  rmsSku?: string
  showAlreadyInProgressMessage?: boolean
}

export type CurrentEditStateT = {
  checkoutBagDataNotes: Record<string, string>
  deactivatedCurationProductIds: Array<number>
  deactivatedCurationProductRmsSkus: Array<string>
  description: string | undefined
  descriptionError: boolean
  duplicateSkus: Array<string>
  editedDescription: string
  editedItemCount: number
  editedNotes: Record<string, Record<string, string>>
  editedTitle: string
  editShoppingSessionId: string
  editShoppingToken: string
  inEditCurationId: number | null
  isFashionMapSearchDrawerOpen: boolean
  isQuickLinksDialogOpen: boolean
  isRecentItemsDrawerOpen: boolean
  isScanningDrawerOpen: boolean
  notes: Record<string, Record<string, string>>
  productDiscovery: ProductDiscoveryT
  title: string
  titleError: string
}

export type BopusStoreT = {
  name: string
  zipCode: string
  storeNumber: number
}
