import { Button } from '@mui/material'
import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { checkBrowserClient } from 'utils/userAgentDetector'

const CustomerBookButton = (): ReactElement => {
  const isMobileDevice = checkBrowserClient.isMobile()
  const history = useHistory()
  const goToCustomerBook = () => {
    generateNewRelicLogs('customerHomeButtonClick')
    history.push({ pathname: '/customers' })
  }
  return (
    <>
      <Button
        onClick={goToCustomerBook}
        variant="outlined"
        sx={{ fontSize: '16px', marginRight: isMobileDevice && '20px' }}
      >
        View Customer Book
      </Button>
    </>
  )
}
export default CustomerBookButton
