import { ReactElement } from 'react'
import BoxWithGladlySpace from 'components/BoxWithGladlySpace'
import { ErrorBoundary } from 'react-error-boundary'
import AppointmentsErrorFallback from '../components/AppointmentsErrorFallback'
import AppointmentsHomeHeader from '../components/AppointmentsHomeHeader'
import EmptyAppointmentsList from '../components/EmptyAppointmentsList'
import AppointmentsDateList from '../components/AppointmentsDateList'
import { getSortedAppointmentsByDate } from './utils'
import oktaTokenStorage from 'utils/okta-token-utils'
import { useGetAppointmentsListQuery } from 'services/appointmentsService'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import { Box } from '@mui/material'

const AppointmentsList = (): ReactElement => {
  const employeeStore = oktaTokenStorage.getEmployeeStoreNumberFromOktaToken()
  const employeeEmail = oktaTokenStorage.getEmployeeEmailFromOktaToken()

  const {
    data: appointmentsData,
    isLoading: isAppointmentsListLoading,
    isError: isAppointmentListError,
    error,
    endpointName
  } = useGetAppointmentsListQuery({
    store: employeeStore,
    email: employeeEmail
  })

  const sortedAppointmentsList = getSortedAppointmentsByDate(
    appointmentsData?.appointments || []
  )
  const isEmptyList = sortedAppointmentsList.length === 0

  if (isAppointmentsListLoading) {
    return <LoadingSpinner />
  }

  return (
    <ErrorBoundary FallbackComponent={AppointmentsErrorFallback}>
      <BoxWithGladlySpace>
        <AppointmentsHomeHeader isEmptyList={isEmptyList} />
        {isAppointmentListError ? (
          <Box sx={{ margin: 'auto', maxWidth: '1488px' }}>
            <PageError
              errorTitle="Error retrieving your appointments - "
              errorDetails={{ endpoint: endpointName, errorData: error }}
            />
          </Box>
        ) : isEmptyList ? (
          <EmptyAppointmentsList />
        ) : (
          <AppointmentsDateList appointmentsList={sortedAppointmentsList} />
        )}
      </BoxWithGladlySpace>
    </ErrorBoundary>
  )
}

export default AppointmentsList
