export const FollowUpKind = {
  ADDED_TO_BOOK: 'addedToBook',
  TWO_WEEKS: 'twoWeeksFollowUp',
  TWO_MONTHS: 'twoMonthsFollowUp',
  NEW_PURCHASE_WITH_EMPLOYEE: 'newPurchaseWithEmployeeFollowUp',
  TWO_WEEKS_PURCHASE_WITH_EMPLOYEE: 'twoWeeksPurchaseWithEmployeeFollowUp',
  TWO_MONTHS_PURCHASE_WITH_EMPLOYEE: 'twoMonthsPurchaseWithEmployeeFollowUp'
} as const

type FollowUpKindKey = keyof typeof FollowUpKind

export type FollowUpKindValueT = typeof FollowUpKind[FollowUpKindKey]

type CompletedFollowUpMetaOptionsT = {
  orderId?: string
}

type FollowUpMetaOptionsT = {
  purchaseDate?: string
} & CompletedFollowUpMetaOptionsT

export type ExtendedFollowUpT = {
  followUpKind: FollowUpKindValueT
  meta?: FollowUpMetaOptionsT
}

export type CompletedFollowUpResponseT = {
  employeeId: string
  customerId: string
  kind: FollowUpKindValueT
  meta?: CompletedFollowUpMetaOptionsT
  id: number
}

export type PaginatedDTO<T> = {
  data: T[]
  totalCount: number
  totalPages: number
  currentPage: number
}

export type CompletedFollowUpForCustomerResponseT = {
  id: number
  kind: FollowUpKindValueT
  meta?: CompletedFollowUpMetaOptionsT
  createdAt: string
}

export type CompletedFollowUpsForEmployeeReponseT =
  CompletedFollowUpForCustomerResponseT & {
    customer: {
      customerId: string
      firstName: string
      lastName: string
    }
  }

type FollowUpCategories = {
  [key in FollowUpKindKey]: string[]
}

export type SingleCustomerData = {
  followUps: ExtendedFollowUpT[]
  firstName: string
  lastName: string
  relationshipDate: string
}

export type FollowUpCustomersData = {
  [customerId: string]: SingleCustomerData
}

export type FollowUpsResponse = {
  categories: FollowUpCategories
  customers: FollowUpCustomersData
}
