import { ReactElement } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Container, ToggleButton, ToggleButtonGroup } from '@mui/material'
import SalesTerms from './components/SalesTerms'
import { useGetSalesQuery } from 'services/salesApi'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import AllSales from './components/AllSales'
import StoreAndStyleboardSales from './components/StoreAndStyleboardSales'
import { emDashCharacter } from 'pages/constants'

const All = 'all'
const Store = 'store'
const Styleboard = 'styleboard'

const Sales = (): ReactElement => {
  const { search } = useLocation()
  const history = useHistory()
  const { isReportingStoreEnabled } = useFeatureFlags()
  const { data, isLoading, isError, endpointName, error } = useGetSalesQuery()

  const query = new URLSearchParams(search)
  const defaultChannel = Styleboard
  const channel = query.get('channel') || defaultChannel

  const handleChannelChange = (
    _event: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string
  ) => {
    if (value !== null) {
      const searchParams = new URLSearchParams(location.search)
      searchParams.set('channel', value)
      history.replace({ search: searchParams.toString() })
    }
  }

  if (isLoading) {
    return <LoadingSpinner />
  }

  if (isError) {
    return (
      <Container>
        <PageError
          isErrorTitlePersonalized={true}
          errorTitle={
            error && 'status' in error && error.status === 404
              ? `Issue getting sales data ${emDashCharacter} `
              : undefined
          }
          errorDetails={{
            endpoint: endpointName,
            errorData: error
          }}
        />
      </Container>
    )
  }

  return (
    <Container>
      <ToggleButtonGroup
        sx={{ my: 3, display: 'block' }}
        value={channel}
        onChange={(event, newValue) => handleChannelChange(event, newValue)}
        color="primary"
        exclusive
        aria-label="Channel"
      >
        <ToggleButton key={All} value={All}>
          All
        </ToggleButton>
        {isReportingStoreEnabled?.active && (
          <ToggleButton key={Store} value={Store}>
            Store
          </ToggleButton>
        )}
        <ToggleButton key={Styleboard} value={Styleboard}>
          Style board
        </ToggleButton>
      </ToggleButtonGroup>
      {data && channel === All && <AllSales salesData={data} />}
      {data && (channel === Styleboard || channel === Store) && (
        <StoreAndStyleboardSales salesData={data} channel={channel} />
      )}
      <SalesTerms />
    </Container>
  )
}

export default Sales
