import { ReactElement } from 'react'
import AppointmentsError from 'pages/Appointments/components/AppointmentsErrorAlert'
import { CopyErrorDataT } from 'types/CopyErrorData'

interface FallbackProps<T extends Error> {
  error: T
  resetErrorBoundary: (...args: Array<unknown>) => void
}

export const AppointmentsErrorFallback = ({
  error
}: FallbackProps<Error>): ReactElement => {
  const errorData: CopyErrorDataT = {
    errorData: error
  }

  return <AppointmentsError errorData={errorData} />
}

export default AppointmentsErrorFallback
