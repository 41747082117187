import PageError from 'components/PageError'
import { CopyErrorDataT } from 'types/CopyErrorData'

const ERROR_MESSAGE = 'Issue getting follow ups'

type PropsT = {
  error: CopyErrorDataT
}

const FollowUpsError = ({ error }: PropsT): JSX.Element => {
  return (
    <PageError
      isInlineAlert
      errorTitle={ERROR_MESSAGE}
      errorDetails={error}
      customBoxStyle={{ mt: '0px', px: '0px' }}
    />
  )
}

export default FollowUpsError
