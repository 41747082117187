import React from 'react'
import { Box, Typography } from '@mui/material'
import { PERSONAL_BOOK_NAVIGATION_TITLE } from 'components/MenuBar/constants'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'

const MenuBarTitle: React.FC = () => {
  const { isPBHolidayThemeEnabled } = useFeatureFlags()

  return (
    <Box
      sx={{
        marginLeft: '8px'
      }}
    >
      <Typography
        variant="overline"
        fontWeight="bold"
        sx={{
          color: isPBHolidayThemeEnabled?.active
            ? 'common.white'
            : 'common.black'
        }}
      >
        {PERSONAL_BOOK_NAVIGATION_TITLE}
      </Typography>
    </Box>
  )
}

export default MenuBarTitle
