import { CurrentEditStateT, StoredEditStateT } from 'types/Curation'
import { CurationEditActionT } from './Actions'
import { useLocalStorage } from 'react-use'
import {
  EDIT_DESCRIPTION,
  EDIT_TITLE,
  SET_DUPLICATE_SKUS,
  MAX_DESCRIPTION_LENGTH,
  MAX_TITLE_LENGTH,
  RESET_EDITS,
  SET_DEACTIVATED_CURATION_PRODUCT,
  SET_REACTIVATED_CURATION_PRODUCT,
  TITLE_MAX_LENGTH_ERROR_TEXT,
  TITLE_REQUIRED_TEXT,
  SET_INITIAL_DESCRIPTION,
  SET_INITIAL_NOTES,
  SET_INITIAL_TITLE,
  UPDATE_EDIT_NOTES,
  UPDATE_CHECKOUT_BAG_DATA_NOTES,
  SET_IS_FASHION_MAP_SEARCH_DRAWER_OPEN,
  SET_IS_QUICK_LINKS_DIALOG_OPEN,
  SET_IS_RECENT_ITEMS_DRAWER_OPEN,
  SET_IS_SCANNING_DRAWER_OPEN,
  UPDATE_WISHLIST_ADDS,
  UPDATE_SCANNER_VIEWS,
  UPDATE_SCANNER_ADDS,
  UPDATE_FASHION_MAP_VIEWS,
  SET_IN_EDIT_CURATION,
  SET_EDIT_SHOPPING_SESSION_ID,
  SET_EDIT_SHOPPING_TOKEN,
  UPDATE_SNAPSHOT_PURCHASE_HISTORY_ADDS,
  UPDATE_SNAPSHOT_PURCHASE_HISTORY_VIEWS,
  UPDATE_MINI_PDP_RECENT_ITEM_ADDS,
  UPDATE_MINI_PDP_BEAUTY_RESTOCK_ADDS,
  UPDATE_MINI_PDP_SCANNER_DRAWER_ADDS
} from './constants'
import { createContext, Dispatch, useCallback, useReducer } from 'react'

export const CURATION_EDIT_LOCAL_STORAGE_KEY = 'curation_edit_reducer_state'

const initialState = {
  deactivatedCurationProductIds: [],
  deactivatedCurationProductRmsSkus: [],
  duplicateSkus: [],
  notes: {},
  title: '',
  titleError: '',
  description: '',
  descriptionError: false,
  editedNotes: {},
  editedTitle: '',
  editedDescription: '',
  checkoutBagDataNotes: {},
  isFashionMapSearchDrawerOpen: false,
  isQuickLinksDialogOpen: false,
  isRecentItemsDrawerOpen: false,
  isScanningDrawerOpen: false,
  editedItemCount: 0,
  productDiscovery: {
    rmsSkus: {},
    webStyleIds: {}
  },
  inEditCurationId: null,
  editShoppingSessionId: '',
  editShoppingToken: ''
}

export const initialStoredState = (({
  deactivatedCurationProductIds,
  deactivatedCurationProductRmsSkus,
  duplicateSkus,
  notes,
  title,
  description,
  editedNotes,
  editedTitle,
  editedDescription,
  checkoutBagDataNotes,
  editedItemCount,
  productDiscovery,
  inEditCurationId,
  editShoppingSessionId,
  editShoppingToken
}) => ({
  deactivatedCurationProductIds,
  deactivatedCurationProductRmsSkus,
  duplicateSkus,
  notes,
  title,
  description,
  editedNotes,
  editedTitle,
  editedDescription,
  checkoutBagDataNotes,
  editedItemCount,
  productDiscovery,
  inEditCurationId,
  editShoppingSessionId,
  editShoppingToken
}))(initialState)

const reducer = (
  currentState: CurrentEditStateT,
  action: CurationEditActionT
) => {
  switch (action.type) {
    case RESET_EDITS:
      return initialState
    case SET_DEACTIVATED_CURATION_PRODUCT:
      return {
        ...currentState,
        deactivatedCurationProductIds: [
          ...currentState.deactivatedCurationProductIds,
          action.payload.id
        ],
        deactivatedCurationProductRmsSkus: [
          ...currentState.deactivatedCurationProductRmsSkus,
          action.payload.sku
        ],
        editedItemCount: currentState.editedItemCount - 1
      }
    case SET_REACTIVATED_CURATION_PRODUCT:
      return {
        ...currentState,
        deactivatedCurationProductIds:
          currentState.deactivatedCurationProductIds.filter(
            (id) => id !== action.payload.id
          ),
        deactivatedCurationProductRmsSkus:
          currentState.deactivatedCurationProductRmsSkus.filter(
            (sku) => sku !== action.payload.sku
          ),
        editedItemCount: currentState.editedItemCount + 1
      }
    case SET_INITIAL_DESCRIPTION:
      return {
        ...currentState,
        description: action.payload
      }
    case SET_INITIAL_NOTES:
      return {
        ...currentState,
        notes: {
          ...currentState.notes,
          ...action.payload
        }
      }
    case SET_INITIAL_TITLE:
      return {
        ...currentState,
        title: action.payload
      }
    case UPDATE_EDIT_NOTES:
      return {
        ...currentState,
        notes: {
          ...currentState.notes,
          ...action.payload
        },
        editedNotes: {
          ...currentState.editedNotes,
          ...action.payload
        }
      }
    case EDIT_TITLE:
      if (action.payload.length === 0) {
        return {
          ...currentState,
          title: action.payload,
          titleError: `${TITLE_REQUIRED_TEXT}`,
          editedTitle: action.payload
        }
      } else if (action.payload.length > MAX_TITLE_LENGTH) {
        return {
          ...currentState,
          title: action.payload,
          titleError: `${TITLE_MAX_LENGTH_ERROR_TEXT}`,
          editedTitle: action.payload
        }
      } else {
        return {
          ...currentState,
          title: action.payload,
          titleError: '',
          editedTitle: action.payload
        }
      }
    case EDIT_DESCRIPTION:
      if (action.payload && action.payload.length > MAX_DESCRIPTION_LENGTH) {
        return {
          ...currentState,
          description: action.payload,
          descriptionError: true,
          editedDescription: action.payload
        }
      } else {
        return {
          ...currentState,
          description: action.payload,
          descriptionError: false,
          editedDescription: action.payload
        }
      }
    case UPDATE_CHECKOUT_BAG_DATA_NOTES:
      return {
        ...currentState,
        checkoutBagDataNotes: {
          ...currentState.checkoutBagDataNotes,
          [action.payload.rmsSku]: action.payload.itemNote
        }
      }
    case SET_IS_FASHION_MAP_SEARCH_DRAWER_OPEN:
      return {
        ...currentState,
        isFashionMapSearchDrawerOpen: action.payload
      }
    case SET_IS_QUICK_LINKS_DIALOG_OPEN:
      return {
        ...currentState,
        isQuickLinksDialogOpen: action.payload
      }
    case SET_IS_RECENT_ITEMS_DRAWER_OPEN:
      return {
        ...currentState,
        isRecentItemsDrawerOpen: action.payload
      }
    case SET_IS_SCANNING_DRAWER_OPEN:
      return {
        ...currentState,
        isScanningDrawerOpen: action.payload
      }
    case SET_DUPLICATE_SKUS:
      return {
        ...currentState,
        duplicateSkus: action.payload
      }
    case UPDATE_WISHLIST_ADDS:
      return {
        ...currentState,
        productDiscovery: {
          ...currentState.productDiscovery,
          webStyleIds: {
            [action.payload.rmsSku]: 'wishlistAdds',
            ...currentState.productDiscovery.rmsSkus
          }
        }
      }
    case UPDATE_SCANNER_ADDS:
      return {
        ...currentState,
        productDiscovery: {
          ...currentState.productDiscovery,
          rmsSkus: {
            [action.payload.rmsSku]: 'scannerAdds',
            ...currentState.productDiscovery.rmsSkus
          }
        }
      }
    case UPDATE_SCANNER_VIEWS:
      return {
        ...currentState,
        productDiscovery: {
          ...currentState.productDiscovery,
          webStyleIds: {
            [action.payload.webStyleId]: 'scannerViews',
            ...currentState.productDiscovery.webStyleIds
          }
        }
      }
    case UPDATE_FASHION_MAP_VIEWS:
      return {
        ...currentState,
        productDiscovery: {
          ...currentState.productDiscovery,
          webStyleIds: {
            [action.payload.webStyleId]: 'fashionMapViews',
            ...currentState.productDiscovery.webStyleIds
          }
        }
      }
    case SET_IN_EDIT_CURATION:
      if (
        currentState.inEditCurationId != null &&
        currentState.inEditCurationId != action.payload.id
      ) {
        return {
          ...initialState,
          inEditCurationId: action.payload.id
        }
      } else {
        return {
          ...currentState,
          inEditCurationId: action.payload.id
        }
      }
    case SET_EDIT_SHOPPING_SESSION_ID:
      return {
        ...currentState,
        editShoppingSessionId: action.payload.editShoppingSessionId
      }
    case SET_EDIT_SHOPPING_TOKEN:
      return {
        ...currentState,
        editShoppingToken: action.payload.editShoppingToken
      }
    case UPDATE_SNAPSHOT_PURCHASE_HISTORY_ADDS:
      return {
        ...currentState,
        productDiscovery: {
          ...currentState.productDiscovery,
          rmsSkus: {
            [action.payload.rmsSku]: 'snapshotPurchaseHistoryAdds',
            ...currentState.productDiscovery.rmsSkus
          }
        }
      }
    case UPDATE_SNAPSHOT_PURCHASE_HISTORY_VIEWS:
      return {
        ...currentState,
        productDiscovery: {
          ...currentState.productDiscovery,
          webStyleIds: {
            [action.payload.webStyleId]: 'snapshotPurchaseHistoryViews',
            ...currentState.productDiscovery.webStyleIds
          }
        }
      }
    case UPDATE_MINI_PDP_RECENT_ITEM_ADDS:
      return {
        ...currentState,
        productDiscovery: {
          ...currentState.productDiscovery,
          rmsSkus: {
            [action.payload.rmsSku]: 'miniPDPRecentItemAdds',
            ...currentState.productDiscovery.rmsSkus
          }
        }
      }
    case UPDATE_MINI_PDP_BEAUTY_RESTOCK_ADDS:
      return {
        ...currentState,
        productDiscovery: {
          ...currentState.productDiscovery,
          rmsSkus: {
            [action.payload.rmsSku]: 'miniPDPBeautyRestockAdds',
            ...currentState.productDiscovery.rmsSkus
          }
        }
      }
    case UPDATE_MINI_PDP_SCANNER_DRAWER_ADDS:
      return {
        ...currentState,
        productDiscovery: {
          ...currentState.productDiscovery,
          rmsSkus: {
            [action.payload.rmsSku]: 'miniPDPScannerDrawerAdds',
            ...currentState.productDiscovery.rmsSkus
          }
        }
      }
    default:
      return currentState
  }
}

//https://www.benmvp.com/blog/sync-localstorage-react-usereducer-hook/
export const usePersistedEditReducer = (): [
  CurrentEditStateT,
  Dispatch<CurationEditActionT>
] => {
  const [storedState, storeState] = useLocalStorage<StoredEditStateT>(
    CURATION_EDIT_LOCAL_STORAGE_KEY,
    initialStoredState
  )

  const reducerLocalStorage = useCallback(
    (state, action) => {
      const newState = reducer(state, action)
      const newStoredState = (({
        deactivatedCurationProductIds,
        deactivatedCurationProductRmsSkus,
        duplicateSkus,
        notes,
        title,
        description,
        editedNotes,
        editedTitle,
        editedDescription,
        checkoutBagDataNotes,
        editedItemCount,
        productDiscovery,
        inEditCurationId,
        editShoppingSessionId,
        editShoppingToken
      }) => ({
        deactivatedCurationProductIds,
        deactivatedCurationProductRmsSkus,
        duplicateSkus,
        notes,
        title,
        description,
        editedNotes,
        editedTitle,
        editedDescription,
        checkoutBagDataNotes,
        editedItemCount,
        productDiscovery,
        inEditCurationId,
        editShoppingSessionId,
        editShoppingToken
      }))(newState)

      storeState(newStoredState)
      return newState
    },
    [storeState]
  )
  return useReducer(reducerLocalStorage, { ...initialState, ...storedState })
}

export const CurationEditDispatchContext =
  createContext<null | Dispatch<CurationEditActionT>>(null)
