import { NewRelicStylingAttributesT } from 'types/NewRelicAttributes'
import {
  isUSSeller,
  isPersonalStylist,
  isStyleAdvisor,
  isBetaTestUser,
  isDevAdmin,
  isBizAdmin,
  isSPM,
  isASMCuration
} from '../utils/userPermissions'
import oktaTokenStorage from './okta-token-utils'
import { CurationT } from 'types/Curation'
import { CurationSliceT } from 'app/curationSlice'
import { ProductSourceType } from './determineProductSourceHelper'

export const NEW_RELIC_NAME = 'newrelic'

export const generateNewRelicLogs = (
  interactionName: string,
  attributes?: NewRelicStylingAttributesT
): void => {
  const interaction =
    window[NEW_RELIC_NAME] && window[NEW_RELIC_NAME].interaction
      ? window[NEW_RELIC_NAME].interaction()
      : null

  if (!interaction) return
  if (interactionName) interaction.setName(interactionName)
  if (attributes) {
    for (const [key, value] of Object.entries(attributes)) {
      interaction.setAttribute(key, value)
    }
  }
  interaction
    .setAttribute(
      'employeeId',
      oktaTokenStorage.getEmployeeNumberFromOktaToken()
    )
    .setAttribute('userPersona', getUserPersonaForLogging())
    .setAttribute('adminPersona', getAdminPersonaForLogging())
    .setAttribute('isUSSeller', isUSSeller())
    .setAttribute('isPersonalStylist', isPersonalStylist())
    .setAttribute('isStyleAdvisor', isStyleAdvisor())
    .setAttribute('isBetaTestUser', isBetaTestUser())
    .setAttribute('isPWAUser', isPWAUser())
    .setAttribute('isSubscribedToPush', isSubscribedToPushNotifications())
    .save()
    .end()
}

export const setNRUserId = (): void => {
  window[NEW_RELIC_NAME] &&
    window[NEW_RELIC_NAME].setUserId &&
    window[NEW_RELIC_NAME].setUserId(
      oktaTokenStorage.getEmployeeNumberFromOktaToken()
    )
}

export const generateSaveAsDraftNewRelicLogs = (
  curationId: number,
  orderedItems: string[],
  notes: Record<string, unknown>,
  title: string,
  description: string
): void => {
  const attributes = {
    curationId: curationId,
    numberOfItemsInBag: orderedItems ? orderedItems?.length : 0,
    hasNotes: notes ? !!Object.keys(notes).length : false,
    hasTitle: !!title,
    hasDescription: !!description
  }
  generateNewRelicLogs('saveAsDraft', attributes)
}

type ProductDiscoveryAttributes = {
  [key in ProductSourceType]: number
}

export const generateProductDiscoveryAttributes = (
  productSources: ProductSourceType[]
): ProductDiscoveryAttributes => {
  const productDiscoveryAttributes: ProductDiscoveryAttributes = {
    fashionMapViews: 0,
    iframe: 0,
    scannerAdds: 0,
    scannerViews: 0,
    seedItemRecViews: 0,
    wishlistAdds: 0,
    snapshotPurchaseHistoryAdds: 0,
    snapshotPurchaseHistoryViews: 0,
    startItemRecViews: 0,
    customerListWishlistAdds: 0,
    customerListPurchaseHistoryAdds: 0,
    miniPDPRecentItemAdds: 0,
    miniPDPSeedItemRecAdds: 0,
    miniPDPBeautyRestockAdds: 0,
    miniPDPStartBoardRecAdds: 0,
    miniPDPHolidayGiftsSBAdds: 0,
    miniPDPScannerDrawerAdds: 0
  }

  productSources.forEach((source) => {
    productDiscoveryAttributes[source]++
  })

  return productDiscoveryAttributes
}

export const mapCurationToNewRelicLog = (
  curation: CurationT
): Pick<
  NewRelicStylingAttributesT,
  | 'curationId'
  | 'ocpId'
  | 'preferredEmployeeId'
  | 'shoppingSessionId'
  | 'title'
  | 'type'
> => {
  return {
    curationId: curation.id,
    ocpId: curation.ocpId,
    preferredEmployeeId: curation.preferredEmployeeId,
    shoppingSessionId: curation.shoppingSessionId,
    title: curation.title,
    type: curation.type
  }
}

export const mapCurationSliceToNewRelicLog = (
  curationSlice: CurationSliceT
): Pick<
  NewRelicStylingAttributesT,
  'curationId' | 'ocpId' | 'shoppingSessionId' | 'type'
> => {
  return {
    curationId: curationSlice.id,
    ocpId: curationSlice.ocpId,
    shoppingSessionId: curationSlice.shoppingSessionId,
    type: curationSlice.type
  }
}

enum USER_PERSONAS {
  PERSONAL_STYLIST = 'personalStylist',
  SELLER = 'seller',
  STYLE_ADVISOR = 'styleAdvisor',
  UNKNOWN = 'unknown'
}

const getUserPersonaForLogging = () => {
  if (isUSSeller()) {
    return USER_PERSONAS.SELLER
  } else if (isPersonalStylist()) {
    return USER_PERSONAS.PERSONAL_STYLIST
  } else if (isStyleAdvisor()) {
    return USER_PERSONAS.STYLE_ADVISOR
  } else {
    return USER_PERSONAS.UNKNOWN
  }
}

enum ADMIN_PERSONAS {
  ASM = 'areaStoreManager',
  BIZ_ADMIN = 'bizAdmin',
  DEV_ADMIN = 'devAdmin',
  SPM = 'stylistProgramManager',
  NONE = 'none'
}

const getAdminPersonaForLogging = () => {
  if (isDevAdmin()) {
    return ADMIN_PERSONAS.DEV_ADMIN
  } else if (isBizAdmin()) {
    return ADMIN_PERSONAS.BIZ_ADMIN
  } else if (isSPM()) {
    return ADMIN_PERSONAS.SPM
  } else if (isASMCuration()) {
    return ADMIN_PERSONAS.ASM
  } else {
    return ADMIN_PERSONAS.NONE
  }
}

const isPWAUser = () => {
  return (
    window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator.standalone === true
  )
}

const isSubscribedToPushNotifications = () => {
  const localStorageResult = localStorage.getItem(
    'isSubscribedToPushNotifications'
  )
  if (localStorageResult) {
    return localStorageResult === 'true'
  }
  return false
}
