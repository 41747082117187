import { CardHeader, IconButton } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import { SingleCustomerData } from 'types/FollowUps'
import { FOLLOW_UP_TEXT } from 'constants/clienteling/followUpsText'

type PropsT = {
  customerData: SingleCustomerData
  onFollowUpClick: () => void
  onCheckFollowUp: () => void
}

const SingleFollowUp = ({
  customerData,
  onFollowUpClick,
  onCheckFollowUp
}: PropsT): JSX.Element => {
  return (
    <CardHeader
      avatar={
        <IconButton onClick={onCheckFollowUp}>
          <CheckBoxOutlineBlankIcon />
        </IconButton>
      }
      title={`${customerData.firstName} ${customerData.lastName}`}
      titleTypographyProps={{ variant: 'subtitle2' }}
      subheader={FOLLOW_UP_TEXT[customerData.followUps[0].followUpKind]}
      subheaderTypographyProps={{
        variant: 'body2',
        color: 'text.primary'
      }}
      action={
        <IconButton onClick={onFollowUpClick} data-testid="follow-up-action">
          <ChevronRightIcon />
        </IconButton>
      }
    />
  )
}

export default SingleFollowUp
