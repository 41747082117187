const MAX_NUMBER_OF_TRIPS = 50

export const formatConsentedCustomerTripsText = (
  numberOfTips: number
): string => {
  if (numberOfTips === 0) {
    return 'No trips'
  } else if (numberOfTips >= MAX_NUMBER_OF_TRIPS) {
    return `${MAX_NUMBER_OF_TRIPS}+ trips`
  } else if (numberOfTips === 1) {
    return `${numberOfTips} trip`
  } else {
    return `${numberOfTips} trips`
  }
}
