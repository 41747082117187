import { ReactElement, useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { LoginCallback, SecureRoute } from '@okta/okta-react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { customStylingTheme } from 'theme'
import CurationReview from 'pages/CurationReview'
import Home from 'pages/Home'
import CurationEdit from 'pages/CurationEdit'
import CurationHome from 'pages/CurationHome'
import CustomerHome from 'pages/CustomerHome'
import CustomerDetails from 'pages/CustomerDetails'
import Drafts from 'pages/Drafts'
import InviteHome from 'pages/InviteHome/InviteHome'
import ArchivedCustomers from 'pages/ArchivedCustomers/ArchivedCustomers'
import SettingsHome from 'pages/SettingsHome'
import StylistProfile from 'pages/StylistProfile'
import NotFound from 'pages/NotFound'
import PurchasesDetails from 'pages/SnapshotDrawer/pages/RecentPurchaseDetails'
import PersonalRequests from 'pages/PersonalRequests'
import PermissionsAdmin from 'pages/PermissionsAdmin'
import ReportingHome from 'pages/ReportingHome'
import FavoriteItems from 'pages/FavoriteItems'
import FinishedBoards from 'pages/FinishedBoards'
import CustomerMessages from 'pages/CustomerMessages'
import { ErrorFallback } from 'pages/ErrorFallback'
import CustomerConversations from 'pages/CustomerConversations'
import Notifications from 'pages/Notifications'
import EditRequests from 'pages/EditRequests'
import Holds from 'pages/Holds'
import { ErrorBoundary } from 'react-error-boundary'
import MenuBar from 'components/MenuBar'
import SecurityProvider from 'components/SecurityProvider'
import { NavigationProvider } from 'contexts/NavigationContext'
import { FeatureFlagsProvider } from 'contexts/FeatureFlagsContext'
import { TwilioClientProvider } from 'contexts/TwilioContext'
import { setGladlyUser } from 'venderScripts/gladly'
import oktaTokenStorage from 'utils/okta-token-utils'
import ScanditSDKProvider from 'components/BarcodeScanner/Scandit/ScanditSDK'
import AppointmentsList from 'pages/Appointments/AppointmentsList'
import AppointmentDetails from 'pages/Appointments/AppointmentDetails'
import FollowUps from 'pages/FollowUps'
import LocalStorageContext from 'contexts/LocalStorageContext'

const App = (): ReactElement => {
  const gladly = window.Gladly

  const empToken = oktaTokenStorage.getOktaAccessToken()

  useEffect(() => {
    if (gladly && empToken) {
      setGladlyUser()
    }
  }, [gladly, empToken])

  return (
    <BrowserRouter>
      <SecurityProvider>
        <ThemeProvider theme={customStylingTheme}>
          {/* Global style reset */}
          <CssBaseline />
          <FeatureFlagsProvider>
            <NavigationProvider>
              <LocalStorageContext>
                <ScanditSDKProvider>
                  <TwilioClientProvider>
                    <SecureRoute path="/" component={MenuBar} />
                    <ErrorBoundary fallbackRender={ErrorFallback}>
                      <Switch>
                        <Route
                          path={process.env.REACT_APP_OKTA_REDIRECT_PATH}
                          component={LoginCallback}
                        />
                        <Route path="/health" />
                        <SecureRoute exact path="/" component={Home} />
                        <SecureRoute
                          exact
                          path="/styleboards"
                          component={CurationHome}
                        />
                        <SecureRoute
                          exact
                          path="/styleboards/finished"
                          component={FinishedBoards}
                        />
                        <SecureRoute
                          exact
                          path="/styleboards/drafts"
                          component={Drafts}
                        />
                        <SecureRoute
                          path="/reporting"
                          component={ReportingHome}
                        />
                        <SecureRoute
                          path="/review"
                          component={CurationReview}
                        />
                        <SecureRoute
                          exact
                          path="/customers"
                          component={CustomerHome}
                        />
                        <SecureRoute
                          path="/customers/details/:customerId"
                          component={CustomerDetails}
                        />
                        <SecureRoute
                          path="/customers/invite"
                          component={InviteHome}
                        />
                        <SecureRoute
                          path="/customers/archived-customers"
                          component={ArchivedCustomers}
                        />
                        <SecureRoute
                          exact
                          path="/settings"
                          component={SettingsHome}
                        />
                        <SecureRoute
                          path="/settings/profile"
                          component={StylistProfile}
                        />
                        <SecureRoute
                          path="/stylist/requests"
                          component={PersonalRequests}
                        />
                        <SecureRoute
                          path="/stylist/edit-requests"
                          component={EditRequests}
                        />
                        <SecureRoute
                          path="/purchase-history/order/:orderNumber"
                          component={PurchasesDetails}
                        />
                        <SecureRoute
                          path="/permissions/admin"
                          component={PermissionsAdmin}
                        />
                        <SecureRoute
                          exact
                          path="/favorites"
                          component={FavoriteItems}
                        />
                        <SecureRoute
                          path="/styleboards/:curationId/edit"
                          component={CurationEdit}
                        />
                        <SecureRoute
                          path="/notifications"
                          component={Notifications}
                        />
                        <SecureRoute
                          exact
                          path="/messages"
                          component={CustomerConversations}
                        />
                        <SecureRoute
                          path="/messages/:conversationSid"
                          component={CustomerMessages}
                        />
                        <SecureRoute
                          path="/appointments/:appointmentId"
                          component={AppointmentDetails}
                        />
                        <SecureRoute
                          path="/appointments"
                          component={AppointmentsList}
                        />
                        <SecureRoute
                          exact
                          path="/customers/followups"
                          component={FollowUps}
                        />

                        <SecureRoute exact path="/holds" component={Holds} />
                        <SecureRoute path="*" component={NotFound} />
                      </Switch>
                    </ErrorBoundary>
                  </TwilioClientProvider>
                </ScanditSDKProvider>
              </LocalStorageContext>
            </NavigationProvider>
          </FeatureFlagsProvider>
        </ThemeProvider>
      </SecurityProvider>
    </BrowserRouter>
  )
}

export default App
