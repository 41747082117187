import { Dispatch, ReactElement, SetStateAction, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  IconButton,
  DialogActions,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import LoadingSpinner from 'components/LoadingSpinner'
import { logAppliedSortNREvents } from 'pages/CustomerHome/utils/newrelic'
import { CUSTOMER_SORT_OPTIONS, CustomerSortKeysT } from '../../constants'
import { CopyErrorDataT } from 'types/CopyErrorData'
import PageError from 'components/PageError/PageError'

type FeatureFlags = {
  isCustomerListTripsSortEnabledActive: boolean
  isNordstromPurchaseSortEnabledActive: boolean
  isCustomerListLastPurchaseWithEmployeeSortEnabledActive: boolean
}

type PropsT = {
  isOpen: boolean
  handleClose: () => void
  setSortFunctionKey: Dispatch<SetStateAction<CustomerSortKeysT>>
  defaultKey: CustomerSortKeysT
  extendedCustomerDataIsLoading: boolean
  extendedCustomerDataCopyError?: CopyErrorDataT
} & FeatureFlags

const isMobileDevice = checkBrowserClient.isMobile()
export const SORT_STORAGE_KEY = 'sortStorageKey'

const SortModal = ({
  isOpen,
  handleClose,
  setSortFunctionKey,
  defaultKey,
  isCustomerListTripsSortEnabledActive,
  extendedCustomerDataIsLoading,
  extendedCustomerDataCopyError,
  isNordstromPurchaseSortEnabledActive,
  isCustomerListLastPurchaseWithEmployeeSortEnabledActive
}: PropsT): ReactElement => {
  const [selected, setSelected] = useState<CustomerSortKeysT>(defaultKey)
  const onCancel = () => {
    handleClose()
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.value as CustomerSortKeysT)
  }

  const handleApply = () => {
    logAppliedSortNREvents(selected, generateNewRelicLogs)
    setSortFunctionKey(selected)
    storeSortFunctionKey(selected)
    handleClose()
  }

  const storeSortFunctionKey = (selectedOption: CustomerSortKeysT) =>
    sessionStorage.setItem(SORT_STORAGE_KEY, selectedOption)

  const isExtendedCustomerDataCopyError = !!extendedCustomerDataCopyError

  return (
    <>
      <Dialog
        open={isOpen}
        sx={{
          paddingBottom: isMobileDevice ? '16%' : 0,
          margin: 'auto'
        }}
        onClose={handleClose}
        fullWidth={true}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Typography fontWeight={'bold'} fontSize="23px">
              Sort by
            </Typography>
            <IconButton onClick={onCancel}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        {isExtendedCustomerDataCopyError && (
          <Box
            sx={{
              marginTop: '-28px',
              marginBottom: '-33px',
              marginLeft: '-12px'
            }}
          >
            <PageError
              isInlineAlert={true}
              errorTitle="Issue getting all sort options -"
              errorDetails={{
                errorData: extendedCustomerDataCopyError,
                endpoint: extendedCustomerDataCopyError?.endpoint,
                identifiers: extendedCustomerDataCopyError?.identifiers
              }}
            />
          </Box>
        )}
        {extendedCustomerDataIsLoading ? (
          <DialogContent sx={{ marginTop: '16px' }}>
            <LoadingSpinner />
          </DialogContent>
        ) : (
          <>
            <DialogContent
              sx={{
                marginTop: '16px',
                maxWidth: isMobileDevice ? '311px' : '600px'
              }}
            >
              <FormControl
                sx={{
                  paddingLeft: '24px'
                }}
              >
                <RadioGroup defaultValue={defaultKey} onChange={handleChange}>
                  <FormControlLabel
                    value={CUSTOMER_SORT_OPTIONS.BY_ALPHABETICAL}
                    label={
                      <Typography fontSize="16px">
                        Customer name: A-Z
                      </Typography>
                    }
                    control={<Radio edge="start" />}
                  />
                  {isCustomerListTripsSortEnabledActive &&
                    !isExtendedCustomerDataCopyError && (
                      <>
                        <FormControlLabel
                          value={CUSTOMER_SORT_OPTIONS.BY_MOST_TRIPS}
                          label={
                            <Typography fontSize="16px">
                              Trips with you (within 12 months): most
                            </Typography>
                          }
                          control={<Radio edge="start" />}
                          sx={{ padding: isMobileDevice ? '8px 0' : '0' }}
                        />
                        <FormControlLabel
                          value={CUSTOMER_SORT_OPTIONS.BY_FEWEST_TRIPS}
                          label={
                            <Typography fontSize="16px">
                              Trips with you (within 12 months): fewest
                            </Typography>
                          }
                          control={<Radio edge="start" />}
                          sx={{ padding: isMobileDevice ? '8px 0' : '0' }}
                        />
                      </>
                    )}
                  {isCustomerListLastPurchaseWithEmployeeSortEnabledActive &&
                    !isExtendedCustomerDataCopyError && (
                      <>
                        <FormControlLabel
                          value={
                            CUSTOMER_SORT_OPTIONS.BY_MOST_RECENT_PURCHASE_WITH_EMPLOYEE
                          }
                          label={
                            <Typography fontSize="16px">
                              Purchases with you: most recent
                            </Typography>
                          }
                          control={<Radio edge="start" />}
                          sx={{ padding: isMobileDevice ? '8px 0' : '0' }}
                        />
                        <FormControlLabel
                          value={
                            CUSTOMER_SORT_OPTIONS.BY_LEAST_RECENT_PURCHASE_WITH_EMPLOYEE
                          }
                          label={
                            <Typography fontSize="16px">
                              Purchases with you: least recent
                            </Typography>
                          }
                          control={<Radio edge="start" />}
                          sx={{ padding: isMobileDevice ? '8px 0' : '0' }}
                        />
                      </>
                    )}
                  {isNordstromPurchaseSortEnabledActive &&
                    !isExtendedCustomerDataCopyError && (
                      <>
                        <FormControlLabel
                          value={CUSTOMER_SORT_OPTIONS.BY_MOST_RECENT_PURCHASE}
                          label={
                            <Typography fontSize="16px">
                              Nordstrom Purchases: most recent
                            </Typography>
                          }
                          control={<Radio edge="start" />}
                          sx={{ padding: isMobileDevice ? '8px 0' : '0' }}
                        />
                        <FormControlLabel
                          value={CUSTOMER_SORT_OPTIONS.BY_LEAST_RECENT_PURCHASE}
                          label={
                            <Typography fontSize="16px">
                              Nordstrom Purchases: least recent
                            </Typography>
                          }
                          control={<Radio edge="start" />}
                          sx={{ padding: isMobileDevice ? '8px 0' : '0' }}
                        />
                      </>
                    )}
                  <FormControlLabel
                    value={CUSTOMER_SORT_OPTIONS.BY_OLDEST_MESSAGES}
                    label={
                      <Typography fontSize="16px">Messages: older</Typography>
                    }
                    control={<Radio edge="start" />}
                  />
                  <FormControlLabel
                    value={CUSTOMER_SORT_OPTIONS.BY_NEWEST_MESSAGES}
                    label={
                      <Typography fontSize="16px">Messages: newer</Typography>
                    }
                    control={<Radio edge="start" />}
                  />
                </RadioGroup>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleApply}>
                <Typography fontWeight={'bold'}>Apply</Typography>
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  )
}

export default SortModal
