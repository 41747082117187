import { ReactElement, useState } from 'react'
import { Box, Alert, AlertTitle } from '@mui/material'
import CopyErrorData from 'components/CopyErrorData'
import CopiedErrorSnackbar from 'components/CopiedErrorSnackbar'
import { emDashCharacter } from 'pages/constants'
import { CopyErrorDataT } from 'types/CopyErrorData'

interface PropsT {
  errorData: CopyErrorDataT | null
}

const AppointmentsError = ({ errorData }: PropsT): ReactElement => {
  const [copyToClipboardSuccess, setCopyToClipboardSuccess] = useState(false)

  return (
    <>
      {errorData && (
        <Box sx={{ mt: 3, mx: 2 }}>
          <Alert severity="error">
            <AlertTitle>
              <strong data-testid="error-title">
                {`Error retrieving your appointments ${emDashCharacter} refresh to try again`}
              </strong>
            </AlertTitle>
            <CopyErrorData
              errorDetails={errorData}
              setCopyToClipboardSuccess={setCopyToClipboardSuccess}
              helperMessage="Still not working? Copy error info and paste in support chat."
            />
          </Alert>
        </Box>
      )}
      {copyToClipboardSuccess && (
        <CopiedErrorSnackbar
          setCopyToClipboardSuccess={setCopyToClipboardSuccess}
        />
      )}
    </>
  )
}

export default AppointmentsError
