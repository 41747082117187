import {
  Box,
  IconButton,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { formatConsentedCustomerTripsText } from './utils'
import { colors, CONSENTED_CUSTOMER_TRIPS_TITLE } from './constants'

type PropsT = {
  numberOfTrips: number
  showInfoModal: () => void
}

const ConsentedCustomerTripsContent = ({
  numberOfTrips,
  showInfoModal
}: PropsT): JSX.Element => {
  return (
    <ListItem sx={{ px: 0 }}>
      <ListItemText
        primary={
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <Typography variant="subtitle2">
              {CONSENTED_CUSTOMER_TRIPS_TITLE}
            </Typography>
            <IconButton
              onClick={showInfoModal}
              data-testid="additionalDetailsButton"
            >
              <InfoOutlinedIcon color="info" />
            </IconButton>
          </Box>
        }
        secondaryTypographyProps={{
          variant: 'body2',
          color: numberOfTrips ? colors.PRIMARY : colors.SECONDARY,
          style: { wordWrap: 'break-word', whiteSpace: 'pre-wrap' }
        }}
        secondary={formatConsentedCustomerTripsText(numberOfTrips)}
      />
    </ListItem>
  )
}

export default ConsentedCustomerTripsContent
