import { ReactElement, useEffect, useState } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { useNavigation } from 'contexts/NavigationContext'
import { Typography, IconButton, AppBar, Toolbar, List } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import {
  useLazyGetNotificationsQuery,
  useMarkNotificationsAsReadMutation
} from 'services/notificationsApi'
import oktaTokenStorage from 'utils/okta-token-utils'
import { isStyleAdvisor } from 'utils/userPermissions'
import PageError from 'components/PageError'
import LoadingSpinner from 'components/LoadingSpinner'
import Container from 'components/MobileAndDesktopContainer'
import {
  AppointmentMetaData,
  CustomerMetaData,
  Notification,
  Type as NotificationType,
  Status as NotificationsStatus
} from 'types/Notification'
import EmptyNotifications from './components/EmptyNotifications'
import NotificationItem from './components/NotificationItem'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { NR_CLIENTELING_PAGES } from 'constants/clienteling/nrClientelingPages'
import {
  NR_NOTIFICATIONS_PAGE_CLICKED_ENDED_CUSTOMER,
  NR_NOTIFICATIONS_PAGE_CLICKED_RENEWED_CUSTOMER,
  NR_NOTIFICATIONS_PAGE_CLICKED_NEW_CUSTOMER,
  NR_NOTIFICATIONS_PAGE_CLICKED_APPOINTMENT_CREATED
} from 'constants/clienteling/newRelicEvents/nrNotificationsPage'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'

const ERROR_TITLE = 'Error retrieving your notifications.'

const Notifications = (): ReactElement => {
  const { setDisplayAppBar } = useNavigation()
  const history = useHistory<{ sourcePath: string }>()
  const [notifications, setNotifications] = useState<Notification[]>([])
  const employeeId = oktaTokenStorage
    .getEmployeeNumberFromOktaToken()
    .toString()

  const [
    lazyGetNotifications,
    { data, error, endpointName, isLoading, isError, isFetching }
  ] = useLazyGetNotificationsQuery()

  const [markNotificationsAsRead] = useMarkNotificationsAsReadMutation()

  const { isPBAppointmentsViewEnabled } = useFeatureFlags()
  const shouldShowAppointmentsNotifications =
    isPBAppointmentsViewEnabled?.active

  useEffect(() => {
    if (data?.notifications) {
      setNotifications(data.notifications)
    }
  }, [data])

  useEffect(() => {
    if (
      notifications.length &&
      notifications.some(
        (notification) => notification.status === NotificationsStatus.UNREAD
      )
    ) {
      markNotificationsAsRead()
    }
  }, [markNotificationsAsRead, notifications])

  useEffect(() => {
    lazyGetNotifications()
  }, [lazyGetNotifications])

  useEffect(() => {
    setDisplayAppBar(false)
    return () => setDisplayAppBar(true)
  }, [setDisplayAppBar])

  const goBackAction = () => {
    const goToRoute = history.location.state?.sourcePath
    if (goToRoute) {
      history.replace(goToRoute)
    } else {
      history.goBack()
    }
  }

  const consentNotificationAction = (notification: Notification) => {
    const newCustomerMetaData = notification?.meta as CustomerMetaData
    const consentType =
      newCustomerMetaData?.currentCustomerConsentType || notification.type
    const nrEventAttributes = {
      page: NR_CLIENTELING_PAGES.NOTIFICATIONS_PAGE,
      ocpId: newCustomerMetaData?.customerId
    }
    switch (consentType) {
      case NotificationType.CUSTOMER_ENDED:
        generateNewRelicLogs(
          NR_NOTIFICATIONS_PAGE_CLICKED_ENDED_CUSTOMER,
          nrEventAttributes
        )
        return history.push({
          pathname: `/customers`
        })
      case NotificationType.CUSTOMER_RENEWED:
        generateNewRelicLogs(
          NR_NOTIFICATIONS_PAGE_CLICKED_RENEWED_CUSTOMER,
          nrEventAttributes
        )
        return history.push({
          pathname: `/customers/details/${newCustomerMetaData?.customerId}`
        })
      case NotificationType.NEW_CUSTOMER:
        generateNewRelicLogs(
          NR_NOTIFICATIONS_PAGE_CLICKED_NEW_CUSTOMER,
          nrEventAttributes
        )
        return history.push({
          pathname: `/customers/details/${newCustomerMetaData?.customerId}`
        })
      default:
        return history.push({
          pathname: `/customers/details/${newCustomerMetaData?.customerId}`
        })
    }
  }

  const appointmentsNotificationAction = (notification: Notification) => {
    const appointmentMetaData = notification?.meta as AppointmentMetaData
    const nrEventAttributes = {
      page: NR_CLIENTELING_PAGES.NOTIFICATIONS_PAGE
    }
    switch (notification.type) {
      case NotificationType.APPOINTMENT_CREATED:
        generateNewRelicLogs(
          NR_NOTIFICATIONS_PAGE_CLICKED_APPOINTMENT_CREATED,
          nrEventAttributes
        )
        return history.push({
          pathname: `/appointments/${appointmentMetaData.appointmentId}`
        })
      default:
        return history.push({
          pathname: `/appointments`
        })
    }
  }

  const notificationAction = (notification: Notification) => {
    switch (notification.type) {
      case NotificationType.APPOINTMENT_CREATED:
        appointmentsNotificationAction(notification)
        break
      case NotificationType.NEW_CUSTOMER ||
        NotificationType.CUSTOMER_ENDED ||
        NotificationType.CUSTOMER_RENEWED:
        consentNotificationAction(notification)
        break
      default:
        consentNotificationAction(notification)
        break
    }
  }

  const getNotificationStaticText = (notification: Notification) => {
    switch (notification.type) {
      case NotificationType.NEW_CUSTOMER:
        return 'is now in your book'
      case NotificationType.CUSTOMER_ENDED:
        return "is no longer in your book. You can't view their profile or messages anymore."
      case NotificationType.CUSTOMER_RENEWED:
        return 'is back in your book'
      case NotificationType.APPOINTMENT_CREATED:
        return 'has a new appointment'
    }
  }

  const displayNotificationText = (notification: Notification) => {
    const notificationStaticText = getNotificationStaticText(notification) || ''
    return (
      <>
        <Typography variant="body2">
          <Typography component="span" variant="subtitle2">
            {`${notification.meta.customerName} `}
          </Typography>
          {notificationStaticText}
        </Typography>
      </>
    )
  }

  if (isStyleAdvisor()) {
    return <Redirect to={'/customers'} />
  }

  if (isLoading || isFetching) {
    return <LoadingSpinner />
  }

  return (
    <>
      <AppBar
        position="static"
        style={{ backgroundColor: 'white', boxShadow: 'none' }}
      >
        <Toolbar sx={{ marginY: '8px' }}>
          <IconButton
            onClick={goBackAction}
            size="large"
            sx={{ marginRight: '16px' }}
            data-testid="goBackButton"
          >
            <ArrowBackIosIcon sx={{ color: 'text.primary' }} />
          </IconButton>
          <Typography variant="h6" color="text.primary">
            Notifications
          </Typography>
        </Toolbar>
      </AppBar>
      <Container>
        {isError ? (
          <PageError
            errorTitle={ERROR_TITLE}
            errorDetails={{
              endpoint: endpointName,
              errorData: error,
              identifiers: {
                customerId: 'NO-DATA',
                curationId: 'NO-DATA',
                employeeId,
                shoppingSessionId: 'NO-DATA'
              }
            }}
          />
        ) : notifications.length ? (
          <List>
            {notifications.map(
              (notification) =>
                (notification.type !== NotificationType.APPOINTMENT_CREATED ||
                  shouldShowAppointmentsNotifications) && (
                  <NotificationItem
                    key={notification.id}
                    notification={notification}
                    notificationAction={notificationAction}
                    NotificationText={() =>
                      displayNotificationText(notification)
                    }
                  />
                )
            )}
          </List>
        ) : (
          <EmptyNotifications />
        )}
      </Container>
    </>
  )
}

export default Notifications
