import { AddressT } from 'types/ConsentedCustomer'
import { ALTERATIONS_BENEFITS } from '../constants'

export const formatLoyaltyStatus: (loyalty: string) => string = (
  loyalty: string
) => {
  if (!loyalty) return ''
  const arrayString = loyalty?.split(' ')
  const loyaltyWithFormat =
    arrayString.length > 1 ? arrayString[1] : arrayString[0]
  return loyaltyWithFormat
}

export const formatCustomerAddress = (address: AddressT): string => {
  const { firstName, lastName, line1, line2, postalCode, city, state } = address
  return `${firstName} ${lastName}\n${line1}${
    line2 ? ',' : ''
  } ${line2}\n${city}, ${state} ${postalCode}`
}

export const formatCustomerAddressForModal = (address: AddressT): string => {
  const { firstName, lastName, line1, line2, postalCode, city, state } = address
  return `${firstName} ${lastName}\n${line1}\n${
    line2 ? `${line2}\n` : ''
  }${city}, ${state} ${postalCode}`
}

type AlterationsBenefitParams = {
  loyaltyStatus: string
  alterationBalance: number
  nordstromCardHolder: boolean
}

export const displayAlterationsBenefit = ({
  alterationBalance,
  loyaltyStatus,
  nordstromCardHolder
}: AlterationsBenefitParams): string => {
  switch (loyaltyStatus) {
    case 'Nordy Icon':
      return ALTERATIONS_BENEFITS.ICON
    case 'Nordy Influencer':
    case 'Nordy Ambassador':
      return nordstromCardHolder
        ? ALTERATIONS_BENEFITS.REGULAR(alterationBalance)
        : ALTERATIONS_BENEFITS.NORDY_WITHOUT_CARD
    case 'Nordy Member':
      return ALTERATIONS_BENEFITS.MEMBER
    default:
      return ALTERATIONS_BENEFITS.NONE
  }
}
