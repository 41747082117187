import { ReactElement } from 'react'
import { Grid } from '@mui/material'
import RecentItemCard from '../../../../../RecentItemsDrawer/components/RecentItemCard/RecentItemCard'
import { OfferPriceDetailsT } from 'types/Product'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'
import { BeautyRestockOrderItemT } from 'types/Orders'
import { formatNumericPrice } from 'utils/formatPrice'

export type PropsT = {
  handleClickForMiniPDP: ({
    webStyleId,
    miniPDPOpenedFrom,
    rmsSku
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    rmsSku: string
  }) => void
  beautyRestockData?: BeautyRestockOrderItemT[]
}

export const formatBeautyPrice = (priceDetails: OfferPriceDetailsT): number => {
  const { units = 0, nanos = 0 } = priceDetails
  return formatNumericPrice(units, nanos)
}

const BeautyRestocks = (props: PropsT): ReactElement => {
  const { handleClickForMiniPDP, beautyRestockData } = props

  return (
    <Grid container>
      {beautyRestockData?.map((item: BeautyRestockOrderItemT) => {
        if (item.rmsSku || item.webStyleId) {
          return (
            <RecentItemCard
              key={item.rmsSku}
              brandName={item.brandName}
              clearancePrice={formatBeautyPrice(
                item.currentPrice?.clearance?.price || {}
              )}
              color={item.color}
              currentPriceType={item.currentPrice?.currentPriceType}
              enticements={item.currentEnticements}
              imageUrl={item.imageUrl}
              itemName={item.itemName}
              itemNumber={item.itemNumber}
              onClickForMiniPDP={handleClickForMiniPDP}
              promotionPrice={formatBeautyPrice(
                item.currentPrice?.promotion?.price || {}
              )}
              regularPrice={formatBeautyPrice(
                item.currentPrice?.regular?.price || {}
              )}
              rmsSku={item.rmsSku}
              size={item.size}
              webStyleId={item.webStyleId}
              lastPurchased={String(item.orderDate)}
              miniPDPOpenedFrom="BeautyRestocksDrawer"
            />
          )
        }
      })}
    </Grid>
  )
}

export default BeautyRestocks
