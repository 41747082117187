import { ReactElement, useEffect, useCallback, useState, useRef } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import { Typography, IconButton, AppBar, Toolbar } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import CreateIcon from '@mui/icons-material/Create'
import Box from '@mui/material/Box'
import { useNavigation } from 'contexts/NavigationContext'
import { useTwilioClient } from 'contexts/TwilioContext'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import {
  twilioSelectors,
  twilioUtils,
  twilioActions
} from 'services/twilioSlice'
import { isMessagingUser } from 'utils/userPermissions'
import LoadingSpinner from 'components/LoadingSpinner'
import Container from 'components/MobileAndDesktopContainer'
import EmptyConversationList from './components/EmptyConversationList'
import ConversationList from './components/ConversationList'
import PageError from 'components/PageError'
import CustomersDialog from '../../components/CustomersDialog'

const ERROR_TITLE = 'Error retrieving your messages.'
const PAGE_SIZE = 50

const CustomerConversations = (): ReactElement => {
  const { setDisplayAppBar } = useNavigation()
  const page = useRef(1)
  const {
    client,
    isError: isTwilioClientError,
    error: twilioClientError
  } = useTwilioClient()
  const history = useHistory()
  const appDispatch = useAppDispatch()
  const [isCustomersDialogOpen, setIsCustomersDialogOpen] = useState(false)

  const canUserViewMessaging = isMessagingUser()

  const [shouldDisplayMoreConversations, setShouldDisplayMoreConversations] =
    useState(false)
  const [pageOffset, setPageOffset] = useState(page.current * PAGE_SIZE)
  const [isDoneSettingRecentMessages, setIsDoneSettingRecentMessages] =
    useState(false)

  const isLoading = useAppSelector(twilioSelectors.isLoadingSelector)

  const sortedConversationIds = useAppSelector(
    twilioSelectors.sortedConversationIds
  )

  const sortedConversationsWithRecentMessage = useAppSelector(
    twilioSelectors.sortedConversationsWithRecentMessage
  ).slice(0, pageOffset)

  const goBackAction = () => {
    history.goBack()
  }

  const goToMessages = (conversationSid: string) => {
    history.push({ pathname: `/messages/${conversationSid}` })
  }

  const openCustomerSearch = () => {
    setIsCustomersDialogOpen(true)
  }

  const setConversationMessagesInRedux = useCallback(async () => {
    if (
      !client ||
      !sortedConversationIds.length ||
      isDoneSettingRecentMessages
    ) {
      return
    }
    const recentMessagesByConversationId =
      await twilioUtils.getRecentMessagesByConversationId(
        client,
        sortedConversationIds.slice(pageOffset - PAGE_SIZE, pageOffset)
      )
    appDispatch(
      twilioActions.setMostRecentMessages({
        messagesById: recentMessagesByConversationId
      })
    )
    setIsDoneSettingRecentMessages(true)
    setShouldDisplayMoreConversations(false)
    appDispatch(twilioActions.setIsLoading({ value: false }))
  }, [
    appDispatch,
    client,
    sortedConversationIds,
    pageOffset,
    isDoneSettingRecentMessages
  ])

  useEffect(() => {
    if (isTwilioClientError) {
      return setIsDoneSettingRecentMessages(true)
    }
    setConversationMessagesInRedux()
  }, [setConversationMessagesInRedux, isTwilioClientError])

  useEffect(() => {
    setDisplayAppBar(false)
    return () => setDisplayAppBar(true)
  }, [setDisplayAppBar])

  useEffect(() => {
    const handleScrollBottom = () => {
      if (
        sortedConversationsWithRecentMessage.length === pageOffset &&
        isDoneSettingRecentMessages
      ) {
        setShouldDisplayMoreConversations(true)
        setIsDoneSettingRecentMessages(false)
        page.current = page.current + 1
        setPageOffset(page.current * PAGE_SIZE)
      }
    }
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        if (
          window.innerHeight + Math.round(window.scrollY) >=
          document.body.offsetHeight
        ) {
          handleScrollBottom()
        }
      }
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [
    pageOffset,
    isDoneSettingRecentMessages,
    sortedConversationsWithRecentMessage.length
  ])

  if (!canUserViewMessaging) {
    return <Redirect to="/customers" />
  }

  if (isLoading && !isDoneSettingRecentMessages) {
    return <LoadingSpinner />
  }

  return (
    <>
      <AppBar
        position="static"
        style={{ backgroundColor: 'white', boxShadow: 'none' }}
      >
        <Toolbar sx={{ marginY: '8px' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flexGrow: 1
            }}
          >
            <IconButton
              onClick={goBackAction}
              size="large"
              sx={{ marginRight: '16px' }}
            >
              <ArrowBackIosIcon sx={{ color: 'text.primary' }} />
            </IconButton>
            <Typography variant="h6" color="text.primary">
              Messages
            </Typography>
          </Box>
          <IconButton
            data-testid="createMessageIcon"
            onClick={openCustomerSearch}
          >
            <CreateIcon sx={{ color: 'text.primary' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      {isTwilioClientError ? (
        <PageError
          errorTitle={ERROR_TITLE}
          errorDetails={{ errorData: twilioClientError }}
        />
      ) : sortedConversationsWithRecentMessage.length ? (
        <Container>
          <ConversationList
            conversations={sortedConversationsWithRecentMessage}
            goToMessages={goToMessages}
          />
        </Container>
      ) : (
        <EmptyConversationList
          setIsCustomersDialogOpen={setIsCustomersDialogOpen}
        />
      )}
      {shouldDisplayMoreConversations && <LoadingSpinner />}
      <CustomersDialog
        isDialogOpen={isCustomersDialogOpen}
        onClose={() => setIsCustomersDialogOpen(false)}
        isCreateStyleBoard={false}
      />
    </>
  )
}

export default CustomerConversations
