import { ReactElement } from 'react'
import { Box, Typography, Avatar, Card, Grid, CardHeader } from '@mui/material'
import { formatPhoneNumber } from 'utils/formatPhoneNumber'
import nordstromAvatar from 'assets/NAvatar.png'
import { GetStylistProfilesQueryDataT } from 'types/StylistProfile'
import { EmployeeT } from 'types/Employee'
import theme from '@ndls/enterprise/v5/theme'

type PropsT = {
  stylistData: GetStylistProfilesQueryDataT | undefined
  employeeData:
    | {
        employee: EmployeeT
      }
    | undefined
}

const StylistInfoCard = ({
  stylistData,
  employeeData
}: PropsT): ReactElement => {
  const displayName =
    stylistData?.stylistProfiles[0]?.displayName ||
    employeeData?.employee?.preferredName ||
    ''
  const mobileNumber = employeeData?.employee?.mobile
    ? formatPhoneNumber(employeeData?.employee?.mobile)
    : 'No saved number'

  const avatarStyle = {
    width: '40px',
    height: '40px',
    backgroundColor: theme.palette.grey[500]
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: '#F9FAFA',
        padding: '15px',
        width: '100%'
      }}
    >
      <Box sx={{ margin: 'auto', maxWidth: '920px' }}>
        <Card variant="outlined">
          <CardHeader
            title={
              <Grid container alignItems="center" spacing={2}>
                <Grid item>
                  <Avatar
                    src={
                      stylistData?.stylistProfiles[0]?.imageUrl ||
                      nordstromAvatar
                    }
                    sx={avatarStyle}
                    alt="profile picture"
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="subtitle2">{displayName}</Typography>
                  <Typography
                    variant="body2"
                    color="#6B777B"
                    sx={{ display: 'flex', alignItems: 'center' }}
                  >
                    {mobileNumber}
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        </Card>
      </Box>
    </Box>
  )
}

export default StylistInfoCard
