import { BudgetRangeT } from 'types/Curation'
import CategoryCarousel from './CategoryCarousel'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'
import RecommendationsTemplate from '../RecommendationsTemplate'

interface PropsT {
  curationId: number
  customerName?: string
  occasions?: string[]
  departments?: string[]
  budgetRange?: BudgetRangeT | null
  onClickForMiniPDP: ({
    webStyleId,
    miniPDPOpenedFrom
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
  }) => void
}

const StartBoardRecommendations = ({
  curationId,
  customerName,
  occasions,
  departments,
  budgetRange,
  onClickForMiniPDP
}: PropsT): JSX.Element => {
  const { isInitialRecTrayEnabled } = useFeatureFlags()

  if (
    !isInitialRecTrayEnabled?.active ||
    (occasions?.length === 0 && departments?.length === 0)
  ) {
    return <></>
  }

  const recommendationCategories = [
    'All',
    'Tops',
    'Bottoms',
    'Shoes',
    'Accessories'
  ]
  const maxBudget = Math.max(
    Number(budgetRange?.tops),
    Number(budgetRange?.bottoms),
    Number(budgetRange?.shoes)
  )
  const budgetPreference = [
    maxBudget,
    budgetRange?.tops,
    budgetRange?.bottoms,
    budgetRange?.shoes,
    maxBudget
  ]
  const departmentsSearchTerm = departments?.join(' ')
  let occasionsSearchTerm = occasions?.join(' ')
  occasionsSearchTerm = occasionsSearchTerm?.includes('Weekend')
    ? 'Casual wear'
    : occasionsSearchTerm

  const recommendationSearchTerms = [
    `${departmentsSearchTerm} ${occasionsSearchTerm}`,
    `${departmentsSearchTerm} Tops ${occasionsSearchTerm}`,
    `${departmentsSearchTerm} Bottoms ${occasionsSearchTerm}`,
    `${departmentsSearchTerm} Shoes ${occasionsSearchTerm}`,
    `${departmentsSearchTerm} Accessories ${occasionsSearchTerm}`
  ]

  const occasionsString = occasions?.join(' ')

  return (
    <RecommendationsTemplate
      renderedFrom="StartBoardRecommendations"
      title={
        customerName ? `Items for ${customerName}` : 'Items for this customer'
      }
      description={`Occasion: ${occasionsString}`}
      categories={recommendationCategories}
      renderCategoryCarousel={(index) => (
        <CategoryCarousel
          onClickForMiniPDP={onClickForMiniPDP}
          curationId={curationId}
          searchTerm={recommendationSearchTerms[index]}
          budgetRange={Number(budgetPreference[index])}
        />
      )}
    />
  )
}

export default StartBoardRecommendations
