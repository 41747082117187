import { ReactElement } from 'react'
import { Button, Box, Container, Typography } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ChevronRight'

import GridPanel from 'components/Tabs/GridPanel'
import OrderInformation from '../OrderInformation'

import { OrderT } from 'types/Orders'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import {
  NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS,
  NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_TRACK_PACKAGE
} from 'constants/clienteling/newRelicEvents/nrCustomerDetailsPage'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { AttributedItems } from 'pages/SnapshotDrawer/pages/RecentPurchasesList/Purchases'

export type PropsT = {
  order: OrderT
  goToDetails: (orderId: string) => void
  fromSnapShot?: boolean
  customerId: string
  isPurchasesToggleOn?: boolean
  attributedItems?: AttributedItems[]
}

const RecentPurchasesItem = (props: PropsT): ReactElement => {
  const {
    order,
    goToDetails,
    fromSnapShot,
    customerId,
    isPurchasesToggleOn,
    attributedItems
  } = props
  const { orderId, productsAndStatus, totalItemCount } = order
  const featureFlags = useFeatureFlags()
  const { isPurchasesWithYouEnabled } = featureFlags

  const numberOfAttributedItems = attributedItems?.length || 0
  const numberItemsLeftInOrder = totalItemCount - numberOfAttributedItems
  const itemsText = `item${
    numberItemsLeftInOrder !== 1 ? 's' : ''
  } not purchased with you`

  return (
    <Container
      sx={{
        maxWidth: {
          lg: '100%'
        }
      }}
      style={{ cursor: 'pointer' }}
      onClick={() => goToDetails(orderId)}
    >
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <OrderInformation
          displayItemCount={false}
          order={order}
          isPurchasesToggleOn={isPurchasesToggleOn}
          isOrderDetailsPage={false}
        />
        <ArrowRightIcon
          fontSize="medium"
          style={{
            marginTop: 10,
            cursor: 'pointer',
            position: 'absolute',
            right: 24
          }}
        />
      </Box>
      {productsAndStatus.map((orderStatus, index) => (
        <Box key={index}>
          <Box mt={2} mb={1}>
            <Typography variant="overline" color="black" fontWeight={450}>
              {orderStatus.deliveryStatus}
            </Typography>
            {orderStatus.trackingUrl && (
              <Box mt={2} mb={1}>
                <Button
                  onClick={() => {
                    !fromSnapShot &&
                      generateNewRelicLogs(
                        NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_TRACK_PACKAGE,
                        {
                          page: NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.PURCHASES,
                          orderId: order?.orderId,
                          ocpId: customerId
                        }
                      )
                  }}
                  component="a"
                  href={orderStatus.trackingUrl}
                  target="_blank"
                  rel="noopener"
                  variant="outlined"
                  sx={{
                    fontSize: '14px',
                    padding: '4px 10px'
                  }}
                >
                  Track Package
                </Button>
              </Box>
            )}
          </Box>
          <GridPanel
            fromSnapShot={fromSnapShot}
            products={orderStatus.products}
          />
        </Box>
      ))}
      {isPurchasesToggleOn &&
        isPurchasesWithYouEnabled?.active &&
        !!numberItemsLeftInOrder && (
          <Box sx={{ marginTop: '6%', fontSize: '16px' }}>
            + {numberItemsLeftInOrder} {itemsText}
          </Box>
        )}
    </Container>
  )
}

export default RecentPurchasesItem
