import { ReactElement } from 'react'
import { Typography, Box, Container, Divider } from '@mui/material'
import { AppointmentListAppointmentT } from 'types/Appointments'
import AppointmentCard from './AppointmentCard'
import moment from 'moment'

const AppointmentsDate = ({
  appointmentDate
}: {
  appointmentDate: {
    date: string
    appointments: AppointmentListAppointmentT[]
  }
}): ReactElement => {
  const appointmentDateString = appointmentDate.date
  const relationalDay = moment(appointmentDateString).calendar(null, {
    sameDay: '[Today], LL',
    sameElse: 'LL'
  })
  const apptDate = moment(appointmentDateString).format('MMM D')
  const weekDay = new Date(appointmentDateString)
    .toLocaleString('en', { weekday: 'long' })
    .toString()
  const day =
    relationalDay.split(' ')[0] === 'Today,'
      ? relationalDay.split(' ')[0]
      : weekDay + ','

  const fullDate = day + ' ' + apptDate
  return (
    <>
      <Divider
        sx={{
          borderBottomWidth: 16,
          borderColor: '#F9FAFA',
          my: 3
        }}
      />
      <Container>
        <Box>
          <Typography variant="h6">{fullDate}</Typography>
          {appointmentDate.appointments.map((appointment) => (
            <AppointmentCard
              key={appointment.appointmentId}
              appointment={appointment}
            />
          ))}
        </Box>
      </Container>
    </>
  )
}

export default AppointmentsDate
