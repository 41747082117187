import { ReactElement } from 'react'
import { Button, Grid, Stack, Typography } from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import { visuallyHidden } from '@mui/utils'
import ItemImage from 'components/ItemImage'
import PriceRange from 'components/PriceRange'
import { FashionMapSearchResultT } from 'types/FashionMapSearch'

const FashionMapSearchResultCard = (
  props: FashionMapSearchResultT & { onClickForPdp: (styleId: string) => void }
): ReactElement => {
  const { brand, image, itemName, onClickForPdp, styleId, price } = props

  return (
    <Grid container display="flex" alignItems="center" sx={{ mb: 3 }}>
      <Grid item xs={10} display="flex">
        <Button
          aria-label="view product details"
          onClick={() => onClickForPdp(styleId)}
          sx={{ maxWidth: '74px', padding: 0 }}
        >
          <ItemImage
            autoHeight={true}
            imageAltText={
              image.altText ? image.altText : `${brand} ${itemName}`
            }
            imageUrl={image.url}
          />
        </Button>
        <Stack sx={{ ml: 1 }}>
          <Typography
            mb={0.5}
            variant="body2"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {brand && `${brand}`} {itemName && `${itemName}`}
          </Typography>
          <Typography
            mb={1}
            variant="body2"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '1',
              WebkitBoxOrient: 'vertical'
            }}
          >
            {image.color}
          </Typography>
          <PriceRange price={price} />
          <Typography color="text.secondary" variant="caption" mt={1}>
            {styleId && `StyleId #${styleId}`}
          </Typography>
        </Stack>
      </Grid>
      <Grid item display="flex" justifyContent="center" xs={2}>
        <Button
          onClick={() => onClickForPdp(styleId)}
          startIcon={<LaunchIcon />}
        >
          View <Typography sx={visuallyHidden}>product details</Typography>
        </Button>
      </Grid>
    </Grid>
  )
}

export default FashionMapSearchResultCard
