export const MIN_NUMBER_OF_ITEMS = 1

export const MAX_TITLE_LENGTH = 125
export const RESET_LENGTH = 2
export const TITLE_MAX_LENGTH_ERROR_TEXT = `Title cannot exceed ${MAX_TITLE_LENGTH} characters`
export const TITLE_REQUIRED_TEXT = 'Title is required'
export const MAX_DESCRIPTION_LENGTH = 1000
export const ITEM_DESCRIPTION_LABEL = 'Description (optional)'
export const DESCRIPTION_FORM_HELPER_TEXT = `Max ${MAX_DESCRIPTION_LENGTH} characters`
export const ITEM_NOTE_LABEL = 'Notes (optional)'

export const SET_TITLE_REQUIRED_ERROR = 'set-title-required'
export const UPDATE_NOTE = 'update-note'
export const UPDATE_TITLE = 'update-title'
export const UPDATE_DESCRIPTION = 'update-description'
export const SET_GENERATED_TITLE_AND_DESCRIPTION =
  'set-generated-title-and-description'
export const SET_IN_PROGRESS_CURATION = 'set-in-progress-curation'
export const SET_ORDERED_ITEMS = 'set-ordered-items'
export const SET_SHOPPING_SESSION_TOKEN = 'set-shopping-session-token'
export const SET_FASHION_MAP_SEARCH_DRAWER_OPEN =
  'set-fashion-map-search-drawer-open'
export const UPDATE_WISHLIST_ADDS = 'update-wishlist-adds'
export const UPDATE_SCANNER_ADDS = 'update-scanner-adds'
export const UPDATE_SCANNER_VIEWS = 'update-scanner-views'
export const UPDATE_FASHION_MAP_VIEWS = 'update-fashion-map-views'
export const UPDATE_SEED_ITEM_REC_VIEWS = 'update-seed-item-rec-views'
export const UPDATE_START_ITEM_REC_VIEWS = 'update-start-item-rec-views'
export const SET_SEED_ITEM_RECS_TERMS = 'set-seed-item-rec-terms'
export const UPDATE_SNAPSHOT_PURCHASE_HISTORY_ADDS =
  'update-snapshot-purchase-history-adds'
export const UPDATE_SNAPSHOT_PURCHASE_HISTORY_VIEWS =
  'update-snapshot-purchase-history-views'
export const UPDATE_CUSTOMER_LIST_WISHLIST_ADDS =
  'update-customer-list-wishlist-adds'
export const UPDATE_CUSTOMER_LIST_PURCHASE_HISTORY_ADDS =
  'update-customer-list-purchase-history-adds'
export const UPDATE_MINI_PDP_RECENT_ITEM_ADDS =
  'update-mini-pdp-recent-item-adds'
export const UPDATE_MINI_PDP_SEED_ITEM_REC_ADDS =
  'update-mini-pdp-seed-item-rec-adds'
export const UPDATE_MINI_PDP_BEAUTY_RESTOCK_ADDS =
  'update-mini-pdp-beauty-restock-adds'
export const UPDATE_MINI_PDP_START_BOARD_REC_ADDS =
  'update-mini-pdp-start-board-rec-adds'
export const UPDATE_MINI_PDP_SCANNER_DRAWER_ADDS =
  'update-mini-pdp-scanner-drawer-adds'
export const UPDATE_MINI_PDP_HOLIDAY_GIFTS_ADDS =
  'update-mini-pdp-holiday-gifts-adds'

export const DEFAULT_IFRAME_PATH = ''
export const IFRAME_PATH = '/review/build'
