import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  CustomerT,
  ServerSingleCustomerResponseT,
  ServerMultipleCustomersResponseT,
  CustomerLoyaltyLevelResponseT
} from 'types/Customer'
import {
  FashionMapFilterResultsT,
  FashionMapSearchResultsT,
  GendersT
} from 'types/FashionMapSearch'
import oktaTokenStorage from 'utils/okta-token-utils'
import setStandardHeaders from 'utils/set-standard-headers'
import { FashionPillarT, PasFashionPillarT } from 'types/FashionPillar'
import { BrandT, ServerBrandsT } from 'types/Brands'
import {
  OrderI,
  OrdersI,
  OrderStatusT,
  OrderT,
  PHOrderSchemaT,
  ProductsAndStatusT,
  OrdersT,
  BeautyRestockOrderItemT,
  BeautyRestockOrdersT
} from 'types/Orders'
import { TransformedWishList } from 'types/WishList'
import {
  EmployeeProductT,
  OfferStyleProductT,
  SkuFilterGroups,
  StyleProductT
} from 'types/Product'
import {
  CustomerDossierT,
  DossierValidationResultT
} from 'types/CustomerDossier'
import { formatOfferProductToEmployeeProduct } from 'utils/formatOfferProduct'
import {
  CurationItemT,
  CurationT,
  GetCurationsQueryDataT,
  GetStyleBoardsQueryDataT,
  GetStyleBoardsQueryParamsT,
  ShoppingTokenDataT
} from 'types/Curation'
import { getTransformedWishListResponse } from './utils'
import { RecommendationsT } from 'types/SeedItemRecommendations'
import { PolarisSearchProductsT } from 'types/PolarisProduct'
import { ProductGenderValues } from 'types/Checkout'
import {
  GetCurationAttributionsQueryDataT,
  GetCurationAttributionsQueryParamsT
} from 'types/CurationAttribution'

const DEFAULT_MAX_PH_ORDERS = 10
const DEFAULT_SKIP_VALUE = 0
const DEFAULT_SORT_BY = 'DATE_CREATED'
const DEFAULT_SORT_ORDER = 'DESCENDING'

export type ScanditConfigT = { libraryLocation: string; licenseKey: string }

export const employeeExperienceApi = createApi({
  reducerPath: 'employeeExperienceApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_EMPLOYEE_EXPERIENCE_API_URL,
    paramsSerializer(params) {
      const data = Object.entries(params)
        .reduce((acc, [key, value]) => {
          if (value && Array.isArray(value)) {
            value.forEach((v) => acc.append(`${key}[]`, v))
          } else if (value) {
            acc.append(key, value)
          }

          return acc
        }, new URLSearchParams())
        .toString()
      return data
    },
    prepareHeaders: (headers) => {
      setStandardHeaders({ headers: headers })
      const accessToken = oktaTokenStorage.getOktaAccessToken()
      const idToken = oktaTokenStorage.getOktaIdToken()
      headers.set('accept', 'application/json')

      if (accessToken) {
        const employeeId = oktaTokenStorage.getEmployeeNumberFromOktaToken()
        headers.set('Employee-Id', employeeId)
        headers.set('Nord-Okta-Id-Token', idToken)
        headers.set('Nord-Okta-Token', accessToken)
        headers.set('Authorization', `Bearer ${accessToken}`)
      }

      return headers
    }
  }),
  tagTypes: ['StyleBoards'],
  endpoints: (builder) => ({
    fashionMapFilters: builder.mutation<
      FashionMapFilterResultsT,
      { productTypes?: string[]; brands?: string[]; genders?: GendersT[] }
    >({
      query: ({ productTypes, brands, genders }) => ({
        url: '/fashion-map/filter-options',
        method: 'POST',
        body: {
          include: {
            productTypes,
            brands,
            // TODO: Maybe we do not need this
            genders
          }
        }
      })
    }),
    fashionMapSearch: builder.mutation<
      FashionMapSearchResultsT,
      {
        filterByBrands?: string[]
        filterByProductTypes?: string[]
        gender?: GendersT
        resultSize?: number
        searchText: string
      }
    >({
      query: ({
        filterByBrands,
        filterByProductTypes,
        gender,
        resultSize = 20,
        searchText
      }) => ({
        url: '/fashion-map/search',
        method: 'POST',
        body: {
          filterByBrands,
          filterByProductTypes,
          gender,
          resultSize,
          searchText
        }
      })
    }),
    getCustomer: builder.query<CustomerT, string>({
      query: (customerId: string) => ({
        url: `/customers/${customerId}`
      }),
      transformResponse: (response: ServerSingleCustomerResponseT) => {
        const customer: CustomerT = {
          firstName: response?.customer?.firstName || '',
          lastName: response?.customer?.lastName || '',
          shopperId: response?.customer?.programs?.WEB || ''
        }

        return customer
      }
    }),
    getCustomers: builder.query<ServerMultipleCustomersResponseT, string[]>({
      query: (ids: string[]) => {
        const idsQuery = ids.map((id) => `customerIds[]=${id}`).join('&')
        return {
          url: `/customers?${idsQuery}`
        }
      }
    }),
    getScanditConfig: builder.query<ScanditConfigT, void>({
      query: () => {
        return {
          url: `/scandit/config`
        }
      }
    }),
    getPasFashionPillar: builder.query({
      query: (customerId: string) => ({
        url: `/customers/${customerId}/fashion-pillars`
      }),
      transformResponse: (response: PasFashionPillarT) => {
        const { pillars } = response.preferredFashionPillars?.[0].attribute
        const sortedFashionPillars: FashionPillarT[] = pillars.sort((a, b) => {
          return b.percent - a.percent
        })

        return {
          primaryPillar: sortedFashionPillars?.shift(),
          otherPillars: sortedFashionPillars
        }
      }
    }),
    getPreferredBrands: builder.query({
      query: (customerId: string) => ({
        url: `/customers/${customerId}/preferred-brands`
      }),
      transformResponse: (response: ServerBrandsT) => {
        const brands: BrandT[] = response.preferredBrands?.[0].attribute.brands
        return { brands: brands || [] }
      }
    }),
    getCustomerLoyalty: builder.query({
      query: (customerId: string) => ({
        url: `/customers/${customerId}/loyalty`
      }),
      transformResponse: (response: CustomerLoyaltyLevelResponseT) => {
        return {
          loyaltyLevel: response?.customer?.loyaltyLevel,
          cardMember: response?.customer?.cardMember,
          eligibilityDate: response?.customer?.eligibilityDate
        }
      }
    }),
    getDefaultWishListData: builder.query<
      TransformedWishList,
      Record<string, string>
    >({
      query: ({
        shopperId,
        sortBy = DEFAULT_SORT_BY,
        sortOrder = DEFAULT_SORT_ORDER
      }) => ({
        url: `customers/${shopperId}/wishlist/default?sortBy=${sortBy}&sortOrder=${sortOrder}`
      }),
      transformResponse: getTransformedWishListResponse
    }),
    getWishListDataById: builder.query<
      TransformedWishList,
      Record<string, string>
    >({
      query: ({ shopperId, wishListId }) => ({
        url: `customers/${shopperId}/wishlist/${wishListId}`
      }),
      transformResponse: getTransformedWishListResponse
    }),
    getAvailabilityFromSkus: builder.query<
      { availableSkus: Array<string> },
      { store: string; skus: Array<string> }
    >({
      query: ({ skus, store }) => ({
        url: '/stores/availability',
        method: 'POST',
        body: {
          store,
          skus
        }
      })
    }),
    getPurchaseHistory: builder.query<OrdersT, PHOrderSchemaT>({
      query: ({
        customerId,
        orderCount = DEFAULT_MAX_PH_ORDERS,
        skip = DEFAULT_SKIP_VALUE
      }: PHOrderSchemaT) => ({
        url: `/customers/${customerId}/orders?skip=${skip}&orderCount=${orderCount.toString()}`
      }),
      transformResponse: (response: OrdersI) => {
        const orders: OrderT[] = []
        const actualToDateOrders = response.actualToDateOrders
        if (response.orders) {
          const ordersFromServer: OrderI[] = response.orders
          ordersFromServer.forEach((_order: OrderI) => {
            const productsAndStatus: ProductsAndStatusT[] = []
            let totalItemCount = 0
            _order.status?.forEach((status) => {
              totalItemCount += status.items?.length || 0
              const products = status.items?.map((product) => ({
                name: product.productName || '',
                brandName: product.brandName || '',
                size: product.size || '',
                color: product.color || '',
                purchasePrice: product.purchasePrice || 0,
                id: product.id || '',
                styleNumber: product.styleNumber || '',
                style: product.style || 0,
                discount: product.discount || 0,
                imageUrl: product.imageUrl || '',
                description: product.description || '',
                isSkuAvailable: product.isSkuAvailable || false
              }))

              const enumKey = status?.deliveryStatusCode || 'UNKNOWN'
              productsAndStatus.push({
                sortValue:
                  OrderStatusT[enumKey as keyof typeof OrderStatusT] ||
                  OrderStatusT.UNKNOWN,
                deliveryStatus: status.deliveryStatus || '',
                products: products || [],
                trackingUrl: status.trackingUrl
              })
            })

            productsAndStatus.sort(
              (first, second) => first.sortValue - second.sortValue
            )

            const order: OrderT = {
              orderType: _order.orderType || '',
              orderId: _order.orderId || '',
              orderDate: _order.orderDate || '',
              totalItemCount: totalItemCount,
              itemSubtotal: _order.itemSubTotal || 0,
              shippingCharge: _order.shippingCharge || 0,
              employeeDiscount: _order.employeeDiscount || 0,
              salesTax: _order.salesTax || 0,
              totalPrice: _order.totalPrice || 0,
              storeName: _order.purchaseStore?.name || '',
              billingInfo: {
                city: _order.billingAddress?.city || '',
                state: _order.billingAddress?.state || '',
                country: _order.billingAddress?.country || '',
                tenderType: _order.payments?.[0]?.tenderType || ''
              },
              productsAndStatus: productsAndStatus
            }

            orders.push(order)
          })
        }
        return { actualToDateOrders: actualToDateOrders, orders }
      }
    }),
    getBeautyPurchases: builder.query<
      BeautyRestockOrderItemT[],
      PHOrderSchemaT
    >({
      query: ({ customerId }) => ({
        url: `/customers/${customerId}/get-beauty-purchases`,
        method: 'POST'
      }),
      transformResponse: (response: BeautyRestockOrdersT) => {
        const orders = [] as BeautyRestockOrderItemT[]
        response.orders?.map((order) => {
          return {
            item: order.status?.map((items) => {
              return items.items?.map((item) => {
                orders.push({
                  brandName: item.brandName,
                  color: item.color,
                  currentEnticements: item.currentEnticements,
                  currentPrice: item.currentPrice,
                  id: item.id,
                  imageUrl: item.imageUrl,
                  isSkuAvailable: !!item.isSkuAvailable,
                  itemName: item.productName,
                  itemNumber: item.styleNumber,
                  orderDate: order.orderDate
                    ? new Date(order.orderDate).toLocaleDateString('en-US')
                    : '',
                  rmsSku: item.id,
                  size: item.size,
                  webStyleId: String(item.style)
                })
              })
            })
          }
        })
        return orders
      }
    }),
    getItemByUPC: builder.mutation<EmployeeProductT, string>({
      query: (upc: string) => ({
        url: `/products/upc/${upc}`,
        method: 'GET'
      }),
      transformResponse: formatOfferProductToEmployeeProduct
    }),
    generateTitleAndDescription: builder.mutation<
      { title: string; description: string },
      {
        checkoutBagData: CurationItemT[]
        curationId: number
        curation: CurationT
        customerFirstName?: string
      }
    >({
      query: ({
        checkoutBagData,
        curationId,
        curation,
        customerFirstName
      }) => ({
        url: `/curations/${curationId}/generate-curation-text`,
        method: 'POST',
        body: {
          checkoutBagData,
          curation,
          customerFirstName
        }
      })
    }),
    getStyleBoards: builder.query<
      GetStyleBoardsQueryDataT,
      Partial<GetStyleBoardsQueryParamsT> &
        Pick<GetStyleBoardsQueryParamsT, 'state'>
    >({
      query: (arg) => {
        const {
          state,
          employeeId,
          customerId,
          orderBy,
          perPage = undefined,
          page = undefined
        } = arg
        return {
          url: `/style-boards`,
          method: 'GET',
          params: {
            state,
            employeeId,
            ocpId: customerId,
            orderBy,
            perPage,
            page
          }
        }
      },
      providesTags: ['StyleBoards']
    }),
    startCuration: builder.mutation<
      CurationT,
      { employeeId: string; lifoEnabled?: boolean; occasionGroup?: string }
    >({
      query: (body) => ({
        url: `/curations/start`,
        method: 'POST',
        body: body
      })
    }),
    startPreferredEmployeeCuration: builder.mutation<
      CurationT,
      { employeeId: string; curationId: string | number }
    >({
      query: (body) => ({
        url: '/curations/start-preferred-employee',
        method: 'POST',
        body: body
      })
    }),
    generateCustomerDossier: builder.mutation<
      CustomerDossierT,
      { customerId: string; shopperId?: string; curationId?: number | null }
    >({
      query: ({ customerId, shopperId, curationId }) => ({
        url: `/customers/${customerId}/dossier`,
        method: 'POST',
        body: { shopperId, curationId }
      })
    }),
    validateCustomerDossier: builder.mutation<
      DossierValidationResultT,
      {
        customerId: string
        shopperId?: string
        curationId?: number
        customerDossier: CustomerDossierT
      }
    >({
      query: ({ customerId, shopperId, curationId, customerDossier }) => ({
        url: `/customers/${customerId}/validateDossier`,
        method: 'POST',
        body: { shopperId, curationId, customerDossier }
      })
    }),
    retrieveShoppingToken: builder.mutation<
      ShoppingTokenDataT,
      { curationId: number; employeeEmail: string; employeeId: string }
    >({
      query: (arg) => {
        const { curationId, employeeEmail, employeeId } = arg
        return {
          url: `/curations/${curationId}/token`,
          method: 'POST',
          body: { employeeEmail, employeeId: `${employeeId}` }
        }
      }
    }),
    retrieveShoppingTokenForEdit: builder.mutation<
      ShoppingTokenDataT,
      {
        curationId: number
        shoppingSessionId: string
      }
    >({
      query: (arg) => {
        const { curationId, shoppingSessionId } = arg
        return {
          url: `/curations/${curationId}/token/for-edit`,
          method: 'POST',
          body: {
            shoppingSessionId
          }
        }
      }
    }),
    getPolarisSearchResults: builder.query<
      PolarisSearchProductsT,
      {
        curationId: number
        searchTerm: string
        productGender?: ProductGenderValues
        minPrice?: number
        maxPrice?: number
        resultsPerSearch?: number
      }
    >({
      query: ({
        curationId,
        searchTerm,
        productGender,
        minPrice,
        maxPrice,
        resultsPerSearch
      }) => ({
        url: `/curations/${curationId}/getSearchResults`,
        method: 'GET',
        params: {
          phrase: searchTerm,
          productGender: productGender?.toUpperCase() || 'UNKNOWN',
          minPrice,
          maxPrice,
          resultsPerSearch
        }
      })
    }),
    generateSeedItemRecommendations: builder.mutation<
      RecommendationsT,
      {
        curationId: number
        seedItemData: CurationItemT
      }
    >({
      query: (arg) => {
        const { curationId, seedItemData } = arg
        return {
          url: `/curations/${curationId}/seed-item-recommendations`,
          method: 'POST',
          body: {
            seedItemData
          }
        }
      }
    }),
    getCurationAttributions: builder.query<
      GetCurationAttributionsQueryDataT,
      GetCurationAttributionsQueryParamsT
    >({
      query: (arg) => {
        const { perPage, page, startDate, endDate, group } = arg
        return {
          url: `/attributions`,
          method: 'GET',
          params: {
            perPage,
            page,
            startDate,
            endDate,
            group
          }
        }
      }
    }),
    getProductByWebStyleId: builder.query<StyleProductT, string>({
      query: (webStyleId: string) => ({
        url: `/products/webStyle/${webStyleId}`
      }),
      transformResponse: (product: OfferStyleProductT) => {
        const formatSizesWithSizeGroupName = (sizeData: SkuFilterGroups) => {
          const sizesWithGroups: Record<string, string[]> = {}
          sizeData &&
            sizeData.selections.forEach((selection) => {
              const groupName = selection.group || 'Regular'
              if (!sizesWithGroups[groupName]) {
                sizesWithGroups[groupName] = []
              }
              !!selection.name &&
                sizesWithGroups[groupName].push(selection.name)
            })
          return sizesWithGroups
        }
        const parseSkuFilterGroups = (
          skuFilterGroups: SkuFilterGroups[],
          filterName: string
        ) => {
          if (skuFilterGroups.length > 0) {
            return skuFilterGroups
              .filter((group) => group.caption === filterName)[0]
              ?.selections.map((group) => group.name)
              .filter((name) => !!name)
          }
          return []
        }

        return {
          productName: product.productAttributes?.name,
          description: product.productAttributes?.description,
          imageUrl:
            product.mediaExperiences?.carouselsByColor[0]?.orderedShots[0]?.url,
          brandName: product.productAttributes?.vendor.labelName,
          itemNumber: product.ids?.rmsStyleGroup?.id,
          price: product.sellingControls[0].price,
          skus: product.skus.map((sku) => {
            return {
              isOnlinePurchasable:
                sku?.sellingControls[0]?.productAttributes?.isOnlinePurchasable,
              color: sku?.productAttributes?.color.name,
              size: sku?.productAttributes?.size.name,
              price: sku.sellingControls[0].price,
              rmsSku: sku?.ids?.rmsSku.id,
              width: sku?.productAttributes?.size.width
            }
          }),
          sizes: formatSizesWithSizeGroupName(
            product.sellingControls[0].productAttributes.skuFilterGroups.filter(
              (group) => group.caption === 'Size'
            )[0]
          ),
          colors: parseSkuFilterGroups(
            product.sellingControls[0].productAttributes.skuFilterGroups,
            'Color'
          ),
          widths: parseSkuFilterGroups(
            product.sellingControls[0].productAttributes.skuFilterGroups,
            'Width'
          ),
          mediaExperiences: product.mediaExperiences
        }
      }
    }),
    getCurationsInQueue: builder.query<GetCurationsQueryDataT, string>({
      query: (occasionGroup = 'ALL') => {
        return {
          url: `/curations/in-general-queue?occasionGroup=${occasionGroup}`,
          method: 'GET'
        }
      }
    })
  })
})

export const {
  useFashionMapFiltersMutation,
  useFashionMapSearchMutation,
  useGenerateTitleAndDescriptionMutation,
  useGetCustomerQuery,
  useGetScanditConfigQuery,
  useGetPasFashionPillarQuery,
  useGetPreferredBrandsQuery,
  useGetPurchaseHistoryQuery,
  useGetCustomersQuery,
  useGetDefaultWishListDataQuery,
  useLazyGetWishListDataByIdQuery,
  useGetItemByUPCMutation,
  useGetStyleBoardsQuery,
  useStartCurationMutation,
  useStartPreferredEmployeeCurationMutation,
  useGetCustomerLoyaltyQuery,
  useLazyGetAvailabilityFromSkusQuery,
  useGenerateCustomerDossierMutation,
  useValidateCustomerDossierMutation,
  useRetrieveShoppingTokenMutation,
  useRetrieveShoppingTokenForEditMutation,
  useGetPolarisSearchResultsQuery,
  useGenerateSeedItemRecommendationsMutation,
  useGetCurationAttributionsQuery,
  useGetProductByWebStyleIdQuery,
  useGetCurationsInQueueQuery,
  useGetBeautyPurchasesQuery
} = employeeExperienceApi
