import { ReactElement, useState } from 'react'
import { Box, Typography, IconButton } from '@mui/material'
import moment from 'moment'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import BaseInformationDialog from 'components/BaseInformationDialog'
import Skeleton from 'components/Skeleton'
import ItemsOrdered from 'pages/Home/components/SalesAndInsights/components/ItemsOrdered'
import { InformationDialogText } from 'pages/ReportingHome/ReportingHome'
import useReportingLinks from 'hooks/useReportingLinks'
import { useGetSalesQuery } from 'services/salesApi'
import NetSales from './components/NetSales'
import { useGetCurationAttributionsQuery } from 'services/employeeExperienceApi'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import ErrorMessage from '../ErrorMessage'

const SalesAndInsights = (): ReactElement => {
  const { payrollCalendarUrl } = useReportingLinks()
  const [open, setOpen] = useState(false)

  const {
    data: salesData,
    isLoading: isSalesLoading,
    isFetching: isSalesFetching,
    isError: isSalesError,
    endpointName: SalesEndpointName
  } = useGetSalesQuery()

  const todayTimestamps = {
    startOfDay: moment().startOf('day').format(),
    endOfDay: moment().endOf('day').format()
  }

  const {
    data: attributionData,
    isLoading: isAttribLoading,
    isFetching: isAttribFetching,
    isError: isAttribError,
    endpointName: attribEndpointName
  } = useGetCurationAttributionsQuery({
    page: 1,
    perPage: 20,
    startDate: todayTimestamps.startOfDay,
    endDate: todayTimestamps.endOfDay
  })

  const errorDetails = {
    sales: {
      endpoint: SalesEndpointName,
      errorData: isSalesError
    },
    attributions: {
      endpoint: attribEndpointName,
      errorData: isAttribError
    }
  }

  const handleReportingInfoClick = () => {
    const attributes = {
      page: 'homepage'
    }
    generateNewRelicLogs('reportingInfoClick', attributes)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box px={2} mt={3}>
      <Typography variant="h6">
        Sales & insights
        <IconButton
          aria-label="info"
          color="info"
          size="small"
          onClick={handleReportingInfoClick}
        >
          <InfoOutlinedIcon
            sx={{
              width: '20px',
              height: '20px'
            }}
          />
        </IconButton>
      </Typography>
      <BaseInformationDialog
        cancel={handleClose}
        contextText={
          <InformationDialogText payrollCalendarUrl={payrollCalendarUrl} />
        }
        onClose={handleClose}
        open={open}
        title={'Sales and insights'}
      />
      {isSalesLoading && isSalesFetching ? (
        <Box my={2}>
          <Skeleton.Image aspectRatio={0} height={'118px'} />
        </Box>
      ) : isSalesError ? (
        <ErrorMessage
          errorTitle="Hmm, the net sales didn’t load"
          errorDetails={errorDetails.sales}
          isInlineAlert
        />
      ) : salesData ? (
        <NetSales salesData={salesData} />
      ) : null}
      {isAttribLoading && isAttribFetching ? (
        <Box my={2}>
          <Skeleton.Image aspectRatio={0} height={'118px'} />
        </Box>
      ) : isAttribError ? (
        <ErrorMessage
          errorTitle="Hmm, the board items data didn’t load"
          errorDetails={errorDetails.attributions}
          isInlineAlert
        />
      ) : attributionData ? (
        <ItemsOrdered
          count={attributionData.meta.count}
          timestamps={todayTimestamps}
        />
      ) : null}
    </Box>
  )
}

export default SalesAndInsights
