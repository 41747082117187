import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import ProductImageCarousel from 'components/ProductImageCarousel'
import { emDashCharacter } from 'pages/constants'
import { useGetPolarisSearchResultsQuery } from 'services/employeeExperienceApi'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'
import { PriceRange } from '../RecommendationsTemplate/RecommendationsTemplate'

const RECS_ERROR_TITLE = `Issue getting recommendations ${emDashCharacter}`

interface PropsT {
  onClickForMiniPDP: ({
    webStyleId,
    miniPDPOpenedFrom,
    imgUrl
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    imgUrl: string
  }) => void
  curationId: number
  searchTerm: string
  minAndMax: PriceRange
}

const CategoryCarousel = ({
  onClickForMiniPDP,
  curationId,
  searchTerm,
  minAndMax
}: PropsT): JSX.Element => {
  const { data, endpointName, error, isLoading, isFetching, isError, refetch } =
    useGetPolarisSearchResultsQuery({
      curationId,
      searchTerm,
      resultsPerSearch: 50,
      minPrice: minAndMax.min,
      maxPrice: minAndMax.max
    })

  if (isError) {
    return (
      <PageError
        errorDetails={{ endpoint: endpointName, errorData: error }}
        errorTitle={RECS_ERROR_TITLE}
        refreshAction={refetch}
      />
    )
  }

  if (!data || isLoading || isFetching) {
    return <LoadingSpinner hideText={true} />
  }

  return (
    <ProductImageCarousel
      data={data}
      onClickForMiniPDP={onClickForMiniPDP}
      miniPDPOpenedFrom={'HolidayGiftRecommendations'}
    />
  )
}

export default CategoryCarousel
