import { ReactElement, useEffect, useRef, useState } from 'react'
import {
  Container,
  Stack,
  Divider,
  Typography,
  paginationClasses,
  Pagination,
  Box,
  Switch
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useHistory } from 'react-router-dom'

import { useAppSelector } from 'app/hooks'
import { customerIdSelector } from 'app/customerSlice'
import RecentPurchasesItem from '../../components/RecentPurchasesItem'
import SnapshotNavigation from '../../components/SnapshotNavigation'
import PageError from 'components/PageError'
import LoadingPurchasesList from './LoadingPurchasesList'

import { useGetPurchaseHistoryQuery } from 'services/employeeExperienceApi'
import {
  OrderT,
  PHOrderSchemaT,
  CommissionAttributedOrderT,
  CommissionAttributedProductsAndStatusT
} from 'types/Orders'
import useWindowDimensions from 'utils/useWindowDimensions'
import oktaTokenStorage from 'utils/okta-token-utils'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import {
  NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS,
  NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_ENABLED_PURCHASES_WITH_ME,
  NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_OPEN_PURCHASE_DETAILS
} from 'constants/clienteling/newRelicEvents/nrCustomerDetailsPage'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import { useGetConsentedCustomerPurchaseHistoryQuery } from 'services/clientelingApi'
import AdditionalDetails from 'components/ConsentedCustomerTripsListItem/AdditionalDetails'
import { PURCHASES_WITH_YOU_DISCLAIMER_DETAILS } from 'components/ConsentedCustomerTripsListItem/constants'

type PropsT = {
  customerId?: string
  hideSnapshotNavigation?: boolean
  sourcePath: string
}
export type AttributedItems = {
  itemId?: string
  commissionSalespersonId?: string
}
type OrdersWithAttributedItems = Record<string, Array<AttributedItems>>

const PAGE_NUMBER_KEY = 'pageNumberKey'
const SCROLL_POSITION_KEY = 'purchaseHistoryScrollPosition'

const RecentPurchasesList = (props: PropsT): ReactElement => {
  const stackRef = useRef<HTMLDivElement>(null)
  const { hideSnapshotNavigation } = props
  const customerIdFromProps = props.customerId || ''
  const employeeId = oktaTokenStorage
    .getEmployeeNumberFromOktaToken()
    .toString()
  const history = useHistory()
  const ORDER_COUNT_OFFSET = 10
  const [page, setPage] = useState(1)
  const featureFlags = useFeatureFlags()
  const { isPurchasesWithYouEnabled } = featureFlags
  const [isPurchasesToggleOn, setIsPurchasesToggleOn] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [shouldShowErrorPage, setShouldShowErrorPage] = useState(false)
  const purchasesErrorTitle = 'Issue getting purchases.'
  const customerIdFromState = useAppSelector(customerIdSelector)
  const customerId = customerIdFromProps || customerIdFromState
  const pageKey = `${customerId}/${PAGE_NUMBER_KEY}`
  const purchasesWithMeErrorTitle =
    'Issue filtering by purchases with you – try again'
  const schema: PHOrderSchemaT = {
    customerId,
    orderCount: ORDER_COUNT_OFFSET,
    skip: (page - 1) * ORDER_COUNT_OFFSET
  }
  const nrCustomerDetailsEventAttributes = {
    page: NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.PURCHASES,
    ocpId: customerId
  }
  const {
    data: phResponse,
    isLoading,
    isFetching,
    isError,
    error,
    endpointName
  } = useGetPurchaseHistoryQuery(schema)
  const {
    data: sellerAttributedPurchasesData,
    isLoading: sellerAttributedPurchasesIsLoading,
    isError: sellerAttributedPurchasesIsError,
    error: sellerAttributedPurchasesError,
    endpointName: sellerAttributedPurchasesEndpoint
  } = useGetConsentedCustomerPurchaseHistoryQuery({
    customerId
  })

  const { height } = useWindowDimensions()

  const handleToggle = () => {
    if (!isPurchasesToggleOn) {
      generateNewRelicLogs(
        NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_ENABLED_PURCHASES_WITH_ME,
        nrCustomerDetailsEventAttributes
      )
    }
    setIsPurchasesToggleOn(!isPurchasesToggleOn)
  }

  useEffect(() => {
    const storedState =
      localStorage.getItem('customerPurchasesWithSellerToggleState') || ''
    const [customerIdFromStorage, isToggleOn] = storedState?.split('--') || ''
    if (customerId !== customerIdFromStorage) {
      return
    }
    if (storedState) {
      setIsPurchasesToggleOn(isToggleOn === 'true')
    }
  }, [customerId])

  useEffect(() => {
    localStorage.setItem(
      'customerPurchasesWithSellerToggleState',
      `${customerId}--${isPurchasesToggleOn}`
    )
  }, [customerId, isPurchasesToggleOn])

  const commissionAttributedOrdersWithOnlyAttributedProducts =
    sellerAttributedPurchasesData?.orders?.map(
      (order): CommissionAttributedOrderT => ({
        ...order,
        productsAndStatus: order.productsAndStatus.map(
          (status): CommissionAttributedProductsAndStatusT => ({
            ...status,
            products: status.products.filter(
              (product) => product.commissionSalespersonId === employeeId
            )
          })
        )
      })
    )

  const ordersWithAttributedItems =
    commissionAttributedOrdersWithOnlyAttributedProducts?.reduce(
      (ordersWithAttributedItems, order) => {
        ordersWithAttributedItems[order.orderId] =
          order.productsAndStatus.flatMap((status) =>
            status.products.map((product) => ({
              itemId: product.id,
              commissionSalespersonId: product.commissionSalespersonId
            }))
          )
        return ordersWithAttributedItems
      },
      {} as OrdersWithAttributedItems
    ) ?? {}

  const ordersList = isPurchasesToggleOn
    ? commissionAttributedOrdersWithOnlyAttributedProducts?.slice(
        (page - 1) * ORDER_COUNT_OFFSET,
        page * ORDER_COUNT_OFFSET
      )
    : phResponse?.orders

  const storeScrollPosition = () => {
    if (stackRef.current) {
      sessionStorage.setItem(
        SCROLL_POSITION_KEY,
        String(stackRef.current.scrollTop)
      )
    }
  }

  useEffect(() => {
    if (isPurchasesToggleOn && sellerAttributedPurchasesError) {
      setShouldShowErrorPage(true)
    }
  }, [isPurchasesToggleOn, sellerAttributedPurchasesError])

  const goToDetails = (orderId: string) => {
    // From Customer Details Page
    hideSnapshotNavigation &&
      generateNewRelicLogs(
        NR_CUSTOMER_DETAILS_PAGE_PURCHASES_TAB_OPEN_PURCHASE_DETAILS,
        {
          page: NR_CUSTOMER_DETAILS_PAGE_TAB_LOCATIONS.PURCHASES,
          ocpId: customerId,
          orderId
        }
      )
    storeScrollPosition()
    history.push({
      pathname: `/purchase-history/order/${orderId}`,
      state: {
        customerId: customerId || customerIdFromProps,
        page,
        isPurchasesToggleOn,
        attributedItems: ordersWithAttributedItems[orderId]
      }
    })
  }

  useEffect(() => {
    let response
    if (isPurchasesToggleOn && sellerAttributedPurchasesData?.orders.length) {
      response = sellerAttributedPurchasesData?.orders.length
    } else {
      response = phResponse?.orders.length
    }
    if (response) {
      const scrollPosition = sessionStorage.getItem(SCROLL_POSITION_KEY)
      const pageInStorage = sessionStorage.getItem(pageKey)
      if (scrollPosition && stackRef.current) {
        stackRef.current.scrollTop = Number(scrollPosition)
        sessionStorage.removeItem(SCROLL_POSITION_KEY)
      }
      if (pageInStorage) {
        setPage(Number(pageInStorage))
      }
    }
  }, [
    phResponse?.orders,
    pageKey,
    isPurchasesToggleOn,
    sellerAttributedPurchasesData?.orders.length
  ])

  useEffect(() => {
    window.addEventListener('beforeunload', storeScrollPosition)
  }, [])

  const NoOrders = () => (
    <Container>
      <Typography my="12px" mt={1} variant="body2" textAlign="left">
        No purchases
      </Typography>
    </Container>
  )

  const NoOrdersWithMe = () => (
    <Container>
      <Typography mt={1} variant="subtitle2" textAlign="left" fontSize="16px">
        No purchases found
      </Typography>
      <Typography variant="body2" textAlign="left" fontSize="16px">
        Adjust the filters and try again
      </Typography>
    </Container>
  )

  if (isLoading || isFetching || sellerAttributedPurchasesIsLoading) {
    return <LoadingPurchasesList />
  }

  if (!phResponse?.orders?.length && page === 1 && !isError) {
    return <NoOrders />
  }

  // Controlled navigation to take user to Snapshot homepage.
  const handleBackButtonClick = () => {
    history.push('')
  }

  const onPageChange = (page: number) => {
    sessionStorage.setItem(pageKey, page.toString())
    setPage(page)
  }

  const handleModalOpen = () => {
    setIsModalOpen(true)
  }
  return (
    <>
      {!hideSnapshotNavigation && (
        <Container>
          <SnapshotNavigation
            title="Purchases"
            onClick={handleBackButtonClick}
          />
        </Container>
      )}
      {!isError && phResponse && !shouldShowErrorPage ? (
        <div
          style={{
            maxHeight: hideSnapshotNavigation ? height - 176 : height,
            position: 'relative',
            overflow: 'auto'
          }}
          ref={stackRef}
        >
          <Divider
            style={{ background: '#F5F5F5', border: 'none', height: 16 }}
          />
          <Stack
            divider={
              <Divider
                style={{ background: '#F5F5F5', border: 'none', height: 16 }}
              />
            }
            spacing={2}
            my={2}
          >
            {isPurchasesWithYouEnabled?.active && (
              <>
                <AdditionalDetails
                  open={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  dialogText={PURCHASES_WITH_YOU_DISCLAIMER_DETAILS}
                />

                <Box sx={{ display: 'flex', paddingLeft: '20px' }}>
                  <Switch
                    checked={isPurchasesToggleOn}
                    onChange={handleToggle}
                  />
                  <Box sx={{ fontSize: '16px', paddingTop: '7.5px' }}>
                    Purchases with you
                  </Box>
                  <Box sx={{ paddingLeft: '9px', paddingTop: '9px' }}>
                    <InfoOutlinedIcon
                      sx={{ width: '24px', height: '24px', color: '#006BE2' }}
                      onClick={handleModalOpen}
                    />
                  </Box>
                </Box>
              </>
            )}
            {!sellerAttributedPurchasesData?.orders?.length &&
              page === 1 &&
              !sellerAttributedPurchasesIsError &&
              isPurchasesWithYouEnabled?.active &&
              isPurchasesToggleOn && <NoOrdersWithMe />}
            {ordersList?.map((order: OrderT) => (
              <RecentPurchasesItem
                customerId={customerId}
                key={order.orderId}
                order={order}
                attributedItems={ordersWithAttributedItems[order.orderId]}
                goToDetails={goToDetails}
                fromSnapShot={!hideSnapshotNavigation}
                isPurchasesToggleOn={isPurchasesToggleOn}
              />
            ))}
          </Stack>
          {phResponse?.actualToDateOrders > ORDER_COUNT_OFFSET &&
            !isPurchasesToggleOn && (
              <Pagination
                count={Math.ceil(
                  phResponse?.actualToDateOrders / ORDER_COUNT_OFFSET
                )}
                page={page}
                onChange={(event, page) => onPageChange(page)}
                sx={{
                  [`& .${paginationClasses.ul}`]: {
                    justifyContent: 'center'
                  },
                  marginBottom: '20px'
                }}
                data-testid={`paginationComponent-${Math.ceil(
                  phResponse?.actualToDateOrders / ORDER_COUNT_OFFSET
                )}`}
              />
            )}
        </div>
      ) : (
        <>
          {sellerAttributedPurchasesError ? (
            <PageError
              isInlineAlert={true}
              errorTitle={purchasesWithMeErrorTitle}
              errorDetails={{
                endpoint: sellerAttributedPurchasesEndpoint,
                errorData: sellerAttributedPurchasesError,
                identifiers: {
                  customerId: customerId,
                  curationId: 'NO-DATA',
                  employeeId,
                  shoppingSessionId: 'NO-DATA'
                }
              }}
            />
          ) : (
            <PageError
              isInlineAlert={true}
              errorTitle={purchasesErrorTitle}
              errorDetails={{
                endpoint: endpointName,
                errorData: error,
                identifiers: {
                  customerId: customerId,
                  curationId: 'NO-DATA',
                  employeeId,
                  shoppingSessionId: 'NO-DATA'
                }
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default RecentPurchasesList
