import { useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Collapse, Tab, Typography, useTheme } from '@mui/material'
import LoadingSpinner from 'components/LoadingSpinner'
import ExpandIconButton from '../ExpandIconButton'
import {
  generateNewRelicLogs,
  mapCurationSliceToNewRelicLog
} from 'utils/newRelicCustomLogHelper'
import { useSelector } from 'react-redux'
import { curationSelector } from 'app/curationSlice'

const capitalize = (string: string) => {
  return string?.charAt(0).toUpperCase() + string.slice(1)
}

type PriceChipLabel = 'Under $25' | 'Under $50' | 'Under $100' | 'Luxe'

export interface PriceRange {
  min?: number
  max?: number
}

interface RecommendationsTemplateProps {
  renderedFrom: string
  title: string
  description: string
  categories: string[]
  renderCategoryCarousel: (index: number) => JSX.Element | null
  renderChips?: (index: number) => JSX.Element[]
  renderPriceChips?: (
    priceChipData: Record<PriceChipLabel, Record<string, number>>
  ) => JSX.Element[]
  priceChipData?: Record<PriceChipLabel, Record<string, number>>
  isLoading?: boolean
  onTabChange?: (value: string) => void
  errorComponent?: JSX.Element
}

const RecommendationsTemplate = ({
  title,
  description,
  categories,
  renderCategoryCarousel,
  renderChips,
  renderPriceChips,
  priceChipData,
  isLoading,
  onTabChange,
  errorComponent,
  renderedFrom
}: RecommendationsTemplateProps): JSX.Element => {
  const theme = useTheme()
  const [tabPosition, setTabPosition] = useState('0')
  const [expanded, setExpanded] = useState(true)

  const curationDetails = useSelector(curationSelector)

  const handleExpandClick = () => {
    if (expanded) {
      const attributes = {
        ...mapCurationSliceToNewRelicLog(curationDetails),
        renderedFrom
      }
      generateNewRelicLogs('recTrayCollapsed', attributes)
    }
    setExpanded(!expanded)
  }

  const handleTabChange = (value: string) => {
    setTabPosition(value)
    onTabChange && onTabChange(value)
  }

  return (
    <Box p={2} mb={3} sx={{ backgroundColor: theme.palette.grey[50] }}>
      <Box display="flex" flexDirection={'row'}>
        <Box>
          <Typography variant="subtitle2" fontWeight={'bold'}>
            {title}
          </Typography>
          <Typography variant="subtitle2" fontWeight={'regular'}>
            {description}
          </Typography>
        </Box>
        <ExpandIconButton
          expanded={expanded}
          handleExpandClick={handleExpandClick}
        />
      </Box>
      <Collapse in={expanded} timeout="auto">
        {isLoading ? (
          <LoadingSpinner />
        ) : errorComponent ? (
          errorComponent
        ) : (
          <TabContext value={tabPosition}>
            <TabList
              onChange={(_e, value) => handleTabChange(value)}
              variant="scrollable"
              sx={{ mb: 2 }}
            >
              {categories.map((category, index) => (
                <Tab
                  key={index}
                  value={index.toString()}
                  label={capitalize(category)}
                />
              ))}
            </TabList>
            {categories.map((_category, index) => (
              <TabPanel key={index} value={index.toString()} sx={{ p: 0 }}>
                {renderChips && (
                  <Box display="flex" flexWrap="wrap" gap={1.5} mb={2}>
                    {renderChips(index)}
                  </Box>
                )}
                {renderPriceChips && priceChipData && (
                  <Box display="flex" flexWrap="wrap" gap={1.5} mb={2}>
                    {renderPriceChips(priceChipData)}
                  </Box>
                )}
                {renderCategoryCarousel(index)}
              </TabPanel>
            ))}
          </TabContext>
        )}
      </Collapse>
    </Box>
  )
}

export default RecommendationsTemplate
