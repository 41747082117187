import { useFeatureFlags } from 'contexts/FeatureFlagsContext'

const useReportingLinks = (): Record<
  'payrollCalendarUrl' | 'fiscalCalendarUrl' | 'salesIncentivesUrl',
  string | undefined
> => {
  const { personalBookReportingLinks } = useFeatureFlags()
  const { payload } = personalBookReportingLinks || {}

  let payrollCalendarUrl,
    fiscalCalendarUrl,
    salesIncentivesUrl = undefined

  if (payload !== null && typeof payload === 'object') {
    if ('payrollCalendarUrl' in payload) {
      payrollCalendarUrl = String(payload.payrollCalendarUrl)
    }

    if ('fiscalCalendarUrl' in payload) {
      fiscalCalendarUrl = String(payload.fiscalCalendarUrl)
    }

    if ('salesIncentivesUrl' in payload) {
      salesIncentivesUrl = String(payload.salesIncentivesUrl)
    }
  }

  return {
    payrollCalendarUrl,
    fiscalCalendarUrl,
    salesIncentivesUrl
  }
}

export default useReportingLinks
