import { useGetCurationsInQueueQuery } from 'services/employeeExperienceApi'
import {
  Box,
  Button,
  Card,
  Typography,
  CardContent,
  CardActions
} from '@mui/material'
import GroupIcon from '@mui/icons-material/Group'
import { Link } from 'react-router-dom'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'

interface PropsT {
  occasionGroup?: string
}

const GeneralRequests = (props: PropsT): JSX.Element => {
  const { occasionGroup = 'ALL' } = props
  const { data } = useGetCurationsInQueueQuery(occasionGroup, {
    refetchOnMountOrArgChange: true
  })

  const handleClickLog = () => {
    const attributes = {
      page: 'styleboards'
    }

    generateNewRelicLogs('startGeneralRequestFromQueueClick', attributes)
  }

  let title = 'General requests'

  switch (occasionGroup) {
    case 'GROUP_1':
      title = 'Group 1'
      break
    case 'GROUP_2':
      title = 'Group 2'
      break
    case 'GROUP_3':
      title = 'Group 3'
      break
    case 'GROUP_4':
      title = 'Group 4'
      break
    default:
      break
  }

  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <CardContent>
        <Box sx={{ mt: 1, display: 'flex', flexDirection: 'row' }}>
          <Box
            display="flex"
            height={40}
            width={40}
            alignItems="center"
            justifyContent="center"
            bgcolor="#482FE914"
            borderRadius={50}
            mr={2}
          >
            <GroupIcon color="primary" />
          </Box>
          <Box>
            <Typography variant="body2">{title}</Typography>
            <Typography component={Box} variant="h6">
              {data?.meta?.count}
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardActions sx={{ p: 2 }}>
        <Button
          component={Link}
          to={`/review?startCuration=true&occasionGroup=${occasionGroup}`}
          variant="contained"
          fullWidth
          onClick={handleClickLog}
        >
          Start
        </Button>
      </CardActions>
    </Card>
  )
}

export default GeneralRequests
