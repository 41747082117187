import { ReactElement, useState } from 'react'
import moment from 'moment'

import { Box, Button, List, ListItem, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ListItemInfo from '../ListItemInfo/ListItemInfo'
import { checkBrowserClient } from 'utils/userAgentDetector'
import { displayAlterationsBenefit } from '../../utils'
import {
  ALTERATIONS_BENEFITS,
  colors,
  EMPTY_NOTES_MESSAGES
} from 'pages/CustomerDetails/constants'
import { NordstromNoteT } from 'types/ConsentedCustomer'

export type PropsT = {
  nordstromNotes?: Array<NordstromNoteT>
  alterationBalance?: number
  personalDoublePointsDays?: number
  totalNoteValue?: number
  loyaltyStatus: string
  nordstromCardHolder: boolean
}

const NordstromNotesInfo = ({
  nordstromNotes = [],
  alterationBalance = 0,
  personalDoublePointsDays = 0,
  totalNoteValue = 0,
  loyaltyStatus,
  nordstromCardHolder
}: PropsT): ReactElement => {
  const isMobileDevice = checkBrowserClient.isMobile()
  const MAX_INITIAL_NOTES = 3
  const [showAllNotes, setShowAllNotes] = useState(false)

  const activeNordstromNotes = nordstromNotes.filter(
    (note) => note.noteState === 'Active'
  )
  const hasNotes = activeNordstromNotes.length > 0
  const notesToDisplay = activeNordstromNotes.slice(
    0,
    showAllNotes
      ? activeNordstromNotes.length
      : Math.min(activeNordstromNotes.length, MAX_INITIAL_NOTES)
  )

  const isNoteExpiring = (note: NordstromNoteT) => {
    return moment(note.expirationDate) < moment().add(3, 'months')
  }

  const alterationsBenefit = displayAlterationsBenefit({
    loyaltyStatus,
    alterationBalance,
    nordstromCardHolder
  })

  const amountOfExpiringNotes = activeNordstromNotes.reduce(
    (sum: number, current: NordstromNoteT) =>
      isNoteExpiring(current) ? sum + 1 : sum,
    0
  )

  return (
    <>
      <ListItemInfo
        sx={{
          p: '8px 12px 4px 16px'
        }}
        title="Nordstrom Notes"
        text={
          hasNotes
            ? `$${totalNoteValue} notes available`
            : EMPTY_NOTES_MESSAGES.NO_NOTES
        }
        showDivider={!hasNotes}
        styleOverride={{
          secondaryColor: hasNotes ? colors.primary : colors.subtitleGrey
        }}
      />
      {!!amountOfExpiringNotes && (
        <Box
          sx={{
            m: '4px 16px 8px 16px',
            p: '16px',
            display: 'flex',
            backgroundColor: '#E9F4FC',
            borderRadius: '8px'
          }}
        >
          <InfoOutlinedIcon htmlColor={colors.infoIcon} sx={{ mr: '8px' }} />
          <Typography variant="body1">
            {amountOfExpiringNotes}{' '}
            {amountOfExpiringNotes > 1 ? 'Notes' : 'Note'} expiring in 3 months
          </Typography>
        </Box>
      )}
      {hasNotes && (
        <Box borderBottom={`2px ${colors.backgroundGrey} solid`}>
          <List
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: isMobileDevice ? 'column' : 'row',
              mb: '8px',
              p: 0
            }}
          >
            {notesToDisplay.map((note, index) => (
              <ListItem
                key={`nordstrom-note-${index}`}
                data-testid={`nordstrom-note-${index}`}
                sx={{
                  display: 'flex',
                  flex: isMobileDevice ? '' : '1 1 calc(100% / 3 - 10px)',
                  maxWidth: isMobileDevice ? '100%' : 'calc(100% / 3 - 10px)',
                  width: 'auto',
                  m: isMobileDevice ? '4px 16px' : '4px 0px 4px 8px',
                  border: `1px ${colors.notesBorder} solid`,
                  alignItems: 'center',
                  p: '0'
                }}
              >
                <Box sx={{ p: '8px 4px 8px 16px', flex: '1 1 auto' }}>
                  <Typography variant="body2">{`$${note.amountAvailable}`}</Typography>
                  <Typography variant="caption">
                    Exp. {moment(note.expirationDate).format('M/DD/YYYY')}
                  </Typography>
                </Box>
                {isNoteExpiring(note) && (
                  <InfoOutlinedIcon
                    htmlColor={colors.infoIcon}
                    sx={{ m: '16px' }}
                  />
                )}
              </ListItem>
            ))}
          </List>
          {activeNordstromNotes.length > MAX_INITIAL_NOTES && (
            <Box
              display="flex"
              sx={{ justifyContent: 'flex-end', my: '8px', mr: '16px' }}
            >
              <Button
                variant="text"
                onClick={() => setShowAllNotes(!showAllNotes)}
              >
                {`${showAllNotes ? 'Show Less' : 'Show More'}`}
              </Button>
            </Box>
          )}
        </Box>
      )}
      <ListItemInfo
        title="Personal Double Points Days"
        text={
          personalDoublePointsDays > 0
            ? `${personalDoublePointsDays} ${
                personalDoublePointsDays > 1 ? 'days' : 'day'
              } available`
            : EMPTY_NOTES_MESSAGES.NO_PDPD
        }
        styleOverride={{
          secondaryColor:
            personalDoublePointsDays > 0 ? colors.primary : colors.subtitleGrey
        }}
      />
      <ListItemInfo
        title="Alterations benefit"
        text={alterationsBenefit}
        styleOverride={{
          secondaryColor:
            alterationsBenefit !== ALTERATIONS_BENEFITS.NONE
              ? colors.primary
              : colors.subtitleGrey
        }}
      />
    </>
  )
}

export default NordstromNotesInfo
