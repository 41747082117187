import { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Alert,
  Avatar,
  Box,
  Button,
  Drawer,
  IconButton,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import {
  PermContactCalendar,
  PersonSearch,
  QrCode,
  Textsms
} from '@mui/icons-material'
import EmailIcon from '@mui/icons-material/Email'
import CloseIcon from '@mui/icons-material/Close'
import { NdlsBorderRadius40 } from '@ndls/design-tokens/tokens/nordstrom'
import { CustomerByIdentifierT } from 'types/Customer'
import { useFeatureFlags } from 'contexts/FeatureFlagsContext'
import {
  CONSENT_INVITE_TYPES,
  CUSTOMER_INFORMATION_STATUS,
  CustomerInformationStatusT
} from 'constants/clienteling/consentConstants'
import { formatLoyaltyStatus } from 'pages/CustomerDetails/utils'
import AnniversaryBadge from 'components/AnniversaryBadge/AnniversaryBadge'
import { filterAnniversaryEligible } from 'pages/CustomerHome/utils/filterHelpers'

type CustomerInformationPropsT = {
  customerData: CustomerByIdentifierT | undefined
  isCustomerNotFoundError: boolean
  isCustomerInformationOpen: boolean
  isInviteSubmitLoading: boolean
  isInviteSubmitDisabled?: boolean
  searchText: string
  onClose: () => void
  onInviteWithText: ({ customerId }: { customerId: string }) => void
  onInviteWithEmail: ({ customerId }: { customerId: string }) => void
  onInviteWithQR: () => void
}

const getInvitationStatus = (
  customerData: CustomerByIdentifierT | undefined
): CustomerInformationStatusT => {
  if (customerData?.relationshipStatus === 'verified') {
    return CUSTOMER_INFORMATION_STATUS.ALREADY_IN_BOOK
  } else if (customerData?.relationshipStatus === 'denied') {
    return CUSTOMER_INFORMATION_STATUS.DECLINED
  } else if (customerData?.invitationStatus === 'pending') {
    return CUSTOMER_INFORMATION_STATUS.INVITATION_PENDING
  } else if (!customerData?.mobile) {
    return CUSTOMER_INFORMATION_STATUS.EMAIL_FOUND_NO_MOBILE
  } else {
    return CUSTOMER_INFORMATION_STATUS.MOBILE_FOUND
  }
}

const CustomerInformation = (
  props: CustomerInformationPropsT
): ReactElement => {
  const {
    customerData,
    isCustomerNotFoundError,
    isCustomerInformationOpen,
    isInviteSubmitLoading,
    isInviteSubmitDisabled = false,
    searchText,
    onClose,
    onInviteWithText,
    onInviteWithEmail,
    onInviteWithQR
  } = props
  const history = useHistory()
  const [inviteSubmitType, setInviteSubmitType] = useState<string | undefined>(
    undefined
  )

  const { isClientelingAnniversaryStylingAppEnabled } = useFeatureFlags()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
  const drawerAnchor = isDesktop ? 'right' : 'bottom'
  const drawerPaperProps = isDesktop
    ? { style: { minWidth: '400px' } }
    : {
        style: {
          height: '468px',
          borderTopLeftRadius: NdlsBorderRadius40,
          borderTopRightRadius: NdlsBorderRadius40
        }
      }
  const drawerBoxResults = isDesktop
    ? { paddingLeft: '24px', paddingRight: '24px', paddingTop: '16px' }
    : { paddingLeft: '16px', paddingRight: '16px', paddingTop: '16px' }

  const customerInvitationStatus = getInvitationStatus(customerData)

  const mobileNumberRegex =
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/g

  const formattedMobileNumber = customerData?.mobile
    ? customerData.mobile.replace(mobileNumberRegex, '($1) $2-$3')
    : ''

  const formattedSearchText = searchText.replace(
    mobileNumberRegex,
    '($1) $2-$3'
  )

  const isSearchTextEmail: boolean = searchText.includes('@')
  const customerFullName =
    customerData?.firstName || customerData?.lastName
      ? `${customerData?.firstName} ${customerData?.lastName}`
      : 'Customer name not on file'

  const goToCustomerDetails = () => {
    history.push({
      pathname: `/customers/details/${customerData?.customerId}`
    })
  }

  const anniversaryEligibleDate = filterAnniversaryEligible(
    customerData?.qualification
  ).eligibleDate

  return (
    <Drawer
      onClose={onClose}
      open={isCustomerInformationOpen}
      anchor={drawerAnchor}
      PaperProps={drawerPaperProps}
      sx={{ zIndex: 2147483100 }} // zIndex for sending the gladly widget behind the drawer
    >
      {!isDesktop && (
        <Box sx={{ marginTop: '8px', marginLeft: 'auto', marginRight: '8px' }}>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      {isDesktop && (
        <Box sx={{ display: 'flex', padding: '8px 12px 8px 12px' }}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ color: 'text.primary' }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ padding: '8px' }}>
            Customer Results
          </Typography>
        </Box>
      )}
      <Box sx={drawerBoxResults}>
        {!isCustomerNotFoundError && (
          <Stack spacing={3}>
            {customerInvitationStatus ===
              CUSTOMER_INFORMATION_STATUS.EMAIL_FOUND_NO_MOBILE && (
              <Alert severity="warning">
                Customer&apos;s mobile number wasn&apos;t found
              </Alert>
            )}
            {(customerInvitationStatus ===
              CUSTOMER_INFORMATION_STATUS.MOBILE_FOUND ||
              customerInvitationStatus ===
                CUSTOMER_INFORMATION_STATUS.EMAIL_FOUND_NO_MOBILE) && (
              <Alert severity="success">Customer was found</Alert>
            )}
            {customerInvitationStatus ===
              CUSTOMER_INFORMATION_STATUS.ALREADY_IN_BOOK && (
              <Alert severity="info">Customer is in your book</Alert>
            )}
            {customerInvitationStatus ===
              CUSTOMER_INFORMATION_STATUS.INVITATION_PENDING && (
              <Alert severity="info">
                {customerData?.inviteType === 'EMAIL'
                  ? "Customer hasn't responded to your email invite"
                  : "Customer hasn't responded to your text invite"}
              </Alert>
            )}
            {customerInvitationStatus ===
              CUSTOMER_INFORMATION_STATUS.DECLINED && (
              <Alert severity="warning">
                Customer is no longer in your book
              </Alert>
            )}
            <Stack
              spacing={4}
              sx={{ backgroundColor: 'grey.50', padding: '16px' }}
            >
              <Stack spacing={1}>
                <div style={{ display: 'flex' }}>
                  <Typography variant="h6" style={{ flex: 1 }}>
                    {customerFullName}
                  </Typography>
                  <Box sx={{ position: 'relative', left: '15px' }}>
                    {anniversaryEligibleDate &&
                      isClientelingAnniversaryStylingAppEnabled?.active && (
                        <AnniversaryBadge date={anniversaryEligibleDate} />
                      )}
                  </Box>
                </div>
                <Typography variant="body2">
                  {formattedMobileNumber || 'Mobile number not on file'}
                </Typography>
                <Typography variant="body2">
                  {customerData?.email || 'Email not on file'}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingTop: '16px'
                  }}
                >
                  <Typography variant="subtitle2">
                    Nordy Club Status:&nbsp;
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      backgroundColor: customerData?.loyaltyStatus
                        ? '#CEB892'
                        : 'transparent',
                      padding: '4px'
                    }}
                  >
                    {customerData?.loyaltyStatus
                      ? formatLoyaltyStatus(customerData?.loyaltyStatus)
                      : 'Not a member'}
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <Typography variant="subtitle2">Cardmember:&nbsp;</Typography>
                  <Typography variant="body2">
                    {customerData?.cardHolder || customerData?.cardInWallet
                      ? 'Yes'
                      : 'No'}
                  </Typography>
                </Box>
              </Stack>
              <Stack spacing={2}>
                <Box textAlign="center">
                  {customerInvitationStatus ===
                    CUSTOMER_INFORMATION_STATUS.MOBILE_FOUND && (
                    <>
                      <Stack spacing={1} sx={{ alignItems: 'center' }}>
                        <Box
                          sx={{
                            maxWidth: '206px'
                          }}
                        >
                          <LoadingButton
                            loading={
                              isInviteSubmitLoading &&
                              inviteSubmitType === CONSENT_INVITE_TYPES.SMS
                            }
                            disabled={
                              isInviteSubmitDisabled ||
                              (isInviteSubmitLoading &&
                                inviteSubmitType === CONSENT_INVITE_TYPES.EMAIL)
                            }
                            variant="contained"
                            startIcon={<Textsms />}
                            sx={{ width: '100%' }}
                            onClick={() => {
                              setInviteSubmitType(CONSENT_INVITE_TYPES.SMS)
                              customerData?.customerId &&
                                onInviteWithText({
                                  customerId: customerData?.customerId
                                })
                            }}
                          >
                            Text Invitation Link
                          </LoadingButton>
                          {isSearchTextEmail && (
                            <LoadingButton
                              loading={
                                isInviteSubmitLoading &&
                                inviteSubmitType === CONSENT_INVITE_TYPES.EMAIL
                              }
                              disabled={
                                isInviteSubmitDisabled ||
                                (isInviteSubmitLoading &&
                                  inviteSubmitType === CONSENT_INVITE_TYPES.SMS)
                              }
                              variant="outlined"
                              startIcon={<EmailIcon />}
                              sx={{ width: '100%', marginTop: '16px' }}
                              onClick={() => {
                                setInviteSubmitType(CONSENT_INVITE_TYPES.EMAIL)
                                customerData?.customerId &&
                                  onInviteWithEmail({
                                    customerId: customerData?.customerId
                                  })
                              }}
                            >
                              Email Invitation Link
                            </LoadingButton>
                          )}
                        </Box>
                      </Stack>
                    </>
                  )}
                  {customerInvitationStatus ===
                    CUSTOMER_INFORMATION_STATUS.EMAIL_FOUND_NO_MOBILE && (
                    <LoadingButton
                      loading={isInviteSubmitLoading}
                      disabled={isInviteSubmitDisabled}
                      variant="outlined"
                      startIcon={<EmailIcon />}
                      sx={{ maxWidth: '198px' }}
                      onClick={() =>
                        customerData?.customerId &&
                        onInviteWithEmail({
                          customerId: customerData?.customerId
                        })
                      }
                    >
                      Email Invitation Link
                    </LoadingButton>
                  )}

                  {customerInvitationStatus ===
                    CUSTOMER_INFORMATION_STATUS.ALREADY_IN_BOOK && (
                    <Button
                      variant="outlined"
                      onClick={() => goToCustomerDetails()}
                      startIcon={<PermContactCalendar />}
                      sx={{ width: '147px' }}
                    >
                      View Details
                    </Button>
                  )}

                  {(customerInvitationStatus ===
                    CUSTOMER_INFORMATION_STATUS.INVITATION_PENDING ||
                    customerInvitationStatus ===
                      CUSTOMER_INFORMATION_STATUS.EMAIL_FOUND_NO_MOBILE ||
                    customerInvitationStatus ===
                      CUSTOMER_INFORMATION_STATUS.DECLINED) && (
                    <Button
                      variant="outlined"
                      onClick={() => onInviteWithQR()}
                      startIcon={<QrCode />}
                      sx={{ width: '198px' }}
                    >
                      Invite with QR Code
                    </Button>
                  )}
                </Box>
                <Typography variant="caption" color="text.secondary">
                  If this customer&apos;s profile is incorrect, call
                  <br />
                  <Link
                    variant="inherit"
                    color="text.secondary"
                    href="tel:+18009641800"
                  >
                    1.800.964.1800.
                  </Link>
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        )}
        {isCustomerNotFoundError && (
          <Stack spacing={3}>
            <Alert severity="error">Customer wasn&apos;t found</Alert>
            <Stack
              spacing={4}
              sx={{ backgroundColor: 'grey.50', padding: '16px' }}
            >
              <Stack
                spacing={2}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Avatar sx={{ width: 67, height: 67, bgcolor: 'grey.200' }}>
                  <PersonSearch
                    fontSize="large"
                    sx={{ color: 'text.secondary' }}
                  />
                </Avatar>
                <Stack
                  spacing={0.5}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography variant="subtitle2">
                    No result for &quot;
                    {formattedSearchText}
                    &quot;
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    textAlign="center"
                  >
                    Check if the {isSearchTextEmail ? 'email' : 'number'} is
                    correct, or have
                    <br />
                    the customer scan the QR code.
                  </Typography>
                </Stack>
              </Stack>
              <Box textAlign="center">
                <Button
                  variant="outlined"
                  onClick={() => onInviteWithQR()}
                  startIcon={<QrCode />}
                  sx={{ width: '198px' }}
                >
                  Invite with QR Code
                </Button>
              </Box>
            </Stack>
          </Stack>
        )}
      </Box>
    </Drawer>
  )
}

export default CustomerInformation
