import LoadingSpinner from 'components/LoadingSpinner'
import PageError from 'components/PageError'
import ProductImageCarousel from 'components/ProductImageCarousel'
import { emDashCharacter } from 'pages/constants'
import { useGetPolarisSearchResultsQuery } from 'services/employeeExperienceApi'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'

interface PropsT {
  onClickForMiniPDP: ({
    webStyleId,
    miniPDPOpenedFrom
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
  }) => void
  curationId: number
  searchTerm: string
  budgetRange?: number
}

const RECS_ERROR_TITLE = `Issue getting recommendations ${emDashCharacter}`

const CategoryCarousel = ({
  onClickForMiniPDP,
  curationId,
  searchTerm,
  budgetRange
}: PropsT): JSX.Element => {
  const { data, endpointName, error, isLoading, isFetching, isError, refetch } =
    useGetPolarisSearchResultsQuery({
      curationId,
      searchTerm,
      minPrice:
        budgetRange && budgetRange <= 100 ? 0 : budgetRange && budgetRange / 2,
      maxPrice: budgetRange && budgetRange * 1.2
    })

  if (isError) {
    return (
      <PageError
        errorDetails={{ endpoint: endpointName, errorData: error }}
        errorTitle={RECS_ERROR_TITLE}
        refreshAction={refetch}
      />
    )
  }

  if (!data || isLoading || isFetching) {
    return <LoadingSpinner hideText={true} />
  }

  return (
    <ProductImageCarousel
      data={data}
      onClickForMiniPDP={onClickForMiniPDP}
      miniPDPOpenedFrom={'StartBoardRecommendations'}
    />
  )
}

export default CategoryCarousel
