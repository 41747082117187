export const CUSTOMER_SORT_OPTIONS = {
  BY_ALPHABETICAL: 'byAlphabetical',
  BY_OLDEST_MESSAGES: 'byOldestMessages',
  BY_NEWEST_MESSAGES: 'byNewestMessages',
  BY_MOST_TRIPS: 'byMostTrips',
  BY_FEWEST_TRIPS: 'byFewestTrips',
  BY_MOST_RECENT_PURCHASE: 'byMostRecentPurchase',
  BY_LEAST_RECENT_PURCHASE: 'byLeastRecentPurchase',
  BY_MOST_RECENT_PURCHASE_WITH_EMPLOYEE: 'byMostRecentPurchaseWithEmployee',
  BY_LEAST_RECENT_PURCHASE_WITH_EMPLOYEE: 'byLeastRecentPurchaseWithEmployee'
} as const

export type CustomerSortKeysT =
  typeof CUSTOMER_SORT_OPTIONS[keyof typeof CUSTOMER_SORT_OPTIONS]

export const CUSTOMER_SORT_EMPTY_SECTION_TITLES = {
  NO_TRIPS: 'NO TRIPS (WITHIN 12 MONTHS)',
  NO_PURCHASES: 'NO NORDSTROM PURCHASES',
  NO_PURCHASES_WITH_EMPLOYEE: 'NO PURCHASES WITH YOU (SINCE OCT 2023)',
  NO_MESSAGES: 'NO MESSAGES',
  DEFAULT: 'NO VALUE'
} as const

export const CUSTOMER_SORT_CRITERIA_KEYS = {
  TRIPS_COUNT: 'tripsCount',
  LAST_PURCHASE_DATE: 'lastPurchaseDate',
  LAST_PURCHASE_DATE_WITH_EMPLOYEE: 'lastPurchaseDateWithEmployee',
  LAST_MESSAGE: 'lastMessage',
  FIRST_NAME: 'firstName'
} as const

export type CriteriaKeys = keyof typeof CUSTOMER_SORT_CRITERIA_KEYS

export const sortFunctionToCriteria: Record<CustomerSortKeysT, CriteriaKeys> = {
  [CUSTOMER_SORT_OPTIONS.BY_LEAST_RECENT_PURCHASE]: 'LAST_PURCHASE_DATE',
  [CUSTOMER_SORT_OPTIONS.BY_MOST_RECENT_PURCHASE]: 'LAST_PURCHASE_DATE',
  [CUSTOMER_SORT_OPTIONS.BY_MOST_TRIPS]: 'TRIPS_COUNT',
  [CUSTOMER_SORT_OPTIONS.BY_FEWEST_TRIPS]: 'TRIPS_COUNT',
  [CUSTOMER_SORT_OPTIONS.BY_NEWEST_MESSAGES]: 'LAST_MESSAGE',
  [CUSTOMER_SORT_OPTIONS.BY_OLDEST_MESSAGES]: 'LAST_MESSAGE',
  [CUSTOMER_SORT_OPTIONS.BY_MOST_RECENT_PURCHASE_WITH_EMPLOYEE]:
    'LAST_PURCHASE_DATE_WITH_EMPLOYEE',
  [CUSTOMER_SORT_OPTIONS.BY_LEAST_RECENT_PURCHASE_WITH_EMPLOYEE]:
    'LAST_PURCHASE_DATE_WITH_EMPLOYEE',
  [CUSTOMER_SORT_OPTIONS.BY_ALPHABETICAL]: 'FIRST_NAME'
}

export const criteriaToSectionTitle: Record<CriteriaKeys, string> = {
  TRIPS_COUNT: CUSTOMER_SORT_EMPTY_SECTION_TITLES.NO_TRIPS,
  LAST_PURCHASE_DATE: CUSTOMER_SORT_EMPTY_SECTION_TITLES.NO_PURCHASES,
  LAST_PURCHASE_DATE_WITH_EMPLOYEE:
    CUSTOMER_SORT_EMPTY_SECTION_TITLES.NO_PURCHASES_WITH_EMPLOYEE,
  LAST_MESSAGE: CUSTOMER_SORT_EMPTY_SECTION_TITLES.NO_MESSAGES,
  FIRST_NAME: CUSTOMER_SORT_EMPTY_SECTION_TITLES.DEFAULT
}
