import { Chip } from '@mui/material'
import PageError from 'components/PageError'
import { useContext, useEffect, useState } from 'react'
import { useGenerateSeedItemRecommendationsMutation } from 'services/employeeExperienceApi'
import { CurationItemT, ItemRecommendationsT } from 'types/Curation'
import RecommendationsTemplate from '../RecommendationsTemplate'
import CategoryCarousel from './CategoryCarousel'
import { SET_SEED_ITEM_RECS_TERMS } from 'pages/CurationReview/constants'
import { CurationReviewDispatchContext } from '../CurationReviewContent/CurationReviewReducer'
import { emDashCharacter } from 'pages/constants'
import { generateNewRelicLogs } from 'utils/newRelicCustomLogHelper'
import { MiniPDPLocationsT } from 'types/NewRelicAttributes'

export const RECS_ERROR_TITLE = `Issue getting recommendations ${emDashCharacter}`

interface SelectableChipT {
  category: string
  handleSearchTermChipClick: (
    searchTerm: string,
    index: number,
    recCategory: string
  ) => void
  searchTerm: string
  searchTermIndex: number
  selectedChipIndex: number
}

const SelectableChip = ({
  category,
  handleSearchTermChipClick,
  searchTerm,
  searchTermIndex,
  selectedChipIndex
}: SelectableChipT) => {
  return (
    <Chip
      size="small"
      label={searchTerm}
      onClick={() =>
        handleSearchTermChipClick(searchTerm, searchTermIndex, category)
      }
      sx={{
        backgroundColor:
          selectedChipIndex === searchTermIndex ? 'primary.main' : 'default',
        color: selectedChipIndex === searchTermIndex ? 'white' : 'black',
        '&:hover': {
          backgroundColor:
            selectedChipIndex === searchTermIndex ? 'primary.main' : 'default',
          color: selectedChipIndex === searchTermIndex ? 'white' : 'black'
        }
      }}
    />
  )
}

interface PropsT {
  curationId: number
  onClickForMiniPDP: ({
    webStyleId,
    miniPDPOpenedFrom,
    imgUrl
  }: {
    webStyleId: string
    miniPDPOpenedFrom: MiniPDPLocationsT
    imgUrl: string
  }) => void
  seedItem?: CurationItemT
  storedRecommendations?: ItemRecommendationsT
}

const ItemRecommendations = ({
  curationId,
  onClickForMiniPDP,
  seedItem,
  storedRecommendations
}: PropsT): JSX.Element => {
  const [
    generateCategoriesAndTerms,
    { isLoading, error, endpointName, isError }
  ] = useGenerateSeedItemRecommendationsMutation()
  const dispatch = useContext(CurationReviewDispatchContext)
  const { seedItemWebStyleId: storedWebStyleId, itemRecommendationTerms } =
    storedRecommendations || {}

  const [selectedChipIndex, setSelectedChipIndex] = useState(0)

  const handleSearchTermChipClick = (
    searchTerm: string,
    index: number,
    recCategory: string
  ) => {
    setSelectedChipIndex(index)
    generateNewRelicLogs('itemRecsSearchTermChipClicked', {
      searchTerm,
      curationId,
      seedItemImageUrl: seedItem?.imageUrl,
      seedItemWebStyleId: seedItem?.webStyleId,
      recommendationCategory: recCategory
    })
  }

  const onTabChange = (value: string) => {
    setSelectedChipIndex(0)
    recommendationCategories &&
      generateNewRelicLogs('recommendationCategoryClicked', {
        category: recommendationCategories[Number(value)],
        curationId: curationId
      })
  }

  useEffect(() => {
    const areRecsStored =
      itemRecommendationTerms &&
      itemRecommendationTerms?.recommendations?.length > 0
    const isSeedItemUpdated =
      seedItem && seedItem.webStyleId !== storedWebStyleId

    if (seedItem && (!areRecsStored || isSeedItemUpdated)) {
      generateCategoriesAndTerms({
        curationId,
        seedItemData: seedItem
      })
        .unwrap()
        .then((response) => {
          dispatch &&
            dispatch({
              type: SET_SEED_ITEM_RECS_TERMS,
              payload: {
                itemRecommendationTerms: response,
                webStyleId: seedItem.webStyleId
              }
            })
          return
        })
        .catch(() => null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curationId, seedItem, generateCategoriesAndTerms])

  useEffect(() => {
    if (selectedChipIndex !== 0) setSelectedChipIndex(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seedItem])

  const recommendationCategories = itemRecommendationTerms?.recommendations.map(
    (rec) => rec.category
  )

  if (!seedItem) {
    return <></>
  }

  return (
    <RecommendationsTemplate
      renderedFrom="SeedItemRecommendations"
      onTabChange={onTabChange}
      errorComponent={
        isError ? (
          <PageError
            errorTitle={RECS_ERROR_TITLE}
            errorDetails={{ endpoint: endpointName, errorData: error }}
            refreshAction={() =>
              generateCategoriesAndTerms({ curationId, seedItemData: seedItem })
            }
          />
        ) : undefined
      }
      title="Complete the look"
      description="Add items to round out this look"
      categories={recommendationCategories || []}
      isLoading={isLoading}
      renderCategoryCarousel={(index) => {
        const recommendation = itemRecommendationTerms?.recommendations?.[index]
        const searchTerm = recommendation?.searchTerms?.[selectedChipIndex]

        if (!recommendation || !searchTerm) {
          return null
        }

        return (
          <CategoryCarousel
            recommendation={itemRecommendationTerms?.recommendations[index]}
            onClickForMiniPDP={onClickForMiniPDP}
            curationId={curationId}
            searchTerm={
              itemRecommendationTerms?.recommendations[index].searchTerms[
                selectedChipIndex
              ]
            }
          />
        )
      }}
      renderChips={(index) =>
        itemRecommendationTerms?.recommendations[index].searchTerms.map(
          (searchTerm, searchTermIndex) => (
            <SelectableChip
              key={searchTermIndex}
              searchTerm={searchTerm}
              searchTermIndex={searchTermIndex}
              selectedChipIndex={selectedChipIndex}
              category={itemRecommendationTerms.recommendations[index].category}
              handleSearchTermChipClick={handleSearchTermChipClick}
            />
          )
        ) || []
      }
    />
  )
}

export default ItemRecommendations
